import { Partner } from "./Partner";
import { Drawer } from "./Drawer";

export enum LockerStatus {
  Inactive = 0,
  Active = 1,
}

export interface Locker {
  id: number;
  code: string | null;
  address: string | null;
  city: string | null;
  region: string | null;
  barangay: string | null;
  province: string | null;
  zip_code: string | null;
  status: number | LockerStatus;
  created_at: string;
  partner: Partner;
  drawers: Drawer[] | null;
}
