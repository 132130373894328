import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import qs from 'qs';
import { Link } from "react-router-dom";

export function getPageSearch (page: number) {
  const qsParsed = qs.parse(window.location.search.slice(1))
  
  const query: Record<string, any> = {
    ...qsParsed,
    page,
  }

  if (query.page === 1) {
    delete query.page
  }

  const search = qs.stringify(query)

  return search ? `?${search}` : ''
}

export interface AppPaginationProps {
  page: number;
  pageHandler: (page: number) => void;
  loading: boolean;
  resources: unknown[];
  totalPages: number;
  totalResources: number;
  resourcesPerPage: number;
}

export default function AppPagination({
  page,
  pageHandler,
  loading,
  resources,
  totalPages,
  totalResources,
  resourcesPerPage,
}: AppPaginationProps) {

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to={{ search: getPageSearch(page - 1) }}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => pageHandler(page - 1)}
        >
          Previous
        </Link>

        <Link
          to={{ search: getPageSearch(page + 1) }}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => pageHandler(page + 1)}
        >
          Next
        </Link>
      </div>
      
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              { totalResources > 0 ? ((page - 1) * resourcesPerPage + 1) : 0}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {page === totalPages
                ? page * resourcesPerPage > totalResources
                  ? totalResources
                  : loading ? page * resourcesPerPage : (page - 1) * resourcesPerPage + resources.length
                : loading ? page * resourcesPerPage : (page - 1) * resourcesPerPage + resources.length}
            </span>{" "}
            of <span className="font-medium">{totalResources}</span> results
          </p>
        </div>

        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <Link
              to={{ search: getPageSearch(page - 1) }}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              style={
                page === 1
                  ? { pointerEvents: "none", backgroundColor: "#e5e7eb" }
                  : { pointerEvents: "auto" }
              }
              onClick={() => pageHandler(page - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {[...Array(totalPages)].map((_, i) => {
              return (
                <Link
                  key={i}
                  to={{ search: getPageSearch(i + 1) }}
                  onClick={() => pageHandler(i + 1)}
                  aria-current="page"
                  className={
                    i + 1 === +page
                      ? "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                      : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  }
                >
                  {i + 1}
                </Link>
              );
            })}

            <Link
              to={{ search: getPageSearch(page + 1) }}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              style={
                page === totalPages
                  ? { pointerEvents: "none", backgroundColor: "#e5e7eb" }
                  : { pointerEvents: "auto" }
              }
              onClick={() => pageHandler(page + 1)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}
