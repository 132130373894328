import { createContext } from 'react'

interface PsgcState {
  regions: any[];
  provinces: any[];
  cityOrMunicipalities: any[];
  barangays: any[];
  psgcLoading: boolean;
}

const psgcInitialState: PsgcState = {
  regions: [],
  provinces: [],
  cityOrMunicipalities: [],
  barangays: [],
  psgcLoading: false,
}

const PsgcContext = createContext(psgcInitialState)

export default PsgcContext
