import axios, { AxiosRequestConfig } from "axios";
import { PaginationResult, Transaction } from "models";

export interface GetTransactionsRequest {
  code?: string;
  locker_code?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  start_time?: string;
  end_time?: string;
  page?: string
}

export interface GetTransactionsResponse {
  status: number;
  transactions: PaginationResult<Transaction>;
}

export interface CreateTransactionRequest {
  payment_id?: string;
}

export interface CreateTransactionResponse {
  status: number;
  data: Transaction;
}

export interface UpdateTransactionRequest {
  payment_id?: string;
}

export interface UpdateTransactionResponse {
  status: number;
  data: Transaction;
}

export interface DeleteTransactionResponse {
  status: number;
}

function getConfig () {
  const accessToken = localStorage.getItem("login")
  const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config
}

async function getTransactions(getTransactionsRequest: GetTransactionsRequest): Promise<GetTransactionsResponse> {
  const config = {
    ...getConfig(),
    params: getTransactionsRequest,
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.get('/api/transactions', config);
  const getTransactionsResponse = response.data
  return getTransactionsResponse
}

async function createTransaction(createTransactionRequest: CreateTransactionRequest): Promise<CreateTransactionResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.post('/api/transactions', createTransactionRequest, config);
  const createTransactionResponse = response.data
  return createTransactionResponse
}

async function updateTransaction(updateTransactionRequest: UpdateTransactionRequest): Promise<UpdateTransactionResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.put(`/api/transactions/${updateTransactionRequest.payment_id}`, {}, config);
  const updateTransactionResponse = response.data
  return updateTransactionResponse
}

async function deleteTransaction(transactionId: string): Promise<DeleteTransactionResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.delete(`/api/transactions/${transactionId}`, config);
  const deleteTransactionResponse = response.data
  return deleteTransactionResponse
}

async function exportTransactions(getTransactionsRequest: GetTransactionsRequest): Promise<GetTransactionsResponse> {
  const responseType = 'blob'
  const config: AxiosRequestConfig = {
    ...getConfig(),
    params: getTransactionsRequest,
    responseType: responseType
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.get('/api/export_transactions', config);
  const exportTransactionsResponse = response.data
  const blob = new Blob([exportTransactionsResponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'transactions.xlsx';

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  return exportTransactionsResponse
}

export const transactionService = {
  getTransactions,
  createTransaction,
  updateTransaction,
  deleteTransaction,
  exportTransactions
}
