import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchLockers } from "hooks/useFetchLockers";
import LockerForm from "pages/lockers/LockerForm";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  cancelCreateLockerModal,
  createLocker,
  selectCreateLockerFormValidationError, selectCreateLockerModalOpen, selectCreateLockerRequest,
  selectCreatingLocker,
  setCreateLockerModalOpen,
  setCreateLockerRequestFieldValue
} from "store/lockers/createLockerSlice";

export default function CreateLockerModal() {
  const dispatch = useAppDispatch();

  const createLockerModalOpen = useAppSelector(selectCreateLockerModalOpen);
  const createLockerRequest = useAppSelector(selectCreateLockerRequest);
  const createLockerFormValidationError = useAppSelector(selectCreateLockerFormValidationError);
  const creatingLocker = useAppSelector(selectCreatingLocker);
  const fetchLockers = useFetchLockers()

  const initialFocusRef = useRef(null);

  const [selectedPartner, setSelectedPartner] = useState<null | { id: number, email: string, firstname: string, lastname: string }>(null)

  useEffect(() => {
    dispatch(setCreateLockerRequestFieldValue({ fieldName: 'partner_id', fieldValue: selectedPartner ? selectedPartner.id : '' }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner])

  const formSubmitHandler = (e: Event) => {
    e.preventDefault();
    dispatch(createLocker(createLockerRequest)).then(() => {
      fetchLockers()
    })
  };

  function setFieldValue(fieldName: string, fieldValue: string | null | { id: number, email: string, firstname: string, lastname: string }) {
    if (fieldName === 'selectedPartner') {
      setSelectedPartner(fieldValue as null | { id: number, email: string, firstname: string, lastname: string })
    } else {
      dispatch(setCreateLockerRequestFieldValue({ fieldName, fieldValue }))
    }
  }

  return (
    <Transition.Root show={createLockerModalOpen} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => creatingLocker ? null : dispatch(setCreateLockerModalOpen(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !creatingLocker
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setCreateLockerModalOpen(false))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }
                  
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Create Locker
                    </Dialog.Title>

                    <LockerForm
                      initialFocusRef={initialFocusRef}
                      formSubmitHandler={formSubmitHandler}
                      cancelHandler={() => dispatch(cancelCreateLockerModal())}
                      request={{
                        ...createLockerRequest,
                        selectedPartner,
                      }}
                      setFieldValue={setFieldValue}
                      submittingForm={creatingLocker}
                      formValidationError={createLockerFormValidationError}
                      formType={'create'}
                    />

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
