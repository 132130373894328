import axios from "axios";
import Loading from "components/Loading";
import GlobalContext from "contexts/GlobalContext";
import { useAppDispatch } from "hooks/store-hooks";
import { Locker } from "models";
import { useContext, useEffect, useState } from "react";
import { setLockerToView } from "store/lockers/updateLockerSlice";

export default function PartnerLockersTable({ partnerId }: { partnerId: number }) {
  const dispatch = useAppDispatch()
  // const navigate = useNavigate()
  
  const { catchErr } = useContext(GlobalContext)

  // const [searchParams] = useSearchParams()

  // const emailFromParams = searchParams.get('partner_email')
  // const statusFromParams = searchParams.get('status')
  // const startDateFromParams = searchParams.get('start_date')
  // const endDateFromParams = searchParams.get('end_date')

  // const hasFilter = emailFromParams || startDateFromParams || endDateFromParams || statusFromParams ? true : false

  const hasFilter = false

  // const lockers = useAppSelector(selectLockers);
  // const loading = useAppSelector(selectGettingLockersLoading);
  // const totalLockers = useAppSelector(selectLockersCount);
  // const lockersPerPage = useAppSelector(selectLockersPerPage);
  // const totalPages = useAppSelector(selectLockersNumberOfPages);
  // const currentPage = useAppSelector(selectLockersCurrentPage);

  // function pageHandler (page: number) {
  //   const search = getPageSearch(page)
  //   navigate({ search })
  // }

  const [lockers, setLockers] = useState([])
  const [loading, setGettingLockers] = useState(false)

  function getLockers() {
    const accessToken = localStorage.getItem("login")

    if (!accessToken) {
      return
    }
    
    const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

    setGettingLockers(true)

    axios.get(`${API_BASE_URL}/api/lockers`, {
      headers: {
        Authorization: token
      },
      params: {
        per_page: 1000,
        page: 1,
        partner_id: partnerId,
      },
    }).then((response) => {
      console.log('get lockers response', response)
      const lockers = response.data.lockers.data
      setLockers(lockers)
    }).catch((err) => {
      console.log('get contacts err', err)
      setLockers([])
      catchErr(err)
    }).finally(() => {
      setGettingLockers(false)
    })
  }

  useEffect(() => {
    getLockers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId])

  return (
    <div className="">

      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Code
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {lockers.map((locker: Locker, i: number) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className={ `inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${locker.status ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}` } >
                          {locker.status ? 'Active' : 'Inactive'}
                        </span>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {locker.code}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {locker.created_at}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                        <button className="text-cyan-600 hover:text-cyan-900" onClick={() => dispatch(setLockerToView(locker))}>
                          View
                          <span className="sr-only">locker Id#: {locker.id}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {
                !loading && lockers.length === 0 ? <>
                  <div className="flex justify-center p-4">
                    <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                  </div>
                </>
                : <></>
              }

              {/* <AppPagination
                page={currentPage}
                pageHandler={pageHandler}
                loading={loading}
                resources={lockers}
                totalPages={totalPages}
                totalResources={totalLockers}
                resourcesPerPage={lockersPerPage}
              /> */}
              
              {
                loading
                ?
                  <>
                    <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <Loading />
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
