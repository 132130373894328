import AppFieldError from "components/AppFieldError";
import AppInputSelect from "components/AppInputSelect";
import LoadingIcon from "components/LoadingIcon";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { usePsgcOptions } from "hooks/usePsgcOptions";
import { FormEvent, FormEventHandler, useEffect, useRef, useState } from "react";
import { getAuthProfile, selectAccessToken, selectLoggedInUserProfile } from "store/auth/authSlice";
import { cancelUpdateProfile, selectUpdateProfileFormValidationError, selectUpdateProfileRequest, 
  selectUpdatingProfile, setProfileToUpdate, setUpdateProfileRequestFieldValue, updateAuthProfile } from "store/auth/updateProfileSlice";

export default function AccountSettingsPage() {
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(selectAccessToken);

  const updateProfileRequest = useAppSelector(selectUpdateProfileRequest);
  const updateProfileFormValidationError = useAppSelector(selectUpdateProfileFormValidationError);
  const updatingProfile = useAppSelector(selectUpdatingProfile);
  
  // const regionValue = updateProfileRequest.region
  // const provinceValue = updateProfileRequest.province
  // const cityValue = updateProfileRequest.city

  // const {
  //   regionsOptions,
  //   provincesOptions,
  //   cityOrMunicipalitiesOptions,
  //   barangaysOptions,
  //   getRegionFromProvice,
  // } = usePsgcOptions({
  //   regionValue,
  //   provinceValue,
  //   cityValue,
  // })

  function cancelHandler() {
    dispatch(cancelUpdateProfile())
    dispatch(setProfileToUpdate({
      profileToUpdate: loggedInUserProfile,
      // region: getRegionFromProvice(loggedInUserProfile && loggedInUserProfile.province ? loggedInUserProfile.province : '')
    }))
  }

  const formSubmitHandler: FormEventHandler<HTMLFormElement> = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateAuthProfile(updateProfileRequest)).then((action) => {
      if (action.type === 'updateProfileSlice/updateAuthProfile/fulfilled') {
        if (accessToken) {
          dispatch(getAuthProfile(accessToken))
        }
      }
    })
  };

  function setFieldValue(fieldName: string, fieldValue: string | number) {
    dispatch(setUpdateProfileRequestFieldValue({ fieldName, fieldValue }))
  }

  const loggedInUserProfile = useAppSelector(selectLoggedInUserProfile)

  useEffect(() => {
    dispatch(setProfileToUpdate({
      profileToUpdate: loggedInUserProfile,
      // region: getRegionFromProvice(loggedInUserProfile && loggedInUserProfile.province ? loggedInUserProfile.province : '')
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserProfile])
  
  const inputAttachmentFileRef = useRef<HTMLInputElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File | null>(null)

  function fileOnChangeHandler (e: { target: HTMLInputElement }) {
    const file = e.target.files?.[0]
    if (file) {
      setFile(file)
      
      const fileReader = new FileReader()

      fileReader.addEventListener('load', () => {
        const dataUrl = fileReader.result as string
        setFieldValue('image', dataUrl)
      })

      fileReader.readAsDataURL(file)
    } else {
      setFile(null)
      setFieldValue('image', '')
    }
  }

  function removeFileHandler () {
    if (inputAttachmentFileRef.current) {
      inputAttachmentFileRef.current.value = ''
    }
    setFile(null)
    setFieldValue('image', '')
  }

  return (
    <>
      {/* Main content */}
      <div className="flex-1 xl:overflow-y-auto">
        <div className="mx-auto max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-cyan-gray-900">
            Account
          </h1>

          <form className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y" onSubmit={formSubmitHandler}>
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-8 sm:gap-x-6">
              <div className="sm:col-span-8">
                <h2 className="text-xl font-medium text-cyan-gray-900">
                  Profile
                </h2>
                <p className="mt-1 text-sm text-cyan-gray-500">
                  This information will be displayed publicly so be careful what
                  you share.
                </p>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Photo
                </label>
                <div className="mt-1 flex items-center">
                  {updateProfileRequest.image ? <img
                    className="inline-block h-12 w-12 rounded-full"
                    src={updateProfileRequest.image}
                    alt="profile_image"
                  /> : <></>}
                  <div className="ml-4 flex">
                    <div className="relative flex cursor-pointer items-center rounded-md border border-cyan-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-500 focus-within:ring-offset-2 focus-within:ring-offset-cyan-gray-50 hover:bg-cyan-gray-50">
                      <label
                        htmlFor="image"
                        className="pointer-events-none relative text-sm font-medium text-cyan-gray-900"
                      >
                        <span>{!updateProfileRequest.image ? 'Choose File' : 'Change'}</span>
                        <span className="sr-only"> user photo</span>
                      </label>

                      <input
                        id="image"
                        name="image"
                        type="file"
                        className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                        ref={inputAttachmentFileRef}
                        accept="image/*"
                        onChange={fileOnChangeHandler}
                      />
                    </div>

                    {updateProfileRequest.image ? <button
                      type="button"
                      className="
                        ml-3 rounded-md border border-transparent bg-transparent py-2 px-3 text-sm font-medium text-cyan-gray-900 hover:text-cyan-gray-700
                        focus:border-cyan-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 focus:ring-offset-cyan-gray-50
                      "
                      onClick={removeFileHandler}
                    >
                      Remove
                    </button> : <></>}
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  First name
                </label>

                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  autoComplete="firstname"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateProfileRequest.firstname}
                  onChange={(e) => setFieldValue('firstname', e.target.value)}

                />

                <AppFieldError fieldName={'firstname'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="mi"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Middle name
                </label>

                <input
                  type="text"
                  name="mi"
                  id="mi"
                  autoComplete="mi"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateProfileRequest.mi}
                  onChange={(e) => setFieldValue('mi', e.target.value)}
                />

                <AppFieldError fieldName={'mi'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Last name
                </label>

                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  autoComplete="lastname"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateProfileRequest.lastname}
                  onChange={(e) => setFieldValue('lastname', e.target.value)}
                />

                <AppFieldError fieldName={'lastname'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Phone number
                </label>

                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateProfileRequest.phone}
                  onChange={(e) => setFieldValue('phone', e.target.value)}
                />

                <AppFieldError fieldName={'phone'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="e_wallet_number"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  E-wallet number
                </label>

                <input
                  type="text"
                  name="e_wallet_number"
                  id="e_wallet_number"
                  autoComplete="e_wallet_number"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateProfileRequest.e_wallet_number}
                  onChange={(e) => setFieldValue('e_wallet_number', e.target.value)}
                />

                <AppFieldError fieldName={'e_wallet_number'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-8">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Address
                </label>

                <div className="mt-1">
                  <textarea
                    id="address"
                    name="address"
                    rows={2}
                    className="block w-full rounded-md border-cyan-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    value={updateProfileRequest.address}
                    onChange={(e) => setFieldValue('address', e.target.value)}
                  />
                </div>

                <AppFieldError fieldName={'address'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="zip_code"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Zip code
                </label>

                <input
                  type="text"
                  name="zip_code"
                  id="zip_code"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateProfileRequest.zip_code}
                  onChange={(e) => setFieldValue('zip_code', e.target.value)}
                />

                <AppFieldError fieldName={'zip_code'} formValidationError={updateProfileFormValidationError} />
              </div>

              <div className="sm:col-span-5">

              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="notification_type"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Notification Type
                </label>

                <AppInputSelect
                  options={[
                    { label: 'Sms', value: 0 },
                    { label: 'Email', value: 1 },
                    { label: 'Both', value: 2 },
                  ]}
                  value={updateProfileRequest.notification_type}
                  onChange={(e) => setFieldValue('notification_type', e)}
                />

                <AppFieldError fieldName={'notification_type'} formValidationError={updateProfileFormValidationError} />
              </div>
            </div>

            <div>
              <div className="flex justify-end pt-8">
                <button
                  type="button"
                  disabled={updatingProfile}
                  className={`${updatingProfile ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                  onClick={cancelHandler}
                >
                  Revert Changes
                </button>

                <button
                  type="submit"
                  disabled={updatingProfile}
                  className={`${updatingProfile ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                >
                  { updatingProfile ? <><LoadingIcon /><span>Loading...</span></> : 'Submit' }
                </button>
              </div>

              {
                updateProfileFormValidationError
                  ?
                    <div className="flex justify-end">
                      <small className="text-red-500">{ updateProfileFormValidationError.message }</small>
                    </div>
                  :
                    <></>
              }
            </div>

          </form>
        </div>
      </div>
    </>
  );
}
