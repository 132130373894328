import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import AppFieldError from "components/AppFieldError";
import AppInputSelect from "components/AppInputSelect";
import LoadingIcon from "components/LoadingIcon";
import { FormValidationError } from "errors/FormValidationError";
import { usePsgcOptions } from "hooks/usePsgcOptions";
import { PartnerStatusEnum } from "models";
import { useRef, useState } from 'react';

export interface PartnerFormProps {
  initialFocusRef: any;
  formSubmitHandler: (e: any) => void;
  cancelHandler: () => void;
  request: Record<string, any>;
  setFieldValue: (fieldName: string, fieldValue: string | number) => void;
  submittingForm: boolean
  formValidationError: FormValidationError | null;
  formType: 'create' | 'update' | 'view'
}

export default function PartnerForm({
  initialFocusRef,
  formSubmitHandler,
  cancelHandler,
  request,
  setFieldValue,
  submittingForm,
  formValidationError,
  formType
}: PartnerFormProps) {
  const regionValue = request.region
  const provinceValue = request.province
  const cityValue = request.city

  const {
    regionsOptions,
    provincesOptions,
    cityOrMunicipalitiesOptions,
    barangaysOptions,
  } = usePsgcOptions({
    regionValue,
    provinceValue,
    cityValue,
  })
  
  const inputAttachmentFileRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = useState<File | null>(null)

  const [showFileModal, setShowFileModal] = useState(false)
  const modalInitialFocusRef = useRef<HTMLButtonElement>(null)

  function fileOnChangeHandler (e: { target: HTMLInputElement }) {
    const file = e.target.files?.[0]
    if (file) {
      setFile(file)
      
      const fileReader = new FileReader()

      fileReader.addEventListener('load', () => {
        const dataUrl = fileReader.result as string
        setFieldValue('attachment', dataUrl)
      })

      fileReader.readAsDataURL(file)
    } else {
      setFile(null)
      setFieldValue('attachment', '')
    }
  }

  function removeFileHandler () {
    if (inputAttachmentFileRef.current) {
      inputAttachmentFileRef.current.value = ''
    }
    setFile(null)
    setFieldValue('attachment', '')
  }

  return (
    <>
      <Transition.Root show={showFileModal}>
        <Dialog
          className="relative z-10"
          initialFocus={modalInitialFocusRef}
          onClose={() => setShowFileModal(false)}
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                  <div>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      ref={modalInitialFocusRef}
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setShowFileModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                    
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        { file?.name }
                      </Dialog.Title>

                      <div>
                        {request.attachment ? <>
                          <img src={request.attachment} alt={file?.name} />
                        </> : <></>}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      
      <div className="mt-2">
        <form
          className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y"
          onSubmit={formSubmitHandler}
        >
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            {/* <div className="sm:col-span-6">
              <h2 className="text-xl font-medium text-cyan-gray-900">
                Profile
              </h2>

              <p className="mt-1 text-sm text-cyan-gray-500">
                This information will be displayed publicly so be
                careful what you share.
              </p>
            </div> */}

            <div className="sm:col-span-2">
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                First name
              </label>

              <input
                ref={initialFocusRef}
                type="text"
                name="firstname"
                id="firstname"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.firstname}
                onChange={(e) => setFieldValue('firstname', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'firstname'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Last name
              </label>

              <input
                type="text"
                name="lastname"
                id="lastname"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.lastname}
                onChange={(e) => setFieldValue('lastname', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'lastname'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="mi"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Middle name
              </label>

              <input
                type="text"
                name="mi"
                id="mi"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.mi}
                onChange={(e) => setFieldValue('mi', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'mi'} formValidationError={formValidationError} />
            </div>

          {/* </div>

          <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6"> */}

            {/* <div className="sm:col-span-6">
              <h2 className="text-xl font-medium text-cyan-gray-900">
                Personal Information
              </h2>

              <p className="mt-1 text-sm text-cyan-gray-500">
                This information will be displayed publicly so be
                careful what you share.
              </p>
            </div> */}

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Email address
              </label>

              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'email'} formValidationError={formValidationError} />
            </div>

            

            {
              formType === 'update' || formType === 'view' ? <>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-cyan-gray-900"
                  >
                    Status
                  </label>
  
                  <AppInputSelect
                    options={[
                      {
                        label: PartnerStatusEnum[PartnerStatusEnum.PENDING],
                        value: PartnerStatusEnum.PENDING
                      },
                      {
                        label: PartnerStatusEnum[PartnerStatusEnum.PROCESSING],
                        value: PartnerStatusEnum.PROCESSING
                      },
                      {
                        label: PartnerStatusEnum[PartnerStatusEnum.APPROVED],
                        value: PartnerStatusEnum.APPROVED
                      },
                      {
                        label: PartnerStatusEnum[PartnerStatusEnum.DECLINED],
                        value: PartnerStatusEnum.DECLINED
                      },
                      {
                        label: PartnerStatusEnum[PartnerStatusEnum.ACTIVE],
                        value: PartnerStatusEnum.ACTIVE
                      },
                      {
                        label: PartnerStatusEnum[PartnerStatusEnum.INACTIVE],
                        value: PartnerStatusEnum.INACTIVE
                      },
                    ]}
                    value={request.status}
                    onChange={(e) => setFieldValue('status', e)}
                    disabled={formType === 'view'}
                    getSelectedOption={() => {
                      return [
                        {
                          label: PartnerStatusEnum[PartnerStatusEnum.PENDING],
                          value: PartnerStatusEnum.PENDING
                        },
                        {
                          label: PartnerStatusEnum[PartnerStatusEnum.PROCESSING],
                          value: PartnerStatusEnum.PROCESSING
                        },
                        {
                          label: PartnerStatusEnum[PartnerStatusEnum.APPROVED],
                          value: PartnerStatusEnum.APPROVED
                        },
                        {
                          label: PartnerStatusEnum[PartnerStatusEnum.DECLINED],
                          value: PartnerStatusEnum.DECLINED
                        },
                        {
                          label: PartnerStatusEnum[PartnerStatusEnum.ACTIVE],
                          value: PartnerStatusEnum.ACTIVE
                        },
                        {
                          label: PartnerStatusEnum[PartnerStatusEnum.INACTIVE],
                          value: PartnerStatusEnum.INACTIVE
                        },
                      ].find(option => option.value === request.status) || null
                    }}
                  />
  
                  <AppFieldError fieldName={'status'} formValidationError={formValidationError} />
                </div>
              </> : <></>
            }

            <div className="sm:col-span-3">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Mobile number
              </label>

              <input
                type="text"
                name="mobile"
                id="mobile"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.mobile}
                onChange={(e) => setFieldValue('mobile', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'mobile'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Address
              </label>

              <input
                type="text"
                name="address"
                id="address"
                autoComplete="address"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.address}
                onChange={(e) => setFieldValue('address', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'address'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                City
              </label>

              <input
                type="text"
                name="city"
                id="city"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.city}
                onChange={(e) => setFieldValue('city', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'city'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="province"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Province (Ex. NCR)
              </label>

              <input
                type="text"
                name="province"
                id="province"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.province}
                onChange={(e) => setFieldValue('province', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'province'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="zip_code"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                Zip code
              </label>

              <input
                type="text"
                name="zip_code"
                id="zip_code"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.zip_code}
                onChange={(e) => setFieldValue('zip_code', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'zip_code'} formValidationError={formValidationError} />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="e_wallet_number"
                className="block text-sm font-medium text-cyan-gray-900"
              >
                E-wallet number
              </label>

              <input
                type="text"
                name="e_wallet_number"
                id="e_wallet_number"
                className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                value={request.e_wallet_number}
                onChange={(e) => setFieldValue('e_wallet_number', e.target.value)}
                disabled={formType === 'view'}
              />

              <AppFieldError fieldName={'e_wallet_number'} formValidationError={formValidationError} />
            </div>

            {
              formType === 'view' ? <></> : <>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="attachment"
                    className="block text-sm font-medium text-cyan-gray-900"
                  >
                    Attachment
                  </label>

                  <div className="flex">
                    <input
                      type="text"
                      disabled={true}
                      className={`${'cursor-pointer'} mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm`}
                      value={file ? file.name : request.attachment}
                      onChange={() => null}
                    />

                    <input
                      ref={inputAttachmentFileRef}
                      className="hidden"
                      type="file"
                      accept="image/*"
                      onChange={fileOnChangeHandler}
                    />

                    {!request.attachment ? <button
                      type="button"
                      className={`${'cursor-pointer bg-cyan-600'} whitespace-nowrap ml-2 px-4 text-white mt-1 block rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                      onClick={() => inputAttachmentFileRef.current?.click() }
                    >Choose File</button> : <></>}

                    {request.attachment ? <>
                      <button
                        type="button"
                        className={`${'cursor-pointer bg-cyan-600'} whitespace-nowrap ml-2 px-4 text-white mt-1 block rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                        onClick={() => setShowFileModal(true)}
                      >View</button>

                      <button
                        type="button"
                        className={`${'cursor-pointer bg-red-600'} whitespace-nowrap ml-2 px-4 text-white mt-1 block rounded-md border-red-gray-300 text-red-gray-900 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2`}
                        onClick={removeFileHandler}
                      >Remove</button>
                    </> : <></>}
                    
                  </div>
                  

                  <AppFieldError fieldName={'attachment'} formValidationError={formValidationError} />
                </div>
              </>
            }

          </div>

          <div>
            <div className="flex justify-end pt-8">
              <button
                type="button"
                disabled={submittingForm}
                className={`${submittingForm ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                onClick={() => cancelHandler()}
              >
                Cancel
              </button>

              {
                formType === 'view' ? <></> : <>
                  <button
                    type="submit"
                    disabled={submittingForm}
                    className={`${submittingForm ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                  >
                    { submittingForm ? <><LoadingIcon /><span>Loading...</span></> : 'Submit' }
                  </button>
                </>
              }
            </div>

            {
              formValidationError
                ?
                  <div className="flex justify-end">
                    <small className="text-red-500 break-all">{ formValidationError.message }</small>
                  </div>
                :
                  <></>
            }
          </div>

        </form>
      </div>
    </>
  )
}
