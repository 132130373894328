import { FormValidationError, ValidationError } from "errors/FormValidationError";
import { Transition } from "@headlessui/react";

export default function AppFieldError({ fieldName, formValidationError }: { fieldName: string; formValidationError: FormValidationError | null}) {
  return (
    <Transition
      show={formValidationError && formValidationError.validationErrors.some((validationError: ValidationError) => validationError.field === fieldName) ? true : false}
    >
      <Transition.Child
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <small className="text-red-500">
          { formValidationError?.validationErrors.find((validationError: ValidationError) => validationError.field === fieldName)?.message }
        </small>
      </Transition.Child>
    </Transition>
  );
}
