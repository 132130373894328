import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Profile } from 'models';
import { authService, LoginRequest } from 'services/authService';
import { RootState } from 'store/store';

interface AuthState {
  accessToken: string | null;
  loggedInUserProfile: Profile | null;
  gettingLoggedInUser: boolean;
  getLoggedInUserFormValidationError: FormValidationError | null;
  loginRequest: LoginRequest;
  loggingInUser: boolean;
  loginFormValidationError: FormValidationError | null;
}

const initialLoginRequest: LoginRequest = {
  device_name: 'android',
  email: '',
  password: '',
}

const authInitialState: AuthState = {
  accessToken: null,
  loggedInUserProfile: null,
  gettingLoggedInUser: false,
  getLoggedInUserFormValidationError: null,
  loginRequest: initialLoginRequest,
  loggingInUser: false,
  loginFormValidationError: null,
}

export const login = createAsyncThunk('authSlice/login', async (loginRequest: LoginRequest, thunkAPI) => {
  try {
    const loginResponse = await authService.login(loginRequest);
    return loginResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const getAuthProfile = createAsyncThunk('authSlice/getAuthProfile', async (accessToken: string, thunkAPI) => {
  try {
    const getAuthProfileResponse = await authService.getAuthProfile(accessToken);
    return getAuthProfileResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: authInitialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },

    logout: (state) => {
      state.accessToken = null
      state.loggedInUserProfile = null
    },

    setLoginRequestEmail: (state, action: PayloadAction<string>) => {
      state.loginRequest.email = action.payload
      
      if (state.loginFormValidationError && state.loginFormValidationError.validationErrors) {
        state.loginFormValidationError.validationErrors = state.loginFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== 'email'
        })

        if (state.loginFormValidationError.validationErrors.length === 0) {
          state.loginFormValidationError = null
        }
      }
    },

    setLoginRequestPassword: (state, action: PayloadAction<string>) => {
      state.loginRequest.password = action.payload
      
      if (state.loginFormValidationError && state.loginFormValidationError.validationErrors) {
        state.loginFormValidationError.validationErrors = state.loginFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== 'password'
        })

        if (state.loginFormValidationError.validationErrors.length === 0) {
          state.loginFormValidationError = null
        }
      }
    },

    cancelLogin: (state) => {
      state.loginRequest = initialLoginRequest
      state.loggingInUser = false
      state.loginFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loggingInUser = true
        state.loginFormValidationError = null
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginRequest = initialLoginRequest
        state.loggingInUser = false
        state.accessToken = action.payload.access_token
      })
      .addCase(login.rejected, (state, action) => {
        state.loggingInUser = false
        state.loginFormValidationError = action.payload as FormValidationError
      })
      
      .addCase(getAuthProfile.pending, (state) => {
        state.gettingLoggedInUser = true
        state.getLoggedInUserFormValidationError = null
      })
      .addCase(getAuthProfile.fulfilled, (state, action) => {
        state.gettingLoggedInUser = false
        state.accessToken = action.meta.arg
        state.loggedInUserProfile = action.payload.data
      })
      .addCase(getAuthProfile.rejected, (state, action) => {
        state.gettingLoggedInUser = false
        state.getLoggedInUserFormValidationError = action.payload as FormValidationError
      });
  },
})

export const { setAccessToken, logout, setLoginRequestEmail, setLoginRequestPassword } = authSlice.actions

export const selectAccessToken = (state: RootState) => state.authSlice.accessToken;
export const selectLoggedInUserProfile = (state: RootState) => state.authSlice.loggedInUserProfile;
export const selectGettingLoggedInUser = (state: RootState) => state.authSlice.gettingLoggedInUser;
export const selectLoginRequest = (state: RootState) => state.authSlice.loginRequest;
export const selectLoggingInUser = (state: RootState) => state.authSlice.loggingInUser;
export const selectLoginFormValidationError = (state: RootState) => state.authSlice.loginFormValidationError;

export default authSlice.reducer
