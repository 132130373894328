import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Partner } from 'models';
import { partnerService } from "services/partnerService";
import { RootState } from 'store/store';

interface DeletePartnerState {
  partnerToDelete: Partner | null;
  deletingPartner: boolean;
  deletePartnerFormValidationError: FormValidationError | null;
}

const deletePartnerModalInitialState: DeletePartnerState = {
  partnerToDelete: null,
  deletingPartner: false,
  deletePartnerFormValidationError: null,
}

export const deletePartner = createAsyncThunk('deletePartnerModal/deletePartner', async (partner: Partner, thunkAPI) => {
  try {
    const deletePartnerResponse = await partnerService.deletePartner(partner.id.toString());
    return deletePartnerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const deletePartnerSlice = createSlice({
  name: 'deletePartnerSlice',
  initialState: deletePartnerModalInitialState,
  reducers: {
    setPartnerToDelete: (state, action: PayloadAction<Partner | null>) => {
      state.partnerToDelete = action.payload
      state.deletePartnerFormValidationError = null
    },
    cancelDeletePartnerModal: (state) => {
      state.partnerToDelete = null
      state.deletePartnerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePartner.pending, (state) => {
        state.deletingPartner = true
        state.deletePartnerFormValidationError = null
      })
      .addCase(deletePartner.fulfilled, (state) => {
        state.partnerToDelete = null
        state.deletingPartner = false
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.deletePartnerFormValidationError = action.payload as FormValidationError
        state.deletingPartner = false
      });
  },
})

export const { setPartnerToDelete, cancelDeletePartnerModal } = deletePartnerSlice.actions

export const selectPartnerToDelete = (state: RootState) => state.deletePartnerSlice.partnerToDelete;
export const selectDeletingPartner = (state: RootState) => state.deletePartnerSlice.deletingPartner;
export const selectDeletePartnerFormValidationError = (state: RootState) => state.deletePartnerSlice.deletePartnerFormValidationError;

export default deletePartnerSlice.reducer
