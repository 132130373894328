import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Profile } from 'models';
import { authService, UpdateProfileRequest } from 'services/authService';
import { RootState } from 'store/store';

interface UpdateProfileState {
  profileToUpdate: Profile | null;
  updateProfileRequest: UpdateProfileRequest;
  updatingProfile: boolean;
  updateProfileFormValidationError: FormValidationError | null;
}

const initialUpdateProfileRequest: UpdateProfileRequest = {
  firstname: '',
  lastname: '',
  mi: '',
  address: '',
  // barangay: '',
  // city: '',
  // province: '',
  // region: '',
  zip_code: '',
  e_wallet_number: '',
  notification_type: '',
  image: '',
  phone: '',
  email: '',
}

const updateProfileInitialState: UpdateProfileState = {
  profileToUpdate: null,
  updateProfileRequest: initialUpdateProfileRequest,
  updatingProfile: false,
  updateProfileFormValidationError: null,
}

export const updateAuthProfile = createAsyncThunk('updateProfileSlice/updateAuthProfile', async (updateProfileRequest: UpdateProfileRequest, thunkAPI) => {
  try {
    const updateAuthProfileResponse = await authService.updateAuthProfile(updateProfileRequest);
    return updateAuthProfileResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const updateProfileSlice = createSlice({
  name: 'updateProfileSlice',
  initialState: updateProfileInitialState,
  reducers: {
  setProfileToUpdate: (state, action: PayloadAction<{ profileToUpdate: Profile | null/* , region: string */ }>) => {
      state.profileToUpdate = action.payload.profileToUpdate

      if (state.profileToUpdate) {
        state.updateProfileRequest.firstname = state.profileToUpdate.firstname || ''
        state.updateProfileRequest.lastname = state.profileToUpdate.lastname || ''
        state.updateProfileRequest.mi = state.profileToUpdate.mi || ''
        state.updateProfileRequest.address = state.profileToUpdate.address || ''
        // state.updateProfileRequest.region = state.profileToUpdate.region || ''
        // state.updateProfileRequest.province = state.profileToUpdate.province || ''
        // state.updateProfileRequest.city = state.profileToUpdate.city || ''
        // state.updateProfileRequest.barangay = state.profileToUpdate.barangay || ''
        state.updateProfileRequest.zip_code = state.profileToUpdate.zip_code || ''
        state.updateProfileRequest.e_wallet_number = state.profileToUpdate.e_wallet_number || ''
        state.updateProfileRequest.phone = state.profileToUpdate.phone || ''
        state.updateProfileRequest.email = state.profileToUpdate.user.email || ''
        // 0 for SMS, 1 for Email, 2 for Both
        const notificationType = state.profileToUpdate.notification_type === 'sms'
          ? 0
          : state.profileToUpdate.notification_type === 'email'
          ? 1
          : state.profileToUpdate.notification_type === 'both'
          ? 2
          : ''
  
        state.updateProfileRequest.notification_type = notificationType
        state.updateProfileRequest.image = state.profileToUpdate.image || ''
      }
    },

    setUpdateProfileRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.updateProfileRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'firstname') {
        state.updateProfileRequest.firstname = action.payload.fieldValue
      } else if (action.payload.fieldName === 'lastname') {
        state.updateProfileRequest.lastname = action.payload.fieldValue
      } else if (action.payload.fieldName === 'mi') {
        state.updateProfileRequest.mi = action.payload.fieldValue
      } else if (action.payload.fieldName === 'address') {
        state.updateProfileRequest.address = action.payload.fieldValue
      // } else if (action.payload.fieldName === 'region') {
      //   state.updateProfileRequest.region = action.payload.fieldValue
      // } else if (action.payload.fieldName === 'province') {
      //   state.updateProfileRequest.province = action.payload.fieldValue
      // } else if (action.payload.fieldName === 'city') {
      //   state.updateProfileRequest.city = action.payload.fieldValue
      // } else if (action.payload.fieldName === 'barangay') {
      //   state.updateProfileRequest.barangay = action.payload.fieldValue
      } else if (action.payload.fieldName === 'zip_code') {
        state.updateProfileRequest.zip_code = action.payload.fieldValue
      } else if (action.payload.fieldName === 'e_wallet_number') {
        state.updateProfileRequest.e_wallet_number = action.payload.fieldValue
      } else if (action.payload.fieldName === 'notification_type') {
        state.updateProfileRequest.notification_type = action.payload.fieldValue
      } else if (action.payload.fieldName === 'image') {
        state.updateProfileRequest.image = action.payload.fieldValue
      } else if (action.payload.fieldName === 'phone') {
        state.updateProfileRequest.phone = action.payload.fieldValue
      }

      // if (action.payload.fieldName === 'region') {
      //   state.updateProfileRequest['province'] = ''
      //   state.updateProfileRequest['city'] = ''
      //   state.updateProfileRequest['barangay'] = ''
      // }

      // if (action.payload.fieldName === 'province') {
      //   state.updateProfileRequest['city'] = ''
      //   state.updateProfileRequest['barangay'] = ''
      // }

      // if (action.payload.fieldName === 'city') {
      //   state.updateProfileRequest['barangay'] = ''
      // }
      
      if (state.updateProfileFormValidationError && state.updateProfileFormValidationError.validationErrors) {
        state.updateProfileFormValidationError.validationErrors = state.updateProfileFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.updateProfileFormValidationError.validationErrors.length === 0) {
          state.updateProfileFormValidationError = null
        }
      }
    },

    cancelUpdateProfile: (state) => {
      state.updateProfileRequest = initialUpdateProfileRequest
      state.updatingProfile = false
      state.updateProfileFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAuthProfile.pending, (state) => {
        state.updatingProfile = true
        state.updateProfileFormValidationError = null
      })
      .addCase(updateAuthProfile.fulfilled, (state) => {
        state.updatingProfile = false
      })
      .addCase(updateAuthProfile.rejected, (state, action) => {
        state.updatingProfile = false
        state.updateProfileFormValidationError = action.payload as FormValidationError
      });
  },
})

export const { setProfileToUpdate, setUpdateProfileRequestFieldValue, cancelUpdateProfile } = updateProfileSlice.actions

export const selectUpdateProfileRequest = (state: RootState) => state.updateProfileSlice.updateProfileRequest;
export const selectUpdatingProfile = (state: RootState) => state.updateProfileSlice.updatingProfile;
export const selectUpdateProfileFormValidationError = (state: RootState) => state.updateProfileSlice.updateProfileFormValidationError;

export default updateProfileSlice.reducer
