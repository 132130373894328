export enum PartnerStatusEnum {
  PENDING = 0,
  PROCESSING = 1,
  APPROVED = 2,
  DECLINED = 3,
  ACTIVE = 4,
  INACTIVE = 5,
}

export interface Partner {
  id: number;
  firstname: string | null;
  lastname:string | null;
  mi: string | null;
  mobile: string | null;
  email: string | null;
  address: string | null;
  e_wallet_number: string | null;
  province: string | null;
  city: string | null;
  zip_code: string | null;
  attachment: string | null;
  status: PartnerStatusEnum;
  created_at: string | null;
}
