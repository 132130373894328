import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import PartnerForm from "pages/partners/PartnerForm";
import { Fragment, useRef } from "react";
import {
  selectPartnerToView, selectUpdatePartnerFormValidationError,
  selectUpdatePartnerRequest,
  selectUpdatingPartner, setPartnerToView, setUpdatePartnerRequestFieldValue, updatePartner
} from "store/partners/updatePartnerSlice";
import PartnerLockersTable from "./PartnerLockersTable";

export default function ViewPartnerModal() {
  const dispatch = useAppDispatch();

  const partnerToView = useAppSelector(selectPartnerToView);
  const updatePartnerRequest = useAppSelector(selectUpdatePartnerRequest);
  const updatePartnerFormValidationError = useAppSelector(selectUpdatePartnerFormValidationError);
  const updatingPartner = useAppSelector(selectUpdatingPartner);

  const initialFocusRef = useRef(null);

  const formSubmitHandler = (e: Event) => {
    e.preventDefault();
    dispatch(updatePartner(updatePartnerRequest))
  };

  function setFieldValue(fieldName: string, fieldValue: any) {
    dispatch(setUpdatePartnerRequestFieldValue({ fieldName, fieldValue }))
  }

  return (
    <Transition.Root show={partnerToView ? true : false} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => updatingPartner ? null : dispatch(setPartnerToView(null))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !updatingPartner
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setPartnerToView(null))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }

                  <div className="mt-3 sm:mt-5">
                    {/* <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      View Partner { partnerToView?.id }
                    </Dialog.Title> */}

                    <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl bg-cyan-900/20 p-1">
                        <Tab
                          className={({ selected }) =>
                            [
                              'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white',
                              'ring-white ring-opacity-60 ring-offset-2 ring-offset-cyan-400 focus:outline-none focus:ring-2',
                              selected
                                ? 'bg-cyan-700 shadow'
                                : 'text-cyan-700 bg-white hover:bg-cyan-700/[0.25] hover:text-white'
                            ].join(' ')
                          }
                        >Partner's Information</Tab>

                        <Tab
                          className={({ selected }) =>
                            [
                              'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white',
                              'ring-white ring-opacity-60 ring-offset-2 ring-offset-cyan-400 focus:outline-none focus:ring-2',
                              selected
                                ? 'bg-cyan-700 shadow'
                                : 'text-cyan-700 bg-white hover:bg-cyan-700/[0.25] hover:text-white'
                            ].join(' ')
                          }
                        >Partner's Lockers</Tab>
                      </Tab.List>

                      <Tab.Panels>
                        <Tab.Panel>
                          <PartnerForm
                            initialFocusRef={initialFocusRef}
                            formSubmitHandler={formSubmitHandler}
                            cancelHandler={() => dispatch(setPartnerToView(null))}
                            request={updatePartnerRequest}
                            setFieldValue={setFieldValue}
                            submittingForm={updatingPartner}
                            formValidationError={updatePartnerFormValidationError}
                            formType="view"
                          />
                        </Tab.Panel>

                        <Tab.Panel>
                          {
                            partnerToView ? <>
                              <PartnerLockersTable partnerId={partnerToView.id} />
                            </> : <></>
                          }
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
