import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import authSlice from "./auth/authSlice";
import updateProfileSlice from "./auth/updateProfileSlice";
import freePointsSlice from "./auth/freePointsSlice";

import createUserSlice from "./users/createUserSlice";
import deleteUserSlice from "./users/deleteUserSlice";
import updateUserSlice from "./users/updateUserSlice";
import userListSlice from "./users/userListSlice";

import createPartnerSlice from "./partners/createPartnerSlice";
import deletePartnerSlice from "./partners/deletePartnerSlice";
import partnerListSlice from "./partners/partnerListSlice";
import updatePartnerSlice from "./partners/updatePartnerSlice";

import createLockerSlice from "./lockers/createLockerSlice";
import deleteLockerSlice from "./lockers/deleteLockerSlice";
import lockerListSlice from "./lockers/lockerListSlice";
import updateLockerSlice from "./lockers/updateLockerSlice";

import createDrawerSlice from "./lockers/createDrawerSlice";
import deleteDrawerSlice from "./lockers/deleteDrawerSlice";
import drawerListSlice from "./lockers/drawerListSlice";
import updateDrawerSlice from "./lockers/updateDrawerSlice";

import transactionListSlice from "./transactions/transactionListSlice";

const store = configureStore({
  reducer: {
    authSlice: authSlice,
    updateProfileSlice: updateProfileSlice,
    freePointsSlice: freePointsSlice,
    
    userListSlice: userListSlice,
    createUserSlice: createUserSlice,
    updateUserSlice: updateUserSlice,
    deleteUserSlice: deleteUserSlice,

    partnerListSlice: partnerListSlice,
    createPartnerSlice: createPartnerSlice,
    updatePartnerSlice: updatePartnerSlice,
    deletePartnerSlice: deletePartnerSlice,

    lockerListSlice: lockerListSlice,
    createLockerSlice: createLockerSlice,
    deleteLockerSlice: deleteLockerSlice,
    updateLockerSlice: updateLockerSlice,

    drawerListSlice: drawerListSlice,
    createDrawerSlice: createDrawerSlice,
    deleteDrawerSlice: deleteDrawerSlice,
    updateDrawerSlice: updateDrawerSlice,

    transactionListSlice,
  },
}
);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
