import { Locker } from "./Locker";

export enum DrawerStatus {
  EMPTY = 0,
  OCCUPIED = 1,
}

export enum DrawerSize {
  XS = 0,
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  XL = 4,
  XXL = 5,
}

export interface Drawer {
  id: number;
  locker_code: string | null;
  cu_number: string | number;
  position: string | number;
  size: number | DrawerSize;
  price: string | number;
  status: number | DrawerStatus;
  locker: Locker;
  created_at: string;
}
