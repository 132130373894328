import AppFieldError from "components/AppFieldError";
import LoadingIcon from "components/LoadingIcon";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { FormEvent, FormEventHandler, useEffect, useRef, useState } from "react";
import { getAuthProfile, selectAccessToken } from "store/auth/authSlice";
import { selectFreePoints, getFreePoints, selectGettingFreePoints, selectGetFreePointsFormValidationError,
  cancelUpdateFreePoints, selectUpdateFreePointsFormValidationError, selectUpdateFreePointsRequest, 
  selectUpdatingFreePoints, setFreePointsToUpdate, setUpdateFreePointsRequestFieldValue, updateFreePoints } from "store/auth/freePointsSlice";

export default function FreePointsSettingsPage() {
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(selectAccessToken);

  const updateFreePointsRequest = useAppSelector(selectUpdateFreePointsRequest);
  const updateFreePointsFormValidationError = useAppSelector(selectUpdateFreePointsFormValidationError);
  const updatingFreePoints = useAppSelector(selectUpdatingFreePoints);


  function cancelHandler() {
    dispatch(cancelUpdateFreePoints())
    dispatch(setFreePointsToUpdate({
      freePointsToUpdate: freePoints
    }))
  }

  const formSubmitHandler: FormEventHandler<HTMLFormElement> = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateFreePoints(updateFreePointsRequest)).then((action) => {
      if (action.type === 'freePointsSlice/updateFreePoints/fulfilled') {
        if (accessToken) {
          dispatch(getFreePoints(accessToken))
        }
      }
    })
  };

  function setFieldValue(fieldName: string, fieldValue: string | number) {
    dispatch(setUpdateFreePointsRequestFieldValue({ fieldName, fieldValue }))
  }

  const freePoints = useAppSelector(selectFreePoints)

  
  useEffect(() => {
    dispatch(getFreePoints(accessToken!));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    dispatch(setFreePointsToUpdate({
      freePointsToUpdate: freePoints
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freePoints])
  
  return (
    <>
      {/* Main content */}
      <div className="flex-1 xl:overflow-y-auto">
        <div className="mx-auto max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-cyan-gray-900">
            Free Points
          </h1>

          <form className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y" onSubmit={formSubmitHandler}>
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-8 sm:gap-x-6">
              <div className="sm:col-span-8">
                <p className="mt-1 text-sm text-cyan-gray-500">
                  This is to view and change the free points value for user registration.
                </p>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="freepoints"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Free Points Value
                </label>

                <input
                  type="number"
                  name="freepoints"
                  id="freepoints"
                  autoComplete="freepoints"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={updateFreePointsRequest.freepoints}
                  onChange={(e) => setFieldValue('freepoints', e.target.value)}

                />

                <AppFieldError fieldName={'freepoints'} formValidationError={updateFreePointsFormValidationError} />
              </div>
            </div>

            <div>
              <div className="flex justify-end pt-8">
                <button
                  type="button"
                  disabled={updatingFreePoints}
                  className={`${updatingFreePoints ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                  onClick={cancelHandler}
                >
                  Revert Change
                </button>

                <button
                  type="submit"
                  disabled={updatingFreePoints}
                  className={`${updatingFreePoints ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                >
                  { updatingFreePoints ? <><LoadingIcon /><span>Loading...</span></> : 'Save' }
                </button>
              </div>

              {
                updateFreePointsFormValidationError
                  ?
                    <div className="flex justify-end">
                      <small className="text-red-500">{ updateFreePointsFormValidationError.message }</small>
                    </div>
                  :
                    <></>
              }
            </div>

          </form>
        </div>
      </div>
    </>
  );
}
