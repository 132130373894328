import AnnoucementTab from "components/AnnoucementTab";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import LockersContext from "contexts/LockersContext";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchLockers } from "hooks/useFetchLockers";
import useLockersState from "hooks/useLockersState";
import CreateLockerModal from "pages/lockers/CreateLockerModal";
import EditLockerModal from "pages/lockers/EditLockerModal";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cancelDeleteLockerModal, deleteLocker, selectDeleteLockerFormValidationError, selectDeletingLocker, selectLockerToDelete, setLockerToDelete } from "store/lockers/deleteLockerSlice";
import LockersFiltersModal from "./LockersFiltersModal";
import LockersTable from "./LockersTable";
import ViewLockerModal from "./ViewLockerModal";
import CreateDrawerModal from "./CreateDrawerModal";

export default function LockersPage() {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') ? searchParams.get('page') : '1'
  const emailFromParams = searchParams.get('partner_email') || ''
  const statusFromParams = searchParams.get('status') || ''
  const startDateFromParams = searchParams.get('start_date') || ''
  const endDateFromParams = searchParams.get('end_date') || ''

  const dispatch = useAppDispatch()
  const lockerToDelete = useAppSelector(selectLockerToDelete)
  const deletingLocker = useAppSelector(selectDeletingLocker)
  const deleteLockerFormValidationError = useAppSelector(selectDeleteLockerFormValidationError)
  const fetchLockers = useFetchLockers()

  const confirmDeleteLockerHandler = () => {
    if (lockerToDelete) {
      dispatch(deleteLocker(lockerToDelete)).then(() => {
        fetchLockers()
      })
    }
  };

  useEffect(() => {
    fetchLockers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageFromParams,
    emailFromParams,
    statusFromParams,
    startDateFromParams,
    endDateFromParams,
  ]);

  const lockersState = useLockersState()

  return (
    <>
      <LockersContext.Provider value={lockersState}>
        <main className="flex-1 overflow-y-auto">
          {/* Primary column */}
          <section
            aria-labelledby="primary-heading"
            className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
          >
            <LockersTable/>
          </section>
        </main>

        {/* Secondary column (hidden on smaller screens) */}
        {/* <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
          <AnnoucementTab />
        </aside> */}

        <CreateLockerModal />

        <EditLockerModal />

        <ViewLockerModal />

        <LockersFiltersModal />

        <CreateDrawerModal />

        {/* <EditDrawerModal />

        <ViewDrawerModal /> */}

        <DeleteConfirmationModal
          title={'Delete locker'}
          subtitle={
            <>
              Are you sure you want to delete this locker ({lockerToDelete?.id})?
              All of their data will be permanently removed from our servers forever.
              This action cannot be undone.
            </>
          }
          openModal={lockerToDelete ? true : false}
          loading={deletingLocker}
          closeHandler={() => dispatch(setLockerToDelete(null))}
          cancelHandler={() => dispatch(cancelDeleteLockerModal())}
          confirmHandler={confirmDeleteLockerHandler}
          formValidationError={deleteLockerFormValidationError}
        />
      </LockersContext.Provider>
    </>
  );
}
