import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { User } from 'models';
import { UpdateUserRequest, userService } from "services/userService";
import { RootState } from 'store/store';

interface UpdateUserState {
  userToUpdate: User | null;
  updateUserRequest: UpdateUserRequest;
  updatingUser: boolean;
  updateUserFormValidationError: FormValidationError | null;
}

const updateUserRequestIntialValue = {
  id: '',
  // email: '',
  // password: '',
  firstname: '',
  lastname: '',
  mi: '',
  phone: '',
  address: '',
  // city: '',
  // province: '',
  // zip_code: '',
  e_wallet_number: '',
  // attachment: '',
  points: 0,
  is_enabled: true,
}

const updateUserInitialState: UpdateUserState = {
  userToUpdate: null,
  updateUserRequest: updateUserRequestIntialValue,
  updatingUser: false,
  updateUserFormValidationError: null,
}

export const updateUser = createAsyncThunk('updateUserModal/updateUser', async (updateUserRequest: UpdateUserRequest, thunkAPI) => {
  try {
    const request = {
      ...updateUserRequest
    }
    // delete request.attachment
    const updateUserResponse = await userService.updateUser(request);
    return updateUserResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        let veLength = validationErrors.length;
        if (veLength > 0) {
          message = "Validation Error";
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const updateUserSlice = createSlice({
  name: 'updateUserSlice',
  initialState: updateUserInitialState,
  reducers: {
    setUserToUpdate: (state, action: PayloadAction<User | null>) => {
      state.userToUpdate = action.payload

      console.log("setUserToUpdate")
      console.log(state.userToUpdate)
      if (state.userToUpdate) {
        console.log("setUserToUpdate in if")
        console.log(state.userToUpdate)
        state.updateUserRequest.id = state.userToUpdate.id
        // state.updateUserRequest.email = state.userToUpdate.email
        // state.updateUserRequest.password = ''
        state.updateUserRequest.firstname = state.userToUpdate.profile.firstname!
        state.updateUserRequest.lastname = state.userToUpdate.profile.lastname!
        state.updateUserRequest.mi = state.userToUpdate.profile.mi
        state.updateUserRequest.phone = state.userToUpdate.profile.phone!
        state.updateUserRequest.address = state.userToUpdate.profile.address!
        state.updateUserRequest.points = state.userToUpdate.profile.points
        state.updateUserRequest.is_enabled = state.userToUpdate.is_enabled
        // state.updateUserRequest.city = state.userToUpdate.profile.city
        // state.updateUserRequest.province = state.userToUpdate.profile.province
        // state.updateUserRequest.zip_code = state.userToUpdate.profile.zip_code
        state.updateUserRequest.e_wallet_number = state.userToUpdate.profile.e_wallet_number!
        // state.updateUserRequest.attachment = state.userToUpdate.profile.attachment
      } else {
        state.updateUserRequest = updateUserRequestIntialValue
      }

      state.updateUserFormValidationError = null
    },

    setUpdateUserRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.updateUserRequest[action.payload.fieldName] = action.payload.fieldValue
      switch(action.payload.fieldName) {
        case 'id':
          state.updateUserRequest.id = action.payload.fieldValue
          break;
        // case 'email':
        //   state.updateUserRequest.email = action.payload.fieldValue
        //   break;
        // case 'password':
        //   state.updateUserRequest.password = action.payload.fieldValue
        //   break;
        case 'firstname':
          state.updateUserRequest.firstname = action.payload.fieldValue
          break;
        case 'lastname':
          state.updateUserRequest.lastname = action.payload.fieldValue
          break;
        case 'mi':
          state.updateUserRequest.mi = action.payload.fieldValue
          break;
        case 'phone':
          state.updateUserRequest.phone = action.payload.fieldValue
          break;
        case 'address':
          state.updateUserRequest.address = action.payload.fieldValue
          break;
        case 'points':
          state.updateUserRequest.points = action.payload.fieldValue
          break;
        case 'is_enabled':
          state.updateUserRequest.is_enabled = action.payload.fieldValue
          break;
        case 'e_wallet_number':
          state.updateUserRequest.e_wallet_number = action.payload.fieldValue
          break;
        // case 'city':
        //   state.updateUserRequest.city = action.payload.fieldValue
        //   break;
        // case 'province':
        //   state.updateUserRequest.province = action.payload.fieldValue
        //   break;
        // case 'zip_code':
        //   state.updateUserRequest.zip_code = action.payload.fieldValue
        //   break;
      }
      
      if (state.updateUserFormValidationError && state.updateUserFormValidationError.validationErrors) {
        state.updateUserFormValidationError.validationErrors = state.updateUserFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.updateUserFormValidationError.validationErrors.length === 0) {
          state.updateUserFormValidationError = null
        }
      }
    },

    cancelUpdateUserModal: (state) => {
      state.userToUpdate = null
      state.updateUserRequest = updateUserRequestIntialValue
      state.updatingUser = false
      state.updateUserFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.updatingUser = true
        state.updateUserFormValidationError = null
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.userToUpdate = null
        state.updatingUser = false
        state.updateUserRequest = updateUserRequestIntialValue
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserFormValidationError = action.payload as FormValidationError
        state.updatingUser = false
      });
  },
})

export const { setUserToUpdate, setUpdateUserRequestFieldValue, cancelUpdateUserModal } = updateUserSlice.actions

export const selectUserToUpdate = (state: RootState) => state.updateUserSlice.userToUpdate;
export const selectUpdateUserRequest = (state: RootState) => state.updateUserSlice.updateUserRequest;
export const selectUpdatingUser = (state: RootState) => state.updateUserSlice.updatingUser;
export const selectUpdateUserFormValidationError = (state: RootState) => state.updateUserSlice.updateUserFormValidationError;

export default updateUserSlice.reducer
