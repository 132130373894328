import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError } from 'errors/FormValidationError';
import { Locker } from 'models';
import qs from 'qs';
import { GetLockersRequest, lockerService } from "services/lockerService";
import { asyncThunkErrorHandler } from 'store/asyncThunkErrorHandler';
import { RootState } from 'store/store';
import { createLocker } from './createLockerSlice';
import { deleteLocker } from './deleteLockerSlice';
import { updateLocker } from './updateLockerSlice';
const qsParsed = qs.parse(window.location.search.slice(1))

interface LockerListState {
  gettingLockersLoading: boolean;
  lockersCount: number;
  lockers: Locker[];
  lockersPerPage: number;
  lockersCurrentPage: number;
  getLockersRequest: GetLockersRequest;
  getLockersFormValidationError: FormValidationError | null;
}

const lockerListInitialState: LockerListState = {
  gettingLockersLoading: false,
  lockersCount: 0,
  lockers: [],
  lockersPerPage: 8,
  lockersCurrentPage: qsParsed.page ? Number(qsParsed.page) : 1,
  getLockersRequest: {},
  getLockersFormValidationError: null,
}

export const fetchLockers = createAsyncThunk('lockerList/fetchLockers', async (getLockersRequest: GetLockersRequest, thunkAPI) => {
  try {
    const getLockersResponse = await lockerService.getLockers(getLockersRequest);
    return getLockersResponse;
  } catch (err: any) {
    return asyncThunkErrorHandler(err, thunkAPI)
  }
});

export const lockerListSlice = createSlice({
  name: 'lockerListSlice',
  initialState: lockerListInitialState,
  reducers: {
    setLockersCurrentPage: (state, action: PayloadAction<number>) => {
      state.lockersCurrentPage = action.payload
    },

    removeLockerFromList: (state, action: PayloadAction<number>) => {
      const lockerId = action.payload
      
      const index = state.lockers.findIndex((locker) => locker.id === lockerId)

      if (index) {
        state.lockers.splice(index, 1)
      }

      state.lockersCount--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLocker.fulfilled, (state) => {
        state.lockersCount++
      })

      .addCase(updateLocker.fulfilled, (state, action) => {
        const index = state.lockers.findIndex((locker) => locker.id === action.meta.arg.id)

        if (index > -1) {
          state.lockers.splice(index, 1, action.payload.data)
        }
      })

      .addCase(deleteLocker.fulfilled, (state, action) => {
        const index = state.lockers.findIndex((locker) => locker.id === action.meta.arg.id)

        if (index > -1) {
          state.lockers.splice(index, 1)
        }

        state.lockersCount--
      })

      .addCase(fetchLockers.pending, (state, action) => {
        state.getLockersRequest = action.meta.arg
        state.gettingLockersLoading = true
        state.getLockersFormValidationError = null
      })
      .addCase(fetchLockers.fulfilled, (state, action) => {
        state.gettingLockersLoading = false

        const meta = action.payload.lockers.meta

        if (meta) {
          state.lockersCount = meta.total
          state.lockers = action.payload.lockers.data;
          state.lockersPerPage = meta.per_page
          state.lockersCurrentPage = meta.current_page
        } else {
          state.lockersCount = action.payload.lockers.data.length
          state.lockers = action.payload.lockers.data;
          state.lockersPerPage = action.payload.lockers.data.length
          state.lockersCurrentPage = 1
        }
      })
      .addCase(fetchLockers.rejected, (state, action) => {
        state.gettingLockersLoading = false
        state.lockersCount = 0
        state.lockers = []
        state.getLockersFormValidationError = (action.meta.rejectedWithValue ? action.payload : action.error) as FormValidationError
      });
  },
})

export const { setLockersCurrentPage, removeLockerFromList } = lockerListSlice.actions

export const selectLockersCount = (state: RootState) => state.lockerListSlice.lockersCount;
export const selectLockers = (state: RootState) => state.lockerListSlice.lockers;
export const selectGettingLockersLoading = (state: RootState) => state.lockerListSlice.gettingLockersLoading;
export const selectLockersPerPage = (state: RootState) => state.lockerListSlice.lockersPerPage;
export const selectLockersNumberOfPages = (state: RootState) => Math.ceil(state.lockerListSlice.lockersCount / state.lockerListSlice.lockersPerPage);
export const selectLockersCurrentPage = (state: RootState) => state.lockerListSlice.lockersCurrentPage;
export const selectGetLockersFormValidationError = (state: RootState) => state.lockerListSlice.getLockersFormValidationError;

export default lockerListSlice.reducer
