import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { CreatePartnerRequest, partnerService } from "services/partnerService";
import { RootState } from 'store/store';

interface CreatePartnerState {
  createPartnerModalOpen: boolean;
  createPartnerRequest: CreatePartnerRequest;
  creatingPartner: boolean;
  createPartnerFormValidationError: FormValidationError | null;
}

const createPartnerRequestIntialValue = {
  firstname: '',
  lastname: '',
  mi: '',
  mobile: '',
  email: '',
  address: '',
  province: '',
  city: '',
  zip_code: '',
  e_wallet_number: '',
  attachment: '',
}

const createPartnerInitialState: CreatePartnerState = {
  createPartnerModalOpen: false,
  createPartnerRequest: createPartnerRequestIntialValue,
  creatingPartner: false,
  createPartnerFormValidationError: null,
}

export const createPartner = createAsyncThunk('createPartnerModal/createPartner', async (createPartnerRequest: CreatePartnerRequest, thunkAPI) => {
  try {
    const request = {
      ...createPartnerRequest
    }
    if (!request.attachment) {
      delete request.attachment
    }
    const createPartnerResponse = await partnerService.createPartner(request);
    return createPartnerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const createPartnerSlice = createSlice({
  name: 'createPartnerSlice',
  initialState: createPartnerInitialState,
  reducers: {
    setCreatePartnerModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createPartnerModalOpen = action.payload
    },

    setCreatePartnerRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: string}>) => {
      // state.createPartnerRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'firstname') {
        state.createPartnerRequest.firstname = action.payload.fieldValue
      } else if (action.payload.fieldName === 'lastname') {
        state.createPartnerRequest.lastname = action.payload.fieldValue
      } else if (action.payload.fieldName === 'mi') {
        state.createPartnerRequest.mi = action.payload.fieldValue
      } else if (action.payload.fieldName === 'mobile') {
        state.createPartnerRequest.mobile = action.payload.fieldValue
      } else if (action.payload.fieldName === 'email') {
        state.createPartnerRequest.email = action.payload.fieldValue
      } else if (action.payload.fieldName === 'address') {
        state.createPartnerRequest.address = action.payload.fieldValue
      } else if (action.payload.fieldName === 'province') {
        state.createPartnerRequest.province = action.payload.fieldValue
      } else if (action.payload.fieldName === 'city') {
        state.createPartnerRequest.city = action.payload.fieldValue
      } else if (action.payload.fieldName === 'zip_code') {
        state.createPartnerRequest.zip_code = action.payload.fieldValue
      } else if (action.payload.fieldName === 'e_wallet_number') {
        state.createPartnerRequest.e_wallet_number = action.payload.fieldValue
      } else if (action.payload.fieldName === 'attachment') {
        state.createPartnerRequest.attachment = action.payload.fieldValue
      }
      
      if (state.createPartnerFormValidationError && state.createPartnerFormValidationError.validationErrors) {
        state.createPartnerFormValidationError.validationErrors = state.createPartnerFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.createPartnerFormValidationError.validationErrors.length === 0) {
          state.createPartnerFormValidationError = null
        }
      }
    },

    cancelCreatePartnerModal: (state) => {
      state.createPartnerModalOpen = false
      state.createPartnerRequest = createPartnerRequestIntialValue
      state.creatingPartner = false
      state.createPartnerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPartner.pending, (state) => {
        state.creatingPartner = true
        state.createPartnerFormValidationError = null
      })
      .addCase(createPartner.fulfilled, (state) => {
        state.createPartnerModalOpen = false
        state.creatingPartner = false
        state.createPartnerRequest = createPartnerRequestIntialValue
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.createPartnerFormValidationError = action.payload as FormValidationError
        state.creatingPartner = false
      });
  },
})

export const { setCreatePartnerModalOpen, setCreatePartnerRequestFieldValue, cancelCreatePartnerModal } = createPartnerSlice.actions

export const selectCreatePartnerModalOpen = (state: RootState) => state.createPartnerSlice.createPartnerModalOpen;
export const selectCreatePartnerRequest = (state: RootState) => state.createPartnerSlice.createPartnerRequest;
export const selectCreatingPartner = (state: RootState) => state.createPartnerSlice.creatingPartner;
export const selectCreatePartnerFormValidationError = (state: RootState) => state.createPartnerSlice.createPartnerFormValidationError;

export default createPartnerSlice.reducer
