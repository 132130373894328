import AppValue from "components/AppValue";
import TransactionsContext from "contexts/TransactionsContent";
import { Transaction } from "models";
import { useContext } from 'react';

export interface TransactionViewProps {
  transaction: Transaction;
}

export default function TransactionView({
  transaction,
}: TransactionViewProps) {
  const { setTransactionToView } = useContext(TransactionsContext)

  return (
    <div className="mt-2">
      <form
        className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y"
        onSubmit={() => null}
      >
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          {/* <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-cyan-gray-900">
              Locker Information
            </h2>
          </div> */}

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Code
            </label>

            <AppValue
              value={transaction.code?.toString() || ''}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Locker Code
            </label>

            <AppValue
              value={transaction.locker?.code?.toString() || ''}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Amount
            </label>

            <AppValue
              value={transaction.amount || ''}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Drawer
            </label>

            <AppValue
              value={<>
                <div className="text-gray-900">
                  Drawer Number: {transaction.drawer_number}
                </div>
                <div className="text-gray-600">
                  Drawer size: {transaction.drawer_size}
                </div>
              </>}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Sender
            </label>

            <AppValue
              value={transaction.sender_name || ''}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Recipient
            </label>

            <AppValue
              value={<>
                <div className="text-gray-900">
                  {transaction.recipient_name}
                </div>
                <div className="text-gray-600">
                  {transaction.recipient_mobile}
                </div>
              </>}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Status
            </label>

            <AppValue
              value={transaction.status || ''}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Deposited At
            </label>

            <AppValue
              value={transaction.started_at || ''}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Claimed At
            </label>

            <AppValue
              value={transaction.status === 'claimed' ? transaction.updated_at : 'N/A'}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-end pt-8">
            <button
              type="button"
              className={`${false ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
              onClick={() => setTransactionToView(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
