import { createContext } from 'react';

interface UsersState {
  showFilterModal: boolean;
  setShowFilterModal: (showFilterModal: boolean) => void;
}

const globalInitialState: UsersState = {
  showFilterModal: false,
  setShowFilterModal: () => {},
}

const UsersContext = createContext(globalInitialState)

export default UsersContext
