import AppHeader from "components/partials/AppHeader";
import Sidebar from "components/partials/Sidebar";
import { useAppSelector } from "hooks/store-hooks";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { selectAccessToken } from "store/auth/authSlice";

export default function MainLayout() {
  const accessToken = useAppSelector(selectAccessToken)

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      return navigate("/login")
    }

    if (location.pathname === "/") {
      return navigate("/overview")
    };

  }, [accessToken, navigate, location.pathname]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const setMobileMenuOpenHandler = () => {
    setMobileMenuOpen(true);
  };

  const setMobileMenuCloseHandler = () => {
    setMobileMenuOpen(false);
  };
  return (
    <>
      <div className="flex h-full">
        <Sidebar
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuCloseHandler={setMobileMenuCloseHandler}
          setMobileMenuOpenHandler={setMobileMenuOpenHandler}
        />
        {/* Content area */}
        <div className="flex flex-1 flex-col overflow-hidden">
          <AppHeader setMobileMenuOpenHandler={setMobileMenuOpenHandler} />
          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden">
            {/* MAIN SECTION HERE, START OF PAGE COMPONENTS */}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
