import { useSearchParams } from "react-router-dom";
import { GetLockersRequest } from "services/lockerService";
import { fetchLockers } from "store/lockers/lockerListSlice";
import { useAppDispatch } from "./store-hooks";

export function useFetchLockers () {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') || '1'
  const emailFromParams = searchParams.get('partner_email') || ''
  const statusFromParams = searchParams.get('status') || ''
  const startDateFromParams = searchParams.get('start_date') || ''
  const endDateFromParams = searchParams.get('end_date') || ''

  const dispatch = useAppDispatch();

  function _fetchLockers() {
    const getRequest: GetLockersRequest = {}

    if (pageFromParams) {
      getRequest.page = pageFromParams
    }

    if (emailFromParams) {
      getRequest.partner_email = emailFromParams
    }

    if (statusFromParams) {
      getRequest.status = statusFromParams
    }

    if (startDateFromParams) {
      getRequest.start_date = startDateFromParams
    }

    if (endDateFromParams) {
      getRequest.end_date = endDateFromParams
    }

    dispatch(fetchLockers(getRequest))
  }

  return _fetchLockers
}
