import { createContext } from 'react';

interface DrawersState {
  showFilterModal: boolean;
  setShowFilterModal: (showFilterModal: boolean) => void;
}

const globalInitialState: DrawersState = {
  showFilterModal: false,
  setShowFilterModal: () => {},
}

const DrawersContext = createContext(globalInitialState)

export default DrawersContext
