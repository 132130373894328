import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export default function useQueryState(queryName: string) {
  const [searchParams] = useSearchParams()

  const valueFromParams = searchParams.get(queryName) || ''

  const [value, setValue] = useState<string>(queryName)

  useEffect(() => {
    setValue(valueFromParams)
  }, [valueFromParams])

  return { value, setValue }
}
