import PsgcContext from "contexts/PsgcContext";
import { useContext } from "react";

export interface UsePsgcOptionsProps {
  regionValue: string
  provinceValue: string
  cityValue: string
}

interface SelectOption {
  label: string
  value: string
}

export function usePsgcOptions ({ regionValue, provinceValue, cityValue }: UsePsgcOptionsProps) {
  const { regions, provinces, cityOrMunicipalities, barangays } = useContext(PsgcContext)
  
  const ncrDistricts = [
    'NCR, City of Manila, First District (Not a Province)',
    'NCR, Second District (Not a Province)',
    'NCR, Third District (Not a Province)',
    'NCR, Fourth District (Not a Province)',
  ]

  const regionsOptions = regions.map((option) => {
    return {
      label: option[1],
      value: option[1],
    }
  })

  let provincesOptions: SelectOption[] = []

  if (regionValue) {
    const selectedRegion = regions.find((array) => array[1] === regionValue)

    if (selectedRegion) {
      provincesOptions = provinces.filter((array) => {
        if (selectedRegion[1] === 'National Capital Region (NCR)') {
          return ncrDistricts.includes(array[1])
        }

        return array[0].slice(0, 2) === selectedRegion[0].slice(0, 2)
      }).sort((a, b) => {
        if (selectedRegion[1] === 'National Capital Region (NCR)') {
          return ncrDistricts.indexOf(a[1]) - ncrDistricts.indexOf(b[1])
        }

        return a[1] > b[1] ? 1 : -1
      }).map((option) => {
        return {
          label: option[1].replace(' (Not a Province)', ''),
          value: option[1].replace(' (Not a Province)', ''),
        }
      })
    }
  }

  let cityOrMunicipalitiesOptions: SelectOption[] = []

  if (provinceValue) {
    const selectedProvince = provinces.find((array) => array[1].replace(' (Not a Province)', '') === provinceValue)

    if (selectedProvince) {
      cityOrMunicipalitiesOptions = cityOrMunicipalities.filter((array) => {
        if (selectedProvince[1] === 'NCR, City of Manila, First District (Not a Province)') {
          return array[0].slice(0, 5) === '13806'
        } else  if (selectedProvince[1] === 'NCR, Second District (Not a Province)') {
          return array[0].slice(0, 5) === '13805'
          || array[0].slice(0, 5) === '13807'
          || array[0].slice(0, 5) === '13812'
          || array[0].slice(0, 5) === '13813'
          || array[0].slice(0, 5) === '13814'
        } else  if (selectedProvince[1] === 'NCR, Third District (Not a Province)') {
          return array[0].slice(0, 5) === '13801'
            || array[0].slice(0, 5) === '13804'
            || array[0].slice(0, 5) === '13809'
            || array[0].slice(0, 5) === '13816'
        } else  if (selectedProvince[1] === 'NCR, Fourth District (Not a Province)') {
          return array[0].slice(0, 5) === '13802'
            || array[0].slice(0, 5) === '13803'
            || array[0].slice(0, 5) === '13808'
            || array[0].slice(0, 5) === '13810'
            || array[0].slice(0, 5) === '13811'
            || array[0].slice(0, 5) === '13817'
            || array[0].slice(0, 5) === '13815'
        }

        return array[0].slice(0, 5) === selectedProvince[0].slice(0, 5)
      }).map((option) => {
        return {
          label: option[1].replace(' (Capital)', ''),
          value: option[1].replace(' (Capital)', ''),
        }
      }).sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })
    }
  }

  let barangaysOptions: SelectOption[] = []

  if (cityValue) {
    const selectedCityOrMunicipality = cityOrMunicipalities.find((array) => array[1].replace(' (Capital)', '') === cityValue)

    if (selectedCityOrMunicipality) {
      barangaysOptions = barangays.filter((array) => {
        return array[0].slice(0, 7) === selectedCityOrMunicipality[0].slice(0, 7)
      }).map((option) => {
        return {
          label: option[1],
          value: option[1],
        }
      }).sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })
    }
  }

  function getRegionFromProvice(provinceValue: string) {
    if (ncrDistricts.map(v => v.replace(' (Not a Province)', '')).includes(provinceValue)) {
      return 'National Capital Region (NCR)'
    }

    const selectedProvince = provinces.find((array) => array[1].replace(' (Not a Province)', '') === provinceValue)

    if (!selectedProvince) {
      return ''
    }
  
    const region = regions.find(region => {
      return region[0].slice(0, 2) === selectedProvince[0].slice(0, 2)
    })
  
    return region ? region[1] : ''
  }

  return {
    regionsOptions,
    provincesOptions,
    cityOrMunicipalitiesOptions,
    barangaysOptions,
    getRegionFromProvice,
  }
}
