import { ValidationError } from "errors/FormValidationError"

export function asyncThunkErrorHandler (err: any, thunkAPI: any) {
  console.log('err', err.response || err)

  let message: string | null = null

  let validationErrors: ValidationError[] = []

  if (err.response) {
    if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
      message = err.response.data.message

      validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
        if (err.response.data.errors[fieldName].length > 0) {
          err.response.data.errors[fieldName].forEach((errorMessage: string) => {
            validationErrors.push({
              field: fieldName,
              message: errorMessage
            })
          })
        }

        return validationErrors
      }, [] as ValidationError[])

      if (validationErrors.length > 0) {
        message = 'Validation Error!'
      }
    } else {
      message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
    }

    return thunkAPI.rejectWithValue({ message, validationErrors })
  } else if (err.request) {
    throw new Error('Something went wrong!')
  }

  throw err
}
