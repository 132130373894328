import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Partner } from 'models';
import { partnerService, UpdatePartnerRequest } from "services/partnerService";
import { RootState } from 'store/store';

interface UpdatePartnerState {
  partnerToView: Partner | null;
  partnerToUpdate: Partner | null;
  updatePartnerRequest: UpdatePartnerRequest;
  updatingPartner: boolean;
  updatePartnerFormValidationError: FormValidationError | null;
}

const updatePartnerRequestIntialValue = {
  id: '',
  status: 0,
  firstname: '',
  lastname: '',
  mi: '',
  mobile: '',
  email: '',
  address: '',
  province: '',
  city: '',
  zip_code: '',
  e_wallet_number: '',
  attachment: '',
}

const updatePartnerInitialState: UpdatePartnerState = {
  partnerToView: null,
  partnerToUpdate: null,
  updatePartnerRequest: updatePartnerRequestIntialValue,
  updatingPartner: false,
  updatePartnerFormValidationError: null,
}

export const updatePartner = createAsyncThunk('updatePartnerModal/updatePartner', async (updatePartnerRequest: UpdatePartnerRequest, thunkAPI) => {
  try {
    const request = {
      ...updatePartnerRequest
    }
    const updatePartnerResponse = await partnerService.updatePartner(request);
    return updatePartnerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const updatePartnerSlice = createSlice({
  name: 'updatePartnerSlice',
  initialState: updatePartnerInitialState,
  reducers: {
    setPartnerToView: (state, action: PayloadAction<Partner | null>) => {
      state.partnerToView = action.payload

      if (state.partnerToView) {
        state.updatePartnerRequest = {
          id: state.partnerToView.id || '',
          status: state.partnerToView.status || 0,
          firstname: state.partnerToView.firstname || '',
          lastname: state.partnerToView.lastname || '',
          mi: state.partnerToView.mi || '',
          mobile: state.partnerToView.mobile || '',
          email: state.partnerToView.email || '',
          address: state.partnerToView.address || '',
          province: state.partnerToView.province || '',
          city: state.partnerToView.city || '',
          zip_code: state.partnerToView.zip_code || '',
          e_wallet_number: state.partnerToView.e_wallet_number || '',
          attachment: state.partnerToView.attachment || '',
        }
      } else {
        state.updatePartnerRequest = updatePartnerRequestIntialValue
      }

      state.updatePartnerFormValidationError = null
    },

    setPartnerToUpdate: (state, action: PayloadAction<Partner | null>) => {
      state.partnerToUpdate = action.payload

      if (state.partnerToUpdate) {
        state.updatePartnerRequest = {
          id: state.partnerToUpdate.id || '',
          status: state.partnerToUpdate.status || 0,
          firstname: state.partnerToUpdate.firstname || '',
          lastname: state.partnerToUpdate.lastname || '',
          mi: state.partnerToUpdate.mi || '',
          mobile: state.partnerToUpdate.mobile || '',
          email: state.partnerToUpdate.email || '',
          address: state.partnerToUpdate.address || '',
          province: state.partnerToUpdate.province || '',
          city: state.partnerToUpdate.city || '',
          zip_code: state.partnerToUpdate.zip_code || '',
          e_wallet_number: state.partnerToUpdate.e_wallet_number || '',
          attachment: state.partnerToUpdate.attachment || '',
        }
      } else {
        state.updatePartnerRequest = updatePartnerRequestIntialValue
      }

      state.updatePartnerFormValidationError = null
    },

    setUpdatePartnerRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.updatePartnerRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'id') {
        state.updatePartnerRequest.id = action.payload.fieldValue
      } else if (action.payload.fieldName === 'status') {
        state.updatePartnerRequest.status = action.payload.fieldValue
      } else if (action.payload.fieldName === 'firstname') {
        state.updatePartnerRequest.firstname = action.payload.fieldValue
      } else if (action.payload.fieldName === 'lastname') {
        state.updatePartnerRequest.lastname = action.payload.fieldValue
      } else if (action.payload.fieldName === 'mi') {
        state.updatePartnerRequest.mi = action.payload.fieldValue
      } else if (action.payload.fieldName === 'mobile') {
        state.updatePartnerRequest.mobile = action.payload.fieldValue
      } else if (action.payload.fieldName === 'email') {
        state.updatePartnerRequest.email = action.payload.fieldValue
      } else if (action.payload.fieldName === 'address') {
        state.updatePartnerRequest.address = action.payload.fieldValue
      } else if (action.payload.fieldName === 'province') {
        state.updatePartnerRequest.province = action.payload.fieldValue
      } else if (action.payload.fieldName === 'city') {
        state.updatePartnerRequest.city = action.payload.fieldValue
      } else if (action.payload.fieldName === 'zip_code') {
        state.updatePartnerRequest.zip_code = action.payload.fieldValue
      } else if (action.payload.fieldName === 'e_wallet_number') {
        state.updatePartnerRequest.e_wallet_number = action.payload.fieldValue
      } else if (action.payload.fieldName === 'attachment') {
        state.updatePartnerRequest.attachment = action.payload.fieldValue
      }
      
      if (state.updatePartnerFormValidationError && state.updatePartnerFormValidationError.validationErrors) {
        state.updatePartnerFormValidationError.validationErrors = state.updatePartnerFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.updatePartnerFormValidationError.validationErrors.length === 0) {
          state.updatePartnerFormValidationError = null
        }
      }
    },

    cancelUpdatePartnerModal: (state) => {
      state.partnerToUpdate = null
      state.updatePartnerRequest = updatePartnerRequestIntialValue
      state.updatingPartner = false
      state.updatePartnerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePartner.pending, (state) => {
        state.updatingPartner = true
        state.updatePartnerFormValidationError = null
      })
      .addCase(updatePartner.fulfilled, (state) => {
        state.partnerToUpdate = null
        state.updatingPartner = false
        state.updatePartnerRequest = updatePartnerRequestIntialValue
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.updatePartnerFormValidationError = action.payload as FormValidationError
        state.updatingPartner = false
      });
  },
})

export const { setPartnerToView, setPartnerToUpdate, setUpdatePartnerRequestFieldValue, cancelUpdatePartnerModal } = updatePartnerSlice.actions

export const selectPartnerToView = (state: RootState) => state.updatePartnerSlice.partnerToView;
export const selectPartnerToUpdate = (state: RootState) => state.updatePartnerSlice.partnerToUpdate;
export const selectUpdatePartnerRequest = (state: RootState) => state.updatePartnerSlice.updatePartnerRequest;
export const selectUpdatingPartner = (state: RootState) => state.updatePartnerSlice.updatingPartner;
export const selectUpdatePartnerFormValidationError = (state: RootState) => state.updatePartnerSlice.updatePartnerFormValidationError;

export default updatePartnerSlice.reducer
