import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import LockerForm from "pages/lockers/LockerForm";
import { Children, cloneElement, Fragment, isValidElement, ReactNode, useEffect, useRef, useState } from "react";
import {
  selectLockerToView, selectUpdateLockerFormValidationError,
  selectUpdateLockerRequest,
  selectUpdatingLocker, setLockerToView, setUpdateLockerRequestFieldValue, updateLocker
} from "store/lockers/updateLockerSlice";
import DrawersTable from "./DrawersTable";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import { selectDeletingDrawer } from "store/lockers/deleteDrawerSlice";
import EditDrawerModal from "./EditDrawerModal";

function LockerMap({
  children,
}: {
  children?: ReactNode;
}) {
  const mapRef = useRef(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    console.log('mapRef', mapRef)
    console.log('map', map)
    if (mapRef.current && !map) {
      setMap(new google.maps.Map(mapRef.current, {}));
    }
  }, [mapRef, map]);

  useEffect(() => {
    if (map) {
      map.setOptions({
        center: {
          lat: 14.634176388196067,
          lng: 121.03848931265658,
        },
        zoom: 18,
      });
    }
  }, [map])
  
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (map && marker) {
      marker.setOptions({
        map,
        position: {
          lat: 14.633997921503237,
          lng: 121.03850186526955,
        }
      });
    }
  }, [map, marker]);

  return <>
    <div ref={mapRef} style={{ height: '400px' }}></div>
    {Children.map(children, (child) => {
      if (isValidElement(child)) {
        // set the map prop on the child component
        // @ts-ignore
        return cloneElement(child, { map });
      }
    })}
  </>
}

export default function ViewLockerModal() {
  const dispatch = useAppDispatch();

  const lockerToView = useAppSelector(selectLockerToView);
  const updateLockerRequest = useAppSelector(selectUpdateLockerRequest);
  const updateLockerFormValidationError = useAppSelector(selectUpdateLockerFormValidationError);
  const updatingLocker = useAppSelector(selectUpdatingLocker);

  const initialFocusRef = useRef(null);

  const [selectedPartner, setSelectedPartner] = useState<null | { id: number, email: string, firstname: string, lastname: string }>(null)

  useEffect(() => {
    dispatch(setUpdateLockerRequestFieldValue({ fieldName: 'partner_id', fieldValue: selectedPartner ? selectedPartner.id : '' }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner])

  useEffect(() => {
    setSelectedPartner(lockerToView && lockerToView.partner ? {
      id: lockerToView.partner.id,
      email: lockerToView.partner.email || '',
      firstname: lockerToView.partner.firstname || '',
      lastname: lockerToView.partner.lastname || '',
    } : null)
  }, [lockerToView])

  const formSubmitHandler = (e: Event) => {
    e.preventDefault();
    dispatch(updateLocker(updateLockerRequest))
  };

  function setFieldValue(fieldName: string, fieldValue: string | null | { id: number, email: string, firstname: string, lastname: string }) {
    if (fieldName === 'selectedPartner') {
      setSelectedPartner(fieldValue as null | { id: number, email: string, firstname: string, lastname: string })
    } else {
      dispatch(setUpdateLockerRequestFieldValue({ fieldName, fieldValue }))
    }
  }

  return (
    <Transition.Root show={lockerToView ? true : false} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => updatingLocker ? null : dispatch(setLockerToView(null))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !updatingLocker
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setLockerToView(null))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }

                  <div className="mt-3 sm:mt-5">
                    {/* <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      View Locker { lockerToView?.id }
                    </Dialog.Title> */}

                    <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl bg-cyan-900/20 p-1">
                        {
                          [`Locker's Information`, `Locker's Drawers`, /* `Locker's Location` */].map((title) => {
                            return <Tab
                              key={title}
                              className={({ selected }) =>
                                [
                                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-cyan-400 focus:outline-none focus:ring-2',
                                  selected
                                    ? 'bg-cyan-700 shadow'
                                    : 'text-cyan-700 bg-white hover:bg-cyan-700/[0.25] hover:text-white'
                                ].join(' ')
                              }
                            >{ title }</Tab>
                          })
                        }
                      </Tab.List>

                      <Tab.Panels>
                        <Tab.Panel>
                          <LockerForm
                            initialFocusRef={initialFocusRef}
                            formSubmitHandler={formSubmitHandler}
                            cancelHandler={() => dispatch(setLockerToView(null))}
                            request={{
                              ...updateLockerRequest,
                              selectedPartner,
                            }}
                            setFieldValue={setFieldValue}
                            submittingForm={updatingLocker}
                            formValidationError={updateLockerFormValidationError}
                            formType={'view'}
                          />
                        </Tab.Panel>

                        <Tab.Panel>
                        <main className="flex-1 overflow-y-auto">
                          {/* Primary column */}
                          <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
                          >
                            <DrawersTable
                              request={{
                                lockerToView,
                              }}
                          />
                          </section>
                        </main>
                        </Tab.Panel>

                        {/* for Google maps */}
                        {/* <Tab.Panel>
                          <Wrapper
                            apiKey={window.env?.GOOGLE_MAP_API_KEY || ''}
                            render={(status: Status) => {
                              return <h1>{status}</h1>;
                            }}
                          >
                            <LockerMap />
                          </Wrapper>
                        </Tab.Panel> */}


                      </Tab.Panels>
                    </Tab.Group>

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
