import "./Landing.css";
import HingedLogo from "assets/images/hingedlogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/store-hooks";
import { selectGettingLoggedInUser, selectLoggedInUserProfile } from "store/auth/authSlice";
import { useEffect } from "react";
import Loading from "components/Loading";

export default function Landing() {
  const navigate = useNavigate();

  const loggedInUserProfile = useAppSelector(selectLoggedInUserProfile)
  const gettingLoggedInUser = useAppSelector(selectGettingLoggedInUser)

  useEffect(() => {
    if (loggedInUserProfile) {
      navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserProfile])

  return (
    <div className="flex items-center justify-center landing-container">
      <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm w-80 flex items-center justify-center flex-col">
        <img src={HingedLogo} alt="Hinged" className="mb-5" />
        {
          gettingLoggedInUser
            ?
              <Loading />
            :
              <Link
                to="/login"
                className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >Sign in</Link>
        }

      </div>
    </div>
  );
}
