import { Transaction } from 'models';
import { createContext } from 'react';

interface TransactionsState {
  showFilterModal: boolean;
  setShowFilterModal: (showFilterModal: boolean) => void;
  transactionToView: Transaction | null;
  setTransactionToView: (transactionToView: Transaction | null) => void;
  
}

const globalInitialState: TransactionsState = {
  showFilterModal: false,
  setShowFilterModal: () => {},
  transactionToView: null,
  setTransactionToView: () => {},
}

const TransactionsContext = createContext(globalInitialState)

export default TransactionsContext
