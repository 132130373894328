import { createContext } from 'react';

interface PartnersState {
  showFilterModal: boolean;
  setShowFilterModal: (showFilterModal: boolean) => void;
}

const globalInitialState: PartnersState = {
  showFilterModal: false,
  setShowFilterModal: () => {},
}

const PartnersContext = createContext(globalInitialState)

export default PartnersContext
