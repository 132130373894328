import axios from "axios";
import { Profile } from "models";

export interface LoginRequest {
  device_name: string;
  email: string;
  password: string;
}

export interface LoginResponse {
  status: number;
  access_token: string;
}

export interface GetAuthProfileResponse {
  status: number;
  data: Profile;
}

export interface UpdateProfileRequest {
  firstname: string;
  lastname: string;
  mi: string;
  address: string;
  // barangay: string;
  // city: string;
  // province: string;
  // region: string;
  zip_code: string;
  e_wallet_number: number | string;
  notification_type: string | number;
  image: string;
  phone: number | string;
  email: string;
}

export interface UpdateProfileResponse {
  status: number;
}

export interface UpdateFreePointsRequest {
  freepoints: number;
}


export interface FreePointsResponse {
  data: number;
  status: number;
}
export interface UpdateFreePointsResponse {
  status: number;
}

async function login(loginRequest: LoginRequest): Promise<LoginResponse> {
  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.post('/api/auth/adminlogin', loginRequest);
  const loginResponse = response.data
  return loginResponse
}

async function getAuthProfile(accessToken: string): Promise<GetAuthProfileResponse> {
  const token = accessToken ? `Bearer ${accessToken}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))
  const response = await axios.get('/api/auth/profile', config);
  const getAuthProfileResponse = response.data
  return getAuthProfileResponse
}

function getConfig () {
  const accessToken = localStorage.getItem("login")
  const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config
}

async function updateAuthProfile(updateProfileRequest: UpdateProfileRequest): Promise<UpdateProfileResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.put('/api/auth/profile', updateProfileRequest, config);
  const updateProfileResponse = response.data
  return updateProfileResponse
}

async function getFreePoints(accessToken: string): Promise<FreePointsResponse> {
  const token = accessToken ? `Bearer ${accessToken}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))
  const response = await axios.get('/api/auth/freepoints', config);
  const getFreePointsResponse = response.data
  return getFreePointsResponse
  }
  
async function updateFreePoints(freePointsRequest: UpdateFreePointsRequest): Promise<UpdateFreePointsResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.put('/api/auth/freepoints/update', freePointsRequest, config);
  const updateFreePointsResponse = response.data
  return updateFreePointsResponse
}

export const authService = {
  login,
  getAuthProfile,
  updateAuthProfile,
  getFreePoints,
  updateFreePoints,
}
