import axios from "axios";
import { Locker, PaginationResult } from "models";

export interface GetLockersRequest {
  partner_email?: string;
  email?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  page?: string
}

export interface GetLockersResponse {
  status: number;
  lockers: PaginationResult<Locker>;
}

export interface CreateLockerRequest {
  partner_id: string | number;
  address: string | null;
  city: string | null;
  region: string | null;
  barangay: string | null;
  province: string | null;
  zip_code: string | null;
  status: number | null;
}

export interface CreateLockerResponse {
  status: number;
  data: Locker;
}

export interface UpdateLockerRequest {
  id: string | number;
  partner_id: string | number;
  address: string | null;
  city: string | null;
  region: string | null;
  barangay: string | null;
  province: string | null;
  zip_code: string | null;
  status: number | null;
}

export interface UpdateLockerResponse {
  status: number;
  data: Locker;
}

export interface DeleteLockerResponse {
  status: number;
}

function getConfig () {
  const accessToken = localStorage.getItem("login")
  const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config
}

async function getLockers(getLockersRequest: GetLockersRequest): Promise<GetLockersResponse> {
  const config = {
    ...getConfig(),
    params: getLockersRequest,
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.get('/api/lockers', config);
  const getLockersResponse = response.data
  return getLockersResponse
}

async function createLocker(createLockerRequest: CreateLockerRequest): Promise<CreateLockerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.post('/api/lockers', createLockerRequest, config);
  const createLockerResponse = response.data
  return createLockerResponse
}

async function updateLocker(updateLockerRequest: UpdateLockerRequest): Promise<UpdateLockerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.put(`/api/lockers/${updateLockerRequest.id}`, updateLockerRequest, config);
  const updateLockerResponse = response.data
  return updateLockerResponse
}

async function deleteLocker(lockerId: string): Promise<DeleteLockerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.delete(`/api/lockers/${lockerId}`, config);
  const deleteLockerResponse = response.data
  return deleteLockerResponse
}

export const lockerService = {
  getLockers,
  createLocker,
  updateLocker,
  deleteLocker
}
