import axios from 'axios';
import AppFieldError from "components/AppFieldError";
import AppInputSelect from "components/AppInputSelect";
import AppInputSelectSearch from "components/AppInputSelectSearch";
import LoadingIcon from "components/LoadingIcon";
import GlobalContext from 'contexts/GlobalContext';
import { FormValidationError } from "errors/FormValidationError";
import { usePsgcOptions } from "hooks/usePsgcOptions";
import { LockerStatus, Partner } from "models";
import { useContext, useEffect, useState } from 'react';

export interface LockerFormProps {
  initialFocusRef: any;
  formSubmitHandler: (e: any) => void;
  cancelHandler: () => void;
  request: Record<string, any>;
  setFieldValue: (fieldName: string, fieldValue: any) => void;
  submittingForm: boolean
  formValidationError: FormValidationError | null;
  formType: 'create' | 'update' | 'view';
}

export default function LockerForm({
  initialFocusRef,
  formSubmitHandler,
  cancelHandler,
  request,
  setFieldValue,
  submittingForm,
  formValidationError,
  formType,
}: LockerFormProps) {
  const { catchErr } = useContext(GlobalContext)

  const regionValue = request.region
  const provinceValue = request.province
  const cityValue = request.city

  const {
    regionsOptions,
    provincesOptions,
    cityOrMunicipalitiesOptions,
    barangaysOptions,
  } = usePsgcOptions({
    regionValue,
    provinceValue,
    cityValue,
  })
  
  const [searchPartners, setSearchPartners] = useState('')
  const [partners, setPartners] = useState([])
  const [gettingPartners, setGettingPartners] = useState(false)

  async function getPartners () {
    const accessToken = localStorage.getItem("login")

    if (!accessToken) {
      return
    }
    
    const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

    setGettingPartners(true)

    axios.get(`${API_BASE_URL}/api/partners`, {
      headers: {
        Authorization: token
      },
      params: {
        per_page: 1000,
        page: 1,
      },
    }).then((response) => {
      console.log('get partners response', response)
      const partners = response.data.partners.data
      setPartners(partners)
    }).catch((err) => {
      console.log('get contacts err', err)
      setPartners([])
      catchErr(err)
    }).finally(() => {
      setGettingPartners(false)
    })
  }

  useEffect(() => {
    getPartners()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mt-2">
      <form
        className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y"
        onSubmit={formSubmitHandler}
      >
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          {/* <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-cyan-gray-900">
              Locker Information
            </h2>
          </div> */}

          <div className="sm:col-span-3">
            <label
              htmlFor="partner_id"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Partner
            </label>

            {/* <AppInput
              ref={initialFocusRef}
              name="partner_id"
              id="partner_id"
              autoComplete="partner_id"
              value={request.partner_id}
              onChange={(value) => setFieldValue('partner_id', value)}
            /> */}

            <AppInputSelectSearch
              options={partners.map((partner: Partner) => ({
                label: `${partner.email} - ${partner.firstname} ${partner.lastname}`,
                value: {
                  id: partner.id,
                  email: partner.email,
                  firstname: partner.firstname,
                  lastname: partner.lastname,
                },
              }))}
              value={request.selectedPartner}
              onChange={(e) => setFieldValue('selectedPartner', e)}
              // search={searchPartners}
              // onSearch={(e) => setSearchPartners(e)}
              // loading={gettingPartners}
              getSelectedOption={() => {
                return request.selectedPartner ? {
                  label: `${request.selectedPartner.email} - ${request.selectedPartner.firstname} ${request.selectedPartner.lastname}`,
                  value: {
                    id: request.selectedPartner.id,
                    email: request.selectedPartner.email,
                    firstname: request.selectedPartner.firstname,
                    lastname: request.selectedPartner.lastname,
                  },
                } : null
              }}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'partner_id'} formValidationError={formValidationError} />
          </div>

          {
            formType === 'view' ? <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Code
                </label>

                <input
                  type="text"
                  name="code"
                  id="code"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={request.code}
                  onChange={(e) => setFieldValue('code', e.target.value)}
                  disabled={formType === 'view'}
                />

                <AppFieldError fieldName={'code'} formValidationError={formValidationError} />
              </div>
            </> : <></>
          }

          {
            formType === 'update' || formType === 'view' ? <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Status
                </label>

                <AppInputSelect
                  options={[
                    {
                      label: 'INACTIVE',
                      value: LockerStatus.Inactive
                    },
                    {
                      label: 'ACTIVE',
                      value: LockerStatus.Active,
                    }
                  ]}
                  value={request.status}
                  onChange={(e) => setFieldValue('status', e)}
                  disabled={formType === 'view'}
                />

                <AppFieldError fieldName={'status'} formValidationError={formValidationError} />
              </div>
            </> : <></>
          }

          <div className="sm:col-span-3">
            <label
              htmlFor="address"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Address
            </label>

            <input
              type="text"
              name="address"
              id="address"
              autoComplete="address"
              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              value={request.address}
              onChange={(e) => setFieldValue('address', e.target.value)}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'address'} formValidationError={formValidationError} />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="zip_code"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Zip code
            </label>

            <input
              type="text"
              name="zip_code"
              id="zip_code"
              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              value={request.zip_code}
              onChange={(e) => setFieldValue('zip_code', e.target.value)}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'zip_code'} formValidationError={formValidationError} />
          </div>
        </div>

        <div>
          <div className="flex justify-end pt-8">
            <button
              type="button"
              disabled={submittingForm}
              className={`${submittingForm ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
              onClick={() => cancelHandler()}
            >
              Cancel
            </button>

            {
              formType !== 'view' ? <>
                <button
                  type="submit"
                  disabled={submittingForm}
                  className={`${submittingForm ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                >
                  { submittingForm ? <><LoadingIcon /><span>Loading...</span></> : 'Submit' }
                </button>
              </> : <></>
            }
          </div>

          {
            formValidationError
              ?
                <div className="flex justify-end">
                  <small className="text-red-500">{ formValidationError.message }</small>
                </div>
              :
                <></>
          }
        </div>
      </form>
    </div>
  )
}
