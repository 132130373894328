import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { CreateDrawerRequest, drawerService } from "services/drawerService";
import { RootState } from 'store/store';

interface CreateDrawerState {
  createDrawerModalOpen: boolean;
  createDrawerRequest: CreateDrawerRequest;
  creatingDrawer: boolean;
  createDrawerFormValidationError: FormValidationError | null;
}

const createDrawerRequestIntialValue = {
  locker_id: '',
  cu_number: '',
  position: '',
  size: 0,
  price: '',
  status: 0
}

const createDrawerInitialState: CreateDrawerState = {
  createDrawerModalOpen: false,
  createDrawerRequest: createDrawerRequestIntialValue,
  creatingDrawer: false,
  createDrawerFormValidationError: null,
}

export const createDrawer = createAsyncThunk('createDrawerModal/createDrawer', async (createDrawerRequest: CreateDrawerRequest, thunkAPI) => {
  try {
    const request = {
      ...createDrawerRequest
    }
    // delete request.attachment
    const createDrawerResponse = await drawerService.createDrawer(request);
    return createDrawerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const createDrawerSlice = createSlice({
  name: 'createDrawerSlice',
  initialState: createDrawerInitialState,
  reducers: {
    setCreateDrawerModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createDrawerModalOpen = action.payload
    },

    setCreateDrawerRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.createDrawerRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'locker_id') {
        state.createDrawerRequest.locker_id = action.payload.fieldValue
      } else if (action.payload.fieldName === 'cu_number') {
        state.createDrawerRequest.cu_number = action.payload.fieldValue
      } else if (action.payload.fieldName === 'position') {
        state.createDrawerRequest.position = action.payload.fieldValue
      } else if (action.payload.fieldName === 'size') {
        state.createDrawerRequest.size = action.payload.fieldValue
      } else if (action.payload.fieldName === 'price') {
        state.createDrawerRequest.price = action.payload.fieldValue
      } else if (action.payload.fieldName === 'status') {
        state.createDrawerRequest.status = action.payload.fieldValue
      }
      
      if (state.createDrawerFormValidationError && state.createDrawerFormValidationError.validationErrors) {
        state.createDrawerFormValidationError.validationErrors = state.createDrawerFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.createDrawerFormValidationError.validationErrors.length === 0) {
          state.createDrawerFormValidationError = null
        }
      }
    },

    cancelCreateDrawerModal: (state) => {
      state.createDrawerModalOpen = false
      state.createDrawerRequest = createDrawerRequestIntialValue
      state.creatingDrawer = false
      state.createDrawerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDrawer.pending, (state) => {
        state.creatingDrawer = true
        state.createDrawerFormValidationError = null
      })
      .addCase(createDrawer.fulfilled, (state) => {
        state.createDrawerModalOpen = false
        state.creatingDrawer = false
        state.createDrawerRequest = createDrawerRequestIntialValue
      })
      .addCase(createDrawer.rejected, (state, action) => {
        state.createDrawerFormValidationError = action.payload as FormValidationError
        state.creatingDrawer = false
      });
  },
})

export const { setCreateDrawerModalOpen, setCreateDrawerRequestFieldValue, cancelCreateDrawerModal } = createDrawerSlice.actions

export const selectCreateDrawerModalOpen = (state: RootState) => state.createDrawerSlice.createDrawerModalOpen;
export const selectCreateDrawerRequest = (state: RootState) => state.createDrawerSlice.createDrawerRequest;
export const selectCreatingDrawer = (state: RootState) => state.createDrawerSlice.creatingDrawer;
export const selectCreateDrawerFormValidationError = (state: RootState) => state.createDrawerSlice.createDrawerFormValidationError;

export default createDrawerSlice.reducer
