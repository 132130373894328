import { createContext } from 'react';

interface GlobalState {
  catchErr: (err: Error | any) => void;
}

const globalInitialState: GlobalState = {
  catchErr: () => {},
}

const GlobalContext = createContext(globalInitialState)

export default GlobalContext
