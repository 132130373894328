import Loading from "components/Loading";
import AppPagination, { getPageSearch } from "components/partials/AppPagination";
import PartnersContext from "contexts/PartnersContext";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { Partner, PartnerStatusEnum } from "models";
import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setCreatePartnerModalOpen } from "store/partners/createPartnerSlice";
import { setPartnerToDelete } from "store/partners/deletePartnerSlice";
import { selectGettingPartnersLoading, selectPartners, selectPartnersCount, selectPartnersCurrentPage, selectPartnersNumberOfPages, selectPartnersPerPage } from "store/partners/partnerListSlice";
import { setPartnerToUpdate, setPartnerToView } from "store/partners/updatePartnerSlice";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import { partnerService } from "services/partnerService";
import { FormValidationError, ValidationError } from "errors/FormValidationError";
import { useFetchPartners } from "hooks/useFetchPartners";

export default function PartnersTable() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const fetchPartners = useFetchPartners()

  const [partnerIdsToDelete, setPartnerIdsToDelete] = useState<number[]>([])
  const [showDeleteManyModal, setShowDeleteManyModal] = useState<boolean>(false)
  const [deletingMany, setDeletingMany] = useState<boolean>(false)
  const [deleteManyValidationError, setDeleteManyValidationError] = useState<FormValidationError | null>(null)

  const { setShowFilterModal } = useContext(PartnersContext)

  const emailFromParams = searchParams.get('email')
  const firstnameFromParams = searchParams.get('firstname')
  const lastnameFromParams = searchParams.get('lastname')
  const statusFromParams = searchParams.get('status')

  const hasFilter = emailFromParams || firstnameFromParams || lastnameFromParams || statusFromParams ? true : false

  const partners = useAppSelector(selectPartners);
  const loading = useAppSelector(selectGettingPartnersLoading);
  const totalPartners = useAppSelector(selectPartnersCount);
  const partnersPerPage = useAppSelector(selectPartnersPerPage);
  const totalPages = useAppSelector(selectPartnersNumberOfPages);
  const currentPage = useAppSelector(selectPartnersCurrentPage);

  function pageHandler (page: number) {
    const search = getPageSearch(page)
    navigate({ search })
  }

  async function deleteMany() {
    try {
      console.log('deleteMany request', { partner_ids: partnerIdsToDelete })
      setDeleteManyValidationError(null)
      setDeletingMany(true)
      const results = await partnerService.deleteManyPartners({ partner_ids: partnerIdsToDelete })
      console.log('deleteMany results', results.data)
      setDeletingMany(false)
      setPartnerIdsToDelete([])
      setShowDeleteManyModal(false)
      await fetchPartners()
    } catch (err: any) {
      console.log('err', err)
      setDeletingMany(false)

      let message = ''
  
      let validationErrors: ValidationError[] = []
  
      if (err.response) {
        if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
          message = err.response.data.message
  
          validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
            if (err.response.data.errors[fieldName].length > 0) {
              err.response.data.errors[fieldName].forEach((errorMessage: string) => {
                validationErrors.push({
                  field: fieldName,
                  message: errorMessage
                })
              })
            }
  
            return validationErrors
          }, [] as ValidationError[])
  
          if (validationErrors.length > 0) {
            message = 'Validation Error!' + '\n' + validationErrors.map((validationError) => validationError.message).join('\n')
          }
        } else {
          message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
        }

        setDeleteManyValidationError({ message, validationErrors })
      } else if (err.request) {
        throw new Error('Something went wrong!')
      }
    }
  }

  return (
    <>
      <DeleteConfirmationModal
        title={'Delete partners'}
        subtitle={
          <>
            Are you sure you want to delete this partners ({partnerIdsToDelete.length})?
            All of their data will be permanently removed from our servers forever.
            This action cannot be undone.
          </>
        }
        openModal={showDeleteManyModal}
        loading={deletingMany}
        closeHandler={() => setShowDeleteManyModal(false)}
        cancelHandler={() => setShowDeleteManyModal(false)}
        confirmHandler={() => deleteMany()}
        formValidationError={deleteManyValidationError}
      />

      <div className="p-10 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Partners</h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name, title,
              email and role.
            </p> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {
              partnerIdsToDelete.length > 0 ? <>
                <button
                  type="button"
                  className="
                    inline-flex items-center justify-center rounded-md border border-transparent
                    bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700
                    focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto
                  "
                  onClick={() => setShowDeleteManyModal(true)}
                >
                  Delete { partnerIdsToDelete.length > 0 ? <>({ partnerIdsToDelete.length })</> : <></>}
                </button>
              </> : <>
                <button
                  type="button"
                  className="
                    inline-flex items-center justify-center rounded-md border border-transparent
                    bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700
                    focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto
                    cursor-not-allowed
                  "
                  disabled
                >
                  Delete
                </button>
              </>
            }

            <button
              type="button"
              className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </button>

            <button
              type="button"
              className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => dispatch(setCreatePartnerModalOpen(true))}
            >
              Add partner
            </button>
          </div>
        </div>

        {/* <PartnersFilters /> */}

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <input 
                          type="checkbox"
                          checked={partners.every(partner => partnerIdsToDelete.includes(partner.id))}
                          onChange={() => {
                            setPartnerIdsToDelete(
                              partners.every(partner => partnerIdsToDelete.includes(partner.id))
                                ? partnerIdsToDelete.filter(id => partners.every(partner => partner.id !== id))
                                : [
                                    ...partnerIdsToDelete.filter(id => partners.every(partner => partner.id !== id)),
                                    ...partners.map(partner => partner.id)
                                ]
                            )
                          }}
                        />
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                      </th>

                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>

                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Id
                      </th> */}

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created At
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {partners.map((partner: Partner, i: number) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              checked={partnerIdsToDelete.includes(partner.id)}
                              onChange={() => {
                                setPartnerIdsToDelete(
                                  partnerIdsToDelete.includes(partner.id)
                                    ? partnerIdsToDelete.filter(id => id !== partner.id)
                                    : [...partnerIdsToDelete, partner.id]
                                )
                              }}
                            />
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <PencilSquareIcon className="h-6 w-6 cursor-pointer" onClick={() => dispatch(setPartnerToUpdate(partner))} />
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            {partner.attachment ? 
                              <div className="h-10 w-10 flex-shrink-0 mr-4">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={partner.attachment}
                                  alt=""
                                />
                              </div>
                            : <></>}
                            <div>
                              <div className="font-medium text-gray-900">
                                {partner.firstname}
                              </div>
                              <div className="text-gray-500">
                                {partner.lastname}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">{partner.email}</div>
                          <div className="text-gray-500">
                            {/* {partner.department} */}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className={ `inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${partner.status !== 0 ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}` } >
                            { PartnerStatusEnum[partner.status] }
                          </span>
                        </td>

                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {partner.id}
                        </td> */}

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {partner.created_at}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                          <button className="text-green-600 hover:text-green-900" onClick={() => dispatch(setPartnerToView(partner))}>
                            View
                            <span className="sr-only">partner Id#: {partner.id}</span>
                          </button>

                          {/* <button className="ml-2 text-cyan-600 hover:text-cyan-900" onClick={() => dispatch(setPartnerToUpdate(partner))}>
                            Edit
                            <span className="sr-only">partner Id#: {partner.id}</span>
                          </button>

                          <button className="ml-2 text-red-600 hover:text-red-900" onClick={() => dispatch(setPartnerToDelete(partner))}>
                            Delete
                            <span className="sr-only">partner Id#: {partner.id}</span>
                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {
                  !loading && partners.length === 0 ? <>
                    <div className="flex justify-center p-4">
                      <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                    </div>
                  </>
                  : <></>
                }

                <AppPagination
                  page={currentPage}
                  pageHandler={pageHandler}
                  loading={loading}
                  resources={partners}
                  totalPages={totalPages}
                  totalResources={totalPartners}
                  resourcesPerPage={partnersPerPage}
                />
                
                {
                  loading
                  ?
                    <>
                      <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                        <Loading />
                      </div>
                    </>
                  :
                    <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
