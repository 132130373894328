import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Drawer } from 'models';
import { drawerService } from "services/drawerService";
import { RootState } from 'store/store';

interface DeleteDrawerState {
  drawerToDelete: Drawer | null;
  deletingDrawer: boolean;
  deleteDrawerFormValidationError: FormValidationError | null;
}

const deleteDrawerModalInitialState: DeleteDrawerState = {
  drawerToDelete: null,
  deletingDrawer: false,
  deleteDrawerFormValidationError: null,
}

export const deleteDrawer = createAsyncThunk('deleteDrawerModal/deleteDrawer', async (drawer: Drawer, thunkAPI) => {
  try {
    const deleteDrawerResponse = await drawerService.deleteDrawer(drawer.id.toString());
    return deleteDrawerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const deleteDrawerSlice = createSlice({
  name: 'deleteDrawerSlice',
  initialState: deleteDrawerModalInitialState,
  reducers: {
    setDrawerToDelete: (state, action: PayloadAction<Drawer | null>) => {
      state.drawerToDelete = action.payload
      state.deleteDrawerFormValidationError = null
    },
    cancelDeleteDrawerModal: (state) => {
      state.drawerToDelete = null
      state.deleteDrawerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDrawer.pending, (state) => {
        state.deletingDrawer = true
        state.deleteDrawerFormValidationError = null
      })
      .addCase(deleteDrawer.fulfilled, (state) => {
        state.drawerToDelete = null
        state.deletingDrawer = false
      })
      .addCase(deleteDrawer.rejected, (state, action) => {
        state.deleteDrawerFormValidationError = action.payload as FormValidationError
        state.deletingDrawer = false
      });
  },
})

export const { setDrawerToDelete, cancelDeleteDrawerModal } = deleteDrawerSlice.actions

export const selectDrawerToDelete = (state: RootState) => state.deleteDrawerSlice.drawerToDelete;
export const selectDeletingDrawer = (state: RootState) => state.deleteDrawerSlice.deletingDrawer;
export const selectDeleteDrawerFormValidationError = (state: RootState) => state.deleteDrawerSlice.deleteDrawerFormValidationError;

export default deleteDrawerSlice.reducer
