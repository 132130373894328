export enum ContactStatus {
  PENDING = 'PENDING',
  ONGOING = 'ONGOING',
  RESOLVED = 'RESOLVED',
}

export interface Contact {
  id: number;
  name: string | null;
  email: string | null;
  message: string | null;
  status: ContactStatus;
  created_at: string;
}
