import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { CreateUserRequest, userService } from "services/userService";
import { RootState } from 'store/store';

interface CreateUserState {
  createUserModalOpen: boolean;
  createUserRequest: CreateUserRequest;
  creatingUser: boolean;
  createUserFormValidationError: FormValidationError | null;
}

const createUserRequestIntialValue = {
  email: '',
  password: '',
}

const createUserInitialState: CreateUserState = {
  createUserModalOpen: false,
  createUserRequest: createUserRequestIntialValue,
  creatingUser: false,
  createUserFormValidationError: null,
}

export const createUser = createAsyncThunk('createUserModal/createUser', async (createUserRequest: CreateUserRequest, thunkAPI) => {
  try {
    const request = {
      ...createUserRequest
    }
    // delete request.attachment
    const createUserResponse = await userService.createUser(request);
    return createUserResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const createUserSlice = createSlice({
  name: 'createUserSlice',
  initialState: createUserInitialState,
  reducers: {
    setCreateUserModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createUserModalOpen = action.payload
    },

    setCreateUserRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.createUserRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'email') {
        state.createUserRequest.email = action.payload.fieldValue
      } else if (action.payload.fieldName === 'password') {
        state.createUserRequest.password = action.payload.fieldValue
      }
      
      if (state.createUserFormValidationError && state.createUserFormValidationError.validationErrors) {
        state.createUserFormValidationError.validationErrors = state.createUserFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.createUserFormValidationError.validationErrors.length === 0) {
          state.createUserFormValidationError = null
        }
      }
    },

    cancelCreateUserModal: (state) => {
      state.createUserModalOpen = false
      state.createUserRequest = createUserRequestIntialValue
      state.creatingUser = false
      state.createUserFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.creatingUser = true
        state.createUserFormValidationError = null
      })
      .addCase(createUser.fulfilled, (state) => {
        state.createUserModalOpen = false
        state.creatingUser = false
        state.createUserRequest = createUserRequestIntialValue
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserFormValidationError = action.payload as FormValidationError
        state.creatingUser = false
      });
  },
})

export const { setCreateUserModalOpen, setCreateUserRequestFieldValue, cancelCreateUserModal } = createUserSlice.actions

export const selectCreateUserModalOpen = (state: RootState) => state.createUserSlice.createUserModalOpen;
export const selectCreateUserRequest = (state: RootState) => state.createUserSlice.createUserRequest;
export const selectCreatingUser = (state: RootState) => state.createUserSlice.creatingUser;
export const selectCreateUserFormValidationError = (state: RootState) => state.createUserSlice.createUserFormValidationError;

export default createUserSlice.reducer
