import LoadingIcon from "./LoadingIcon";

export default function Loading() {
  return (
    <div className="text-center h-full flex align-center justify-center">
      <div className="self-center">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-cyan-600 hover:bg-cyan-400 transition ease-in-out duration-150 cursor-not-allowed"
          disabled
        >
          <LoadingIcon />
          Loading...
        </button>
      </div>
    </div>
  );
}
