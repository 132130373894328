export interface Profile {
  qr_code: string | null;
  firstname: string | null;
  lastname: string | null;
  mi: string | null;
  phone: string | null;
  birthdate: string | null;
  image: string | null;
  address: string | null;
  barangay: string | null;
  city: string | null;
  province: string | null;
  region: string | null;
  zip_code: string | null;
  points: number;
  notification_type: string | null;
  ytd_usage: number;
  e_wallet_number: number | null;
  referral_code: string;
  user: User;
}

export interface User {
  id: number;
  email: string;
  created_at: string;
  profile: Profile;
  is_enabled: boolean;
}

export function getCompleteNameFromUserProfile(p: Profile) {
  return [p.firstname, p.mi, p.lastname].filter(v => v).join(' ')
}

export function getCompleteAddressFromUserProfile(p: Profile) {
  return [p.address, p.barangay, p.city, p.province, p.zip_code].filter(v => v).join(', ')
}
