import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { authService, UpdateFreePointsRequest } from 'services/authService';
import { RootState } from 'store/store';

interface FreePointsState {
  freePoints: number;
  gettingFreePoints: boolean;
  getFreePointsFormValidationError: FormValidationError | null;
  updateFreePointsRequest: UpdateFreePointsRequest;
  updatingFreePoints: boolean;
  updateFreePointsFormValidationError: FormValidationError | null;
}

const initialFreePointsRequest: UpdateFreePointsRequest = {
  freepoints: 0,
}

const freePointsInitialState: FreePointsState = {
  freePoints: 0,
  gettingFreePoints: false,
  getFreePointsFormValidationError: null,
  updateFreePointsRequest: initialFreePointsRequest,
  updatingFreePoints: false,
  updateFreePointsFormValidationError: null,
}

export const getFreePoints = createAsyncThunk('freePointsSlice/getFreePoints', async (accessToken: string, thunkAPI) => {
  try {
    const getFreePointsResponse = await authService.getFreePoints(accessToken);
    return getFreePointsResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const updateFreePoints = createAsyncThunk('freePointsSlice/updateFreePoints', async (updateFreePointsRequest: UpdateFreePointsRequest, thunkAPI) => {
  try {
    const updateFreePointsResponse = await authService.updateFreePoints(updateFreePointsRequest);
    return updateFreePointsResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const freePointsSlice = createSlice({
  name: 'freePointsSlice',
  initialState: freePointsInitialState,
  reducers: {
    setFreePointsToUpdate: (state, action: PayloadAction<{ freePointsToUpdate: number }>) => {
      state.freePoints = action.payload.freePointsToUpdate

      if (state.freePoints) {
        state.updateFreePointsRequest.freepoints = state.freePoints
      }
    },

    setUpdateFreePointsRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.updateProfileRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'freepoints') {
        state.updateFreePointsRequest.freepoints = action.payload.fieldValue
      }
      
      if (state.updateFreePointsFormValidationError && state.updateFreePointsFormValidationError.validationErrors) {
        state.updateFreePointsFormValidationError.validationErrors = state.updateFreePointsFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.updateFreePointsFormValidationError.validationErrors.length === 0) {
          state.updateFreePointsFormValidationError = null
        }
      }
    },

    cancelUpdateFreePoints: (state) => {
      state.updateFreePointsRequest = initialFreePointsRequest
      state.updatingFreePoints = false
      state.updateFreePointsFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFreePoints.pending, (state) => {
        state.gettingFreePoints = true
        state.getFreePointsFormValidationError = null
      })
      .addCase(getFreePoints.fulfilled, (state, action) => {
        state.gettingFreePoints = false
        state.freePoints = action.payload.data
      })
      .addCase(getFreePoints.rejected, (state, action) => {
        state.gettingFreePoints = false
        state.getFreePointsFormValidationError = action.payload as FormValidationError
      })
      .addCase(updateFreePoints.pending, (state) => {
        state.updatingFreePoints = true
        state.updateFreePointsFormValidationError = null
      })
      .addCase(updateFreePoints.fulfilled, (state) => {
        state.updatingFreePoints = false
      })
      .addCase(updateFreePoints.rejected, (state, action) => {
        state.updatingFreePoints = false
        state.updateFreePointsFormValidationError = action.payload as FormValidationError
      });
  },
})

export const { setFreePointsToUpdate, setUpdateFreePointsRequestFieldValue, cancelUpdateFreePoints } = freePointsSlice.actions

export const selectFreePoints = (state: RootState) => state.freePointsSlice.freePoints;
export const selectGettingFreePoints = (state: RootState) => state.freePointsSlice.gettingFreePoints;
export const selectGetFreePointsFormValidationError = (state: RootState) => state.freePointsSlice.getFreePointsFormValidationError;
export const selectUpdateFreePointsRequest = (state: RootState) => state.freePointsSlice.updateFreePointsRequest;
export const selectUpdatingFreePoints = (state: RootState) => state.freePointsSlice.updatingFreePoints;
export const selectUpdateFreePointsFormValidationError = (state: RootState) => state.freePointsSlice.updateFreePointsFormValidationError;

export default freePointsSlice.reducer
