import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { selectCreatePartnerModalOpen } from "store/partners/createPartnerSlice";
import PartnerForm from "pages/partners/PartnerForm";
import {
  cancelCreatePartnerModal,
  createPartner,
  selectCreatePartnerFormValidationError,
  selectCreatePartnerRequest,
  selectCreatingPartner,
  setCreatePartnerModalOpen,
  setCreatePartnerRequestFieldValue
} from "store/partners/createPartnerSlice";
import { useFetchPartners } from "hooks/useFetchPartners";

export default function CreatePartnerModal() {
  const dispatch = useAppDispatch();

  const createPartnerModalOpen = useAppSelector(selectCreatePartnerModalOpen);
  const createPartnerRequest = useAppSelector(selectCreatePartnerRequest);
  const createPartnerFormValidationError = useAppSelector(selectCreatePartnerFormValidationError);
  const creatingPartner = useAppSelector(selectCreatingPartner);
  const fetchPartners = useFetchPartners()

  const initialFocusRef = useRef(null);

  const formSubmitHandler = (e: Event) => {
    e.preventDefault();
    dispatch(createPartner(createPartnerRequest)).then(() => {
      fetchPartners()
    })
  };

  function setFieldValue(fieldName: string, fieldValue: any) {
    dispatch(setCreatePartnerRequestFieldValue({ fieldName, fieldValue }))
  }

  return (
    <Transition.Root show={createPartnerModalOpen} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => creatingPartner ? null : dispatch(setCreatePartnerModalOpen(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !creatingPartner
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setCreatePartnerModalOpen(false))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }
                  
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Create Partner
                    </Dialog.Title>

                    <PartnerForm
                      initialFocusRef={initialFocusRef}
                      formSubmitHandler={formSubmitHandler}
                      cancelHandler={() => dispatch(cancelCreatePartnerModal())}
                      request={createPartnerRequest}
                      setFieldValue={setFieldValue}
                      submittingForm={creatingPartner}
                      formValidationError={createPartnerFormValidationError}
                      formType="create"
                    />

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
