import axios from "axios";
import { PaginationResult, Partner, PartnerStatusEnum } from "models";

export interface GetPartnersRequest {
  email?: string;
  firstname?: string;
  lastname?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  page?: string
}

export interface GetPartnersResponse {
  status: number;
  partners: PaginationResult<Partner>;
}

export interface CreatePartnerRequest {
  firstname: string
  lastname: string
  mi: string
  mobile: number | string
  email: string
  address: string
  province: string
  city: string
  zip_code: number | string
  e_wallet_number: number | string
  attachment: unknown
}

export interface CreatePartnerResponse {
  status: number;
  data: Partner;
}

export interface UpdatePartnerRequest {
  id: number | string;
  status: PartnerStatusEnum;
  firstname: string
  lastname: string
  mi: string
  mobile: number | string
  email: string
  address: string
  province: string
  city: string
  zip_code: number | string
  e_wallet_number: number | string
  attachment: unknown
}

export interface UpdatePartnerResponse {
  status: number;
  data: Partner;
}

export interface DeletePartnerResponse {
  status: number;
}

export interface DeleteManyPartnersRequest {
  partner_ids: number[];
}

export interface DeleteManyPartnersResponse {
  status: number;
  data: number;
}

function getConfig () {
  const accessToken = localStorage.getItem("login")
  const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config
}

async function getPartners(getPartnersRequest: GetPartnersRequest): Promise<GetPartnersResponse> {
  const config = {
    ...getConfig(),
    params: getPartnersRequest,
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.get('/api/partners', config);
  const getPartnersResponse = response.data
  return getPartnersResponse
}

async function createPartner(createPartnerRequest: CreatePartnerRequest): Promise<CreatePartnerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.post('/api/partners', createPartnerRequest, config);
  const createPartnerResponse = response.data
  return createPartnerResponse
}

async function updatePartner(updatePartnerRequest: UpdatePartnerRequest): Promise<UpdatePartnerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.put(`/api/partners/${updatePartnerRequest.id}`, updatePartnerRequest, config);
  const updatePartnerResponse = response.data
  return updatePartnerResponse
}

async function deletePartner(partnerId: string): Promise<DeletePartnerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.delete(`/api/partners/${partnerId}`, config);
  const deletePartnerResponse = response.data
  return deletePartnerResponse
}

async function deleteManyPartners(deleteManyPartnersRequest: DeleteManyPartnersRequest): Promise<DeleteManyPartnersResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.post(`/api/partners/delete-many`, deleteManyPartnersRequest, config);
  const deleteManyPartnersResponse = response.data
  return deleteManyPartnersResponse
}

export const partnerService = {
  getPartners,
  createPartner,
  updatePartner,
  deletePartner,
  deleteManyPartners
}
