import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import PartnerForm from "pages/partners/PartnerForm";
import {
  selectPartnerToUpdate,
  updatePartner,
  selectUpdatePartnerFormValidationError,
  selectUpdatePartnerRequest,
  selectUpdatingPartner,
  setUpdatePartnerRequestFieldValue,
  setPartnerToUpdate,
  cancelUpdatePartnerModal,
} from "store/partners/updatePartnerSlice";

export default function EditPartnerModal() {
  const dispatch = useAppDispatch();

  const partnerToUpdate = useAppSelector(selectPartnerToUpdate);
  const updatePartnerRequest = useAppSelector(selectUpdatePartnerRequest);
  const updatePartnerFormValidationError = useAppSelector(selectUpdatePartnerFormValidationError);
  const updatingPartner = useAppSelector(selectUpdatingPartner);

  const initialFocusRef = useRef(null);

  const formSubmitHandler = (e: Event) => {
    e.preventDefault();
    dispatch(updatePartner(updatePartnerRequest))
  };

  function setFieldValue(fieldName: string, fieldValue: any) {
    dispatch(setUpdatePartnerRequestFieldValue({ fieldName, fieldValue }))
  }

  return (
    <Transition.Root show={partnerToUpdate ? true : false} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => updatingPartner ? null : dispatch(setPartnerToUpdate(null))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !updatingPartner
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setPartnerToUpdate(null))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }

                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Edit Partner { partnerToUpdate?.id }
                    </Dialog.Title>

                    <PartnerForm
                      initialFocusRef={initialFocusRef}
                      formSubmitHandler={formSubmitHandler}
                      cancelHandler={() => dispatch(cancelUpdatePartnerModal())}
                      request={updatePartnerRequest}
                      setFieldValue={setFieldValue}
                      submittingForm={updatingPartner}
                      formValidationError={updatePartnerFormValidationError}
                      formType="update"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
