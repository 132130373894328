import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Locker } from 'models';
import { lockerService, UpdateLockerRequest } from "services/lockerService";
import { RootState } from 'store/store';

interface UpdateLockerState {
  lockerToView: Locker | null;
  lockerToUpdate: Locker | null;
  updateLockerRequest: UpdateLockerRequest;
  updatingLocker: boolean;
  updateLockerFormValidationError: FormValidationError | null;
}

const updateLockerRequestIntialValue = {
  id: '',
  partner_id: '',
  address: '',
  city: '',
  region: '',
  barangay: '',
  province: '',
  zip_code: '',
  status: null,
}

const updateLockerInitialState: UpdateLockerState = {
  lockerToView: null,
  lockerToUpdate: null,
  updateLockerRequest: updateLockerRequestIntialValue,
  updatingLocker: false,
  updateLockerFormValidationError: null,
}

export const updateLocker = createAsyncThunk('updateLockerModal/updateLocker', async (updateLockerRequest: UpdateLockerRequest, thunkAPI) => {
  try {
    const request = {
      ...updateLockerRequest
    }
    // delete request.attachment
    const updateLockerResponse = await lockerService.updateLocker(request);
    return updateLockerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const updateLockerSlice = createSlice({
  name: 'updateLockerSlice',
  initialState: updateLockerInitialState,
  reducers: {
    setLockerToView: (state, action: PayloadAction<Locker | null>) => {
      state.lockerToView = action.payload

      if (state.lockerToView) {
        state.updateLockerRequest = {
          ...state.lockerToView,
          partner_id: state.lockerToView.partner?.id || '',
        }
      } else {
        state.updateLockerRequest = updateLockerRequestIntialValue
      }

      state.updateLockerFormValidationError = null
    },

    setLockerToUpdate: (state, action: PayloadAction<Locker | null>) => {
      state.lockerToUpdate = action.payload

      if (state.lockerToUpdate) {
        state.updateLockerRequest = {
          ...state.lockerToUpdate,
          partner_id: state.lockerToUpdate.partner?.id || '',
        }
      } else {
        state.updateLockerRequest = updateLockerRequestIntialValue
      }

      state.updateLockerFormValidationError = null
    },

    setUpdateLockerRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.updateLockerRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'id') {
        state.updateLockerRequest.id = action.payload.fieldValue
      } else if (action.payload.fieldName === 'partner_id') {
        state.updateLockerRequest.partner_id = action.payload.fieldValue
      } else if (action.payload.fieldName === 'address') {
        state.updateLockerRequest.address = action.payload.fieldValue
      } else if (action.payload.fieldName === 'city') {
        state.updateLockerRequest.city = action.payload.fieldValue
      } else if (action.payload.fieldName === 'region') {
        state.updateLockerRequest.region = action.payload.fieldValue
      } else if (action.payload.fieldName === 'barangay') {
        state.updateLockerRequest.barangay = action.payload.fieldValue
      } else if (action.payload.fieldName === 'province') {
        state.updateLockerRequest.province = action.payload.fieldValue
      } else if (action.payload.fieldName === 'zip_code') {
        state.updateLockerRequest.zip_code = action.payload.fieldValue
      } else if (action.payload.fieldName === 'status') {
        state.updateLockerRequest.status = action.payload.fieldValue
      }

      if (action.payload.fieldName === 'region') {
        state.updateLockerRequest['province'] = ''
        state.updateLockerRequest['city'] = ''
        state.updateLockerRequest['barangay'] = ''
      }

      if (action.payload.fieldName === 'province') {
        state.updateLockerRequest['city'] = ''
        state.updateLockerRequest['barangay'] = ''
      }

      if (action.payload.fieldName === 'city') {
        state.updateLockerRequest['barangay'] = ''
      }
      
      if (state.updateLockerFormValidationError && state.updateLockerFormValidationError.validationErrors) {
        state.updateLockerFormValidationError.validationErrors = state.updateLockerFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.updateLockerFormValidationError.validationErrors.length === 0) {
          state.updateLockerFormValidationError = null
        }
      }
    },

    cancelUpdateLockerModal: (state) => {
      state.lockerToUpdate = null
      state.updateLockerRequest = updateLockerRequestIntialValue
      state.updatingLocker = false
      state.updateLockerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateLocker.pending, (state) => {
        state.updatingLocker = true
        state.updateLockerFormValidationError = null
      })
      .addCase(updateLocker.fulfilled, (state) => {
        state.lockerToUpdate = null
        state.updatingLocker = false
        state.updateLockerRequest = updateLockerRequestIntialValue
      })
      .addCase(updateLocker.rejected, (state, action) => {
        state.updateLockerFormValidationError = action.payload as FormValidationError
        state.updatingLocker = false
      });
  },
})

export const { setLockerToView, setLockerToUpdate, setUpdateLockerRequestFieldValue, cancelUpdateLockerModal } = updateLockerSlice.actions

export const selectLockerToView = (state: RootState) => state.updateLockerSlice.lockerToView;
export const selectLockerToUpdate = (state: RootState) => state.updateLockerSlice.lockerToUpdate;
export const selectUpdateLockerRequest = (state: RootState) => state.updateLockerSlice.updateLockerRequest;
export const selectUpdatingLocker = (state: RootState) => state.updateLockerSlice.updatingLocker;
export const selectUpdateLockerFormValidationError = (state: RootState) => state.updateLockerSlice.updateLockerFormValidationError;

export default updateLockerSlice.reducer
