import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import AppFieldError from "components/AppFieldError";
import AppInputSelect from "components/AppInputSelect";
import PartnersContext from "contexts/PartnersContext";
import { useAppSelector } from "hooks/store-hooks";
import useQueryState from "hooks/useQueryState";
import { PartnerStatusEnum } from "models";
import qs from 'qs';
import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { selectGetPartnersFormValidationError, selectGettingPartnersLoading } from "store/partners/partnerListSlice";

export default function PartnersFiltersModal() {
  const navigate = useNavigate()

  const { showFilterModal, setShowFilterModal } = useContext(PartnersContext)

  const loading = useAppSelector(selectGettingPartnersLoading);
  const formValidationError = useAppSelector(selectGetPartnersFormValidationError)

  const { value: email, setValue: setEmail } = useQueryState('email')
  const { value: firstname, setValue: setFirstname } = useQueryState('firstname')
  const { value: lastname, setValue: setLastname } = useQueryState('lastname')
  const { value: status, setValue: setStatus } = useQueryState('status')
  const { value: startDate, setValue: setStartDate } = useQueryState('start_date')
  const { value: endDate, setValue: setEndDate } = useQueryState('end_date')

  function submitFilter () {
    const qsParsed = qs.parse(window.location.search.slice(1))
  
    const query = {
      ...qsParsed,
    }

    if (email) {
      query.email = email
    } else {
      delete query.email
    }

    if (firstname) {
      query.firstname = firstname
    } else {
      delete query.firstname
    }

    if (lastname) {
      query.lastname = lastname
    } else {
      delete query.lastname
    }

    if (status) {
      query.status = status
    } else {
      delete query.status
    }

    if (startDate) {
      query.start_date = startDate
    } else {
      delete query.start_date
    }

    if (endDate) {
      query.end_date = endDate
    } else {
      delete query.end_date
    }
  
    delete query.page
  
    const search = qs.stringify(query)
  
    navigate({ search: search ? `?${search}` : '' })

    setShowFilterModal(false)
  }

  function resetFilter () {
    const qsParsed = qs.parse(window.location.search.slice(1))
  
    const query = {
      ...qsParsed,
    }
  
    delete query.email
    delete query.firstname
    delete query.lastname
    delete query.status
    delete query.start_date
    delete query.end_date
    delete query.page
  
    const search = qs.stringify(query)
  
    navigate({ search: search ? `?${search}` : '' })

    setEmail('')
    setFirstname('')
    setLastname('')
    setStatus('')
    setStartDate('')
    setEndDate('')

    setShowFilterModal(false)
  }

  return (
    <>
      <Transition.Root show={showFilterModal} as={Fragment}>
        <Dialog
          className="relative z-10"
          onClose={() => setShowFilterModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                  <div>
                    {
                      !loading
                        ?
                          <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setShowFilterModal(false)}
                            >
                              <span className="sr-only">Close</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        :
                          <></>
                    }
                    
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        Filters
                      </Dialog.Title>

                      <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-cyan-gray-900"
                          >
                            Email
                          </label>

                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />

                          <AppFieldError fieldName={'email'} formValidationError={formValidationError} />
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium text-cyan-gray-900"
                          >
                            Status
                          </label>

                          <AppInputSelect
                            options={[
                              {
                                label: PartnerStatusEnum[PartnerStatusEnum.PENDING],
                                value: PartnerStatusEnum.PENDING.toString()
                              },
                              {
                                label: PartnerStatusEnum[PartnerStatusEnum.PROCESSING],
                                value: PartnerStatusEnum.PROCESSING.toString()
                              },
                              {
                                label: PartnerStatusEnum[PartnerStatusEnum.APPROVED],
                                value: PartnerStatusEnum.APPROVED.toString()
                              },
                              {
                                label: PartnerStatusEnum[PartnerStatusEnum.DECLINED],
                                value: PartnerStatusEnum.DECLINED.toString()
                              },
                              {
                                label: PartnerStatusEnum[PartnerStatusEnum.ACTIVE],
                                value: PartnerStatusEnum.ACTIVE.toString()
                              },
                              {
                                label: PartnerStatusEnum[PartnerStatusEnum.INACTIVE],
                                value: PartnerStatusEnum.INACTIVE.toString()
                              },
                            ]}
                            value={status}
                            onChange={(e) => setStatus(e)}
                          />

                          <AppFieldError fieldName={'status'} formValidationError={formValidationError} />
                        </div>
                        

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="firstname"
                            className="block text-sm font-medium text-cyan-gray-900"
                          >
                            First name
                          </label>

                          <input
                            type="text"
                            name="firstname"
                            id="firstname"
                            className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                          />

                          <AppFieldError fieldName={'firstname'} formValidationError={formValidationError} />
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="lastname"
                            className="block text-sm font-medium text-cyan-gray-900"
                          >
                            Last name
                          </label>

                          <input
                            type="text"
                            name="lastname"
                            id="lastname"
                            className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                          />

                          <AppFieldError fieldName={'lastname'} formValidationError={formValidationError} />
                        </div>

                      </div>

                      <div className="mt-12 flex justify-end items-end margin-top">
                        <button
                          disabled={loading}
                          className={`${loading ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                          onClick={() => resetFilter()}
                        >
                          Reset
                        </button>

                        <button
                          disabled={loading}
                          className={`${loading ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                          onClick={() => submitFilter()}
                        >
                          { 'Submit' }
                        </button>

                        {
                          formValidationError
                            ?
                              <div className="mx-2 flex justify-end">
                                <small className="text-red-500">{ formValidationError.message }</small>
                              </div>
                            :
                              <></>
                        }
                      </div>

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
