import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TransactionsContext from "contexts/TransactionsContent";
import { Fragment, useContext, useRef } from "react";
import TransactionView from "./TransactionView";

export default function ViewTransactionModal() {
  
  const { transactionToView, setTransactionToView } = useContext(TransactionsContext)

  const updatingLocker = false;

  const initialFocusRef = useRef(null);

  return (
    <Transition.Root show={transactionToView ? true : false} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => updatingLocker ? null : setTransactionToView(null)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !updatingLocker
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setTransactionToView(null)}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }

                  <div className="mt-3 sm:mt-5">
                    {/* <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      View Locker { transactionToView?.id }
                    </Dialog.Title> */}

                    <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl bg-cyan-900/20 p-1">
                        {
                          [`Transaction's Information`].map((title) => {
                            return <Tab
                              key={title}
                              className={({ selected }) =>
                                [
                                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-cyan-400 focus:outline-none focus:ring-2',
                                  selected
                                    ? 'bg-cyan-700 shadow'
                                    : 'text-cyan-700 bg-white hover:bg-cyan-700/[0.25] hover:text-white'
                                ].join(' ')
                              }
                            >{ title }</Tab>
                          })
                        }
                      </Tab.List>

                      <Tab.Panels>
                        <Tab.Panel>
                          {
                            transactionToView ? <>
                              <TransactionView
                                transaction={transactionToView}
                              />
                            </> : <></>
                          }
                        </Tab.Panel>

                        {/* <Tab.Panel>
                        </Tab.Panel>

                        <Tab.Panel>
                        </Tab.Panel> */}


                      </Tab.Panels>
                    </Tab.Group>

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
