import { useSearchParams } from "react-router-dom";
import { GetDrawersRequest } from "services/drawerService";
import { fetchDrawers } from "store/lockers/drawerListSlice";
import { useAppDispatch } from "./store-hooks";

export function useFetchDrawers (locker_id: string) {

  const dispatch = useAppDispatch();

  function _fetchDrawers() {
    const getRequest: GetDrawersRequest = {}
    getRequest.locker_id = locker_id
    dispatch(fetchDrawers(getRequest))
  }

  return _fetchDrawers
}
