import Loading from "components/Loading";
import AppPagination, { getPageSearch } from "components/partials/AppPagination";
import UsersContext from "contexts/UsersContext";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { getCompleteAddressFromUserProfile, getCompleteNameFromUserProfile, User } from "models";
import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setCreateUserModalOpen } from "store/users/createUserSlice";
import { setUserToDelete } from "store/users/deleteUserSlice";
import { setUserToUpdate } from "store/users/updateUserSlice";
import { selectGettingUsersLoading, selectUsers, selectUsersCount, selectUsersCurrentPage, selectUsersNumberOfPages, selectUsersPerPage } from "store/users/userListSlice";

export default function UsersTable() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { setShowFilterModal } = useContext(UsersContext)

  const emailFromParams = searchParams.get('email')

  const hasFilter = emailFromParams ? true : false

  const users = useAppSelector(selectUsers);
  const loading = useAppSelector(selectGettingUsersLoading);
  const totalPartners = useAppSelector(selectUsersCount);
  const partnersPerPage = useAppSelector(selectUsersPerPage);
  const totalPages = useAppSelector(selectUsersNumberOfPages);
  const currentPage = useAppSelector(selectUsersCurrentPage);

  function pageHandler (page: number) {
    const search = getPageSearch(page)
    navigate({ search })
  }

  return (
    <div className="p-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p> */}
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>

          <button
            type="button"
            className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => dispatch(setCreateUserModalOpen(true))}
          >
            Add user
          </button>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Image
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Email
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Address
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Mobile Number
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      E-wallet Number
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((user: User, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            {user.profile ? user.profile?.image ? <img
                              className="h-10 w-10 rounded-full"
                              src={user.profile.image}
                              alt=""
                            /> : <></> : <></>}
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        {user.email}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        { user.profile ? getCompleteNameFromUserProfile(user.profile) : ''}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        { user.profile ? getCompleteAddressFromUserProfile(user.profile) : '' }
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {`${user.profile?.phone || ''}`}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {`${user.profile?.e_wallet_number || ''}`}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {`${user.created_at || ''}`}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                        <button className="text-cyan-600 hover:text-cyan-900" onClick={() => dispatch(setUserToUpdate(user))} >
                          Edit
                          <span className="sr-only">user Id#: {user.id}</span>
                        </button>

                        <button className="ml-2 text-red-600 hover:text-red-900" onClick={() => dispatch(setUserToDelete(user))}>
                          Delete
                          <span className="sr-only">user Id#: {user.id}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {
                !loading && users.length === 0 ? <>
                  <div className="flex justify-center p-4">
                    <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                  </div>
                </>
                : <></>
              }

              <AppPagination
                page={currentPage}
                pageHandler={pageHandler}
                loading={loading}
                resources={users}
                totalPages={totalPages}
                totalResources={totalPartners}
                resourcesPerPage={partnersPerPage}
              />

              {
                loading
                ?
                  <>
                    <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <Loading />
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
