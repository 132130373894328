import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Locker } from 'models';
import { lockerService } from "services/lockerService";
import { RootState } from 'store/store';

interface DeleteLockerState {
  lockerToDelete: Locker | null;
  deletingLocker: boolean;
  deleteLockerFormValidationError: FormValidationError | null;
}

const deleteLockerModalInitialState: DeleteLockerState = {
  lockerToDelete: null,
  deletingLocker: false,
  deleteLockerFormValidationError: null,
}

export const deleteLocker = createAsyncThunk('deleteLockerModal/deleteLocker', async (locker: Locker, thunkAPI) => {
  try {
    const deleteLockerResponse = await lockerService.deleteLocker(locker.id.toString());
    return deleteLockerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const deleteLockerSlice = createSlice({
  name: 'deleteLockerSlice',
  initialState: deleteLockerModalInitialState,
  reducers: {
    setLockerToDelete: (state, action: PayloadAction<Locker | null>) => {
      state.lockerToDelete = action.payload
      state.deleteLockerFormValidationError = null
    },
    cancelDeleteLockerModal: (state) => {
      state.lockerToDelete = null
      state.deleteLockerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteLocker.pending, (state) => {
        state.deletingLocker = true
        state.deleteLockerFormValidationError = null
      })
      .addCase(deleteLocker.fulfilled, (state) => {
        state.lockerToDelete = null
        state.deletingLocker = false
      })
      .addCase(deleteLocker.rejected, (state, action) => {
        state.deleteLockerFormValidationError = action.payload as FormValidationError
        state.deletingLocker = false
      });
  },
})

export const { setLockerToDelete, cancelDeleteLockerModal } = deleteLockerSlice.actions

export const selectLockerToDelete = (state: RootState) => state.deleteLockerSlice.lockerToDelete;
export const selectDeletingLocker = (state: RootState) => state.deleteLockerSlice.deletingLocker;
export const selectDeleteLockerFormValidationError = (state: RootState) => state.deleteLockerSlice.deleteLockerFormValidationError;

export default deleteLockerSlice.reducer
