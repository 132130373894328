import { forwardRef } from 'react';

export interface AppValueProps {
  value: any;
}

const AppValue = forwardRef<HTMLInputElement, AppValueProps>(({
  id,
  value,
}: any, ref) => {
  return (
    <div
      ref={ref}
      id={id}
      className="
        mt-1
        block
        w-full
        rounded-md
        border-gray-500
        text-gray-900
        shadow-sm
        focus:border-cyan-500
        focus:ring-cyan-500
        sm:text-sm
        border
        border-1
        px-4
        py-2
      "
    >{ value ? value : <>&nbsp;</> }</div>
  )
})

export default AppValue
