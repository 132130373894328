import Loading from "components/Loading";
import AppPagination, { getPageSearch } from "components/partials/AppPagination";
import DrawersContext from "contexts/DrawersContext";
import { FormValidationError } from "errors/FormValidationError";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchDrawers } from "hooks/useFetchDrawers";
import { Drawer, DrawerSize, DrawerStatus } from "models";
import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setDrawerToDelete } from "store/lockers/deleteDrawerSlice";
import { selectGettingDrawersLoading, selectDrawers, selectDrawersCount, selectDrawersCurrentPage, selectDrawersNumberOfPages, selectDrawersPerPage } from "store/lockers/drawerListSlice";
import { setDrawerToUpdate } from "store/lockers/updateDrawerSlice";
import { useEffect } from 'react';

export interface DrawerFormProps {
  request: Record<string, any>;
  // formValidationError: FormValidationError | null;
}

export default function DrawersTable({
  request,
}: DrawerFormProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { setShowFilterModal } = useContext(DrawersContext)

  const lockerCodeFromParams = searchParams.get('locker_code')
  const statusFromParams = searchParams.get('status')

  const hasFilter = false

  const fetchDrawers = useFetchDrawers(request.lockerToView ? request.lockerToView.id : 0);

  const drawers = useAppSelector(selectDrawers);
  const loading = useAppSelector(selectGettingDrawersLoading);
  const totalDrawers = useAppSelector(selectDrawersCount);
  const drawersPerPage = useAppSelector(selectDrawersPerPage);
  const totalPages = useAppSelector(selectDrawersNumberOfPages);
  const currentPage = useAppSelector(selectDrawersCurrentPage);

  function pageHandler (page: number) {
    const search = getPageSearch(page)
    navigate({ search })
  }

  useEffect(() => {
    // Call fetchDrawers when the component mounts
    fetchDrawers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                  <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      CU #
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Position
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Size
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {drawers.map((drawer: Drawer, i: number) => (
                    <tr key={i}>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {drawer.cu_number}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {drawer.position}
                      </td>
                      
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {(() => {
                          switch (drawer.size) {
                            case DrawerSize.XS:
                              return "XS";
                            case DrawerSize.SMALL:
                              return "Small";
                            case DrawerSize.MEDIUM:
                              return "Medium";
                            case DrawerSize.LARGE:
                              return "Large";
                            case DrawerSize.XL:
                              return "XL";
                            case DrawerSize.XXL:
                              return "XXL";
                          }
                        })()}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {drawer.price}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className={ `inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${drawer.status ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}` } >
                          {(() => {
                            switch (drawer.status) {
                              case DrawerStatus.EMPTY:
                                return "Empty";
                              case DrawerStatus.OCCUPIED:
                                return "Occupied";
                            }
                          })()}
                        </span>
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">

                        <button className="ml-2 text-cyan-600 hover:text-cyan-900" onClick={() => dispatch(setDrawerToUpdate(drawer))}>
                          Edit
                          <span className="sr-only">Drawer Id#: {drawer.id}</span>
                        </button>

                        <button className="ml-2 text-red-600 hover:text-red-900" onClick={() => dispatch(setDrawerToDelete(drawer))}>
                          Delete
                          <span className="sr-only">Drawer Id#: {drawer.id}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {
                !loading && drawers.length === 0 ? <>
                  <div className="flex justify-center p-4">
                    <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                  </div>
                </>
                : <></>
              }

              <AppPagination
                page={currentPage}
                pageHandler={pageHandler}
                loading={loading}
                resources={drawers}
                totalPages={totalPages}
                totalResources={totalDrawers}
                resourcesPerPage={drawersPerPage}
              />
              
              {
                loading
                ?
                  <>
                    <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <Loading />
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
