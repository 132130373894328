import { useSearchParams } from "react-router-dom";
import { GetTransactionsRequest } from "services/transactionService";
import { exportTransactions } from "store/transactions/transactionListSlice";
import { useAppDispatch } from "./store-hooks";

export function useExportTransactions () {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') ? searchParams.get('page') : '1'
  const codeFromParams = searchParams.get('code') ? searchParams.get('code') : ''
  const lockerCodeFromParams = searchParams.get('locker_code') ? searchParams.get('locker_code') : ''
  const statusFromParams = searchParams.get('status') ? searchParams.get('status') : ''
  const startDateFromParams = searchParams.get('start_date') ? searchParams.get('start_date') : ''
  const endDateFromParams = searchParams.get('end_date') ? searchParams.get('end_date') : ''
  const startTimeFromParams = searchParams.get('start_time') ? searchParams.get('start_time') : ''
  const endTimeFromParams = searchParams.get('end_time') ? searchParams.get('end_time') : ''

  const dispatch = useAppDispatch();

  function _exportTransactions() {
    const getTransactionsRequest: GetTransactionsRequest = {}

    if (pageFromParams) {
      getTransactionsRequest.page = pageFromParams
    }

    if (codeFromParams) {
      getTransactionsRequest.code = codeFromParams
    }

    if (lockerCodeFromParams) {
      getTransactionsRequest.locker_code = lockerCodeFromParams
    }

    if (statusFromParams) {
      getTransactionsRequest.status = statusFromParams
    }

    if (startDateFromParams) {
      getTransactionsRequest.start_date = startDateFromParams
    }

    if (endDateFromParams) {
      getTransactionsRequest.end_date = endDateFromParams
    }

    if (startTimeFromParams) {
      getTransactionsRequest.start_time = startTimeFromParams + ':00'
    }

    if (endTimeFromParams) {
      getTransactionsRequest.end_time = endTimeFromParams + ':00'
    }

    dispatch(exportTransactions(getTransactionsRequest))
  }

  return _exportTransactions
}
