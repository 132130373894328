import axios from "axios";
import { PaginationResult, User } from "models";

export interface GetUsersRequest {
  email?: string;
  firstname?: string;
  lastname?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  page?: string
}

export interface GetUsersResponse {
  status: number;
  users: PaginationResult<User>;
}

export interface CreateUserRequest {
  // firstname: string
  // lastname: string
  // mi: string
  // mobile: number | string
  email: string
  password: string
  // address: string
  // province: string
  // city: string
  // zip_code: number | string
  // e_wallet_number: number | string
  // attachment: unknown
}

export interface CreateUserResponse {
  status: number;
  data: User;
}

export interface UpdateUserRequest {
  id: number | string;
  firstname: string
  lastname: string
  mi: string | null
  phone: number | string
  // email: string
  // password: string
  address: string
  // province: string | null
  // city: string | null
  // zip_code: number | string | null
  e_wallet_number: number | string
  // attachment: unknown
  points: number;
  is_enabled: boolean;
}

export interface UpdateUserResponse {
  status: number;
  data: User;
}

export interface DeleteUserResponse {
  status: number;
}

function getConfig () {
  const accessToken = localStorage.getItem("login")
  const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config
}

async function getUsers(getUsersRequest: GetUsersRequest): Promise<GetUsersResponse> {
  const config = {
    ...getConfig(),
    params: getUsersRequest,
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * 1))

  const response = await axios.get('/api/users', config);
  const getUsersResponse = response.data
  return getUsersResponse
}

async function createUser(createUserRequest: CreateUserRequest): Promise<CreateUserResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * 1))

  const response = await axios.post('/api/users', createUserRequest, config);
  const createUserResponse = response.data
  return createUserResponse
}

async function updateUser(updateUserRequest: UpdateUserRequest): Promise<UpdateUserResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * 1))

  const response = await axios.put(`/api/users/${updateUserRequest.id}`, updateUserRequest, config);
  const updateUserResponse = response.data
  return updateUserResponse
}

async function deleteUser(userId: string): Promise<DeleteUserResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * 1))

  const response = await axios.delete(`/api/users/${userId}`, config);
  const deleteUserResponse = response.data
  return deleteUserResponse
}

export const userService = {
  getUsers,
  createUser,
  updateUser,
  deleteUser
}
