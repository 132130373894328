import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError } from 'errors/FormValidationError';
import { Drawer } from 'models';
import qs from 'qs';
import { GetDrawersRequest, drawerService } from "services/drawerService";
import { asyncThunkErrorHandler } from 'store/asyncThunkErrorHandler';
import { RootState } from 'store/store';
import { createDrawer } from './createDrawerSlice';
import { deleteDrawer } from './deleteDrawerSlice';
import { updateDrawer } from './updateDrawerSlice';
const qsParsed = qs.parse(window.location.search.slice(1))

interface DrawerListState {
  gettingDrawersLoading: boolean;
  drawersCount: number;
  drawers: Drawer[];
  drawersPerPage: number;
  drawersCurrentPage: number;
  getDrawersRequest: GetDrawersRequest;
  getDrawersFormValidationError: FormValidationError | null;
}

const drawerListInitialState: DrawerListState = {
  gettingDrawersLoading: false,
  drawersCount: 0,
  drawers: [],
  drawersPerPage: 8,
  drawersCurrentPage: qsParsed.page ? Number(qsParsed.page) : 1,
  getDrawersRequest: {},
  getDrawersFormValidationError: null,
}

export const fetchDrawers = createAsyncThunk('drawerList/fetchDrawers', async (getDrawersRequest: GetDrawersRequest, thunkAPI) => {
  try {
    const getDrawersResponse = await drawerService.getDrawers(getDrawersRequest);
    return getDrawersResponse;
  } catch (err: any) {
    return asyncThunkErrorHandler(err, thunkAPI)
  }
});

export const drawerListSlice = createSlice({
  name: 'drawerListSlice',
  initialState: drawerListInitialState,
  reducers: {
    setDrawersCurrentPage: (state, action: PayloadAction<number>) => {
      state.drawersCurrentPage = action.payload
    },

    removeDrawerFromList: (state, action: PayloadAction<number>) => {
      const drawerId = action.payload
      
      const index = state.drawers.findIndex((drawer) => drawer.id === drawerId)

      if (index) {
        state.drawers.splice(index, 1)
      }

      state.drawersCount--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDrawer.fulfilled, (state) => {
        state.drawersCount++
      })

      .addCase(updateDrawer.fulfilled, (state, action) => {
        const index = state.drawers.findIndex((drawer) => drawer.id === action.meta.arg.id)

        if (index > -1) {
          state.drawers.splice(index, 1, action.payload.data)
        }
      })

      .addCase(deleteDrawer.fulfilled, (state, action) => {
        const index = state.drawers.findIndex((drawer) => drawer.id === action.meta.arg.id)

        if (index > -1) {
          state.drawers.splice(index, 1)
        }

        state.drawersCount--
      })

      .addCase(fetchDrawers.pending, (state, action) => {
        state.getDrawersRequest = action.meta.arg
        state.gettingDrawersLoading = true
        state.getDrawersFormValidationError = null
      })
      .addCase(fetchDrawers.fulfilled, (state, action) => {
        state.gettingDrawersLoading = false

        const meta = action.payload.drawers.meta

        if (meta) {
          state.drawersCount = meta.total
          state.drawers = action.payload.drawers.data;
          state.drawersPerPage = meta.per_page
          state.drawersCurrentPage = meta.current_page
        } else {
          state.drawersCount = action.payload.drawers.data.length
          state.drawers = action.payload.drawers.data;
          state.drawersPerPage = action.payload.drawers.data.length
          state.drawersCurrentPage = 1
        }
      })
      .addCase(fetchDrawers.rejected, (state, action) => {
        state.gettingDrawersLoading = false
        state.drawersCount = 0
        state.drawers = []
        state.getDrawersFormValidationError = (action.meta.rejectedWithValue ? action.payload : action.error) as FormValidationError
      });
  },
})

export const { setDrawersCurrentPage, removeDrawerFromList } = drawerListSlice.actions

export const selectDrawersCount = (state: RootState) => state.drawerListSlice.drawersCount;
export const selectDrawers = (state: RootState) => state.drawerListSlice.drawers;
export const selectGettingDrawersLoading = (state: RootState) => state.drawerListSlice.gettingDrawersLoading;
export const selectDrawersPerPage = (state: RootState) => state.drawerListSlice.drawersPerPage;
export const selectDrawersNumberOfPages = (state: RootState) => Math.ceil(state.drawerListSlice.drawersCount / state.drawerListSlice.drawersPerPage);
export const selectDrawersCurrentPage = (state: RootState) => state.drawerListSlice.drawersCurrentPage;
export const selectGetDrawersFormValidationError = (state: RootState) => state.drawerListSlice.getDrawersFormValidationError;

export default drawerListSlice.reducer
