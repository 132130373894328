import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
export interface AppInputSelectProps {
  disabled?: boolean;
  value: any
  options: {
    label: string,
    value: any
  }[]
  onChange: (value: any) => void
  getSelectedOption?: () => {
    label: string;
    value: any;
  } | null;
}

export default function AppInputSelect({
  disabled,
  value,
  options,
  onChange,
  getSelectedOption,
}: AppInputSelectProps) {
  // const selectedOption = options.find((option) => option.value === value)
  const selectedOption = getSelectedOption ? getSelectedOption() : options.find((option) => option.value === value) || null

  const props = { tabIndex: 0 }
  const icon = value && !disabled ? <div
    { ...props }
    className="absolute inset-y-0 right-0 flex items-center p-2 focus:outline-none focus:border-cyan-500 focus:ring-cyan-500 focus:ring-1 m-1 rounded-lg cursor-pointer block"
    onClick={(e) => {
      e.stopPropagation()
      onChange('')
    }}
    onKeyDown={(e) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        onChange('')
      }
    }}
  >
    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
  </div> : <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2">
    <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
  </span>

  if (disabled) {
    return <button
      disabled={disabled}
      className="
        relative
        w-full
        cursor-not-allowed
        rounded-md
        bg-white
        py-2
        pl-3
        pr-8
        text-left
        shadow-md
        focus:outline-none
        focus:border-cyan-500
        focus:ring-cyan-500
        focus:ring-1
        sm:text-sm
        mt-1
        block
        border
        border-gray-200
      "
    >
      <span className="block truncate">{selectedOption ? selectedOption.label : value}&nbsp;</span>
      {icon}
    </button>
  }


  return (
    <Listbox value={selectedOption} onChange={(selected) => onChange(selected ? selected.value : selected)}>
      <div className="relative mt-1">
        <Listbox.Button as={Fragment}>
          <button
            disabled={disabled}
            className="
              relative
              w-full
              cursor-pointer
              rounded-md
              bg-white
              py-2
              pl-3
              pr-8
              text-left
              shadow-md
              focus:outline-none
              focus:border-cyan-500
              focus:ring-cyan-500
              focus:ring-1
              sm:text-sm
              mt-1
              block
              border
              border-gray-500
            "
          >
            <span className="block truncate">{selectedOption ? selectedOption.label : value}&nbsp;</span>
            {icon}
          </button>
        </Listbox.Button>

        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="
              absolute
              mt-1
              max-h-40
              w-full
              overflow-auto
              rounded-md
              bg-white
              py-1
              text-base
              shadow-lg
              ring-1
              ring-black
              ring-opacity-5
              focus:outline-none
              sm:text-sm
              border-cyan-500
              ring-cyan-500
              z-50
            "
          >
            {options.map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-cyan-100 text-cyan-900' : 'text-gray-900'
                  }`
                }
                value={option}
              >
                {(qwe) => {
                  return (
                    <>
                      <span className={`block truncate ${qwe.selected || selectedOption?.value === option.value ? 'font-medium' : 'font-normal'}`}>
                        {option.label}
                      </span>
  
                      {qwe.selected || selectedOption?.value === option.value ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-cyan-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
