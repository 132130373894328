import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchDrawers } from "hooks/useFetchDrawers";
import DrawerForm from "pages/lockers/DrawerForm";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  cancelCreateDrawerModal,
  createDrawer,
  selectCreateDrawerFormValidationError, selectCreateDrawerModalOpen, selectCreateDrawerRequest,
  selectCreatingDrawer,
  setCreateDrawerModalOpen,
  setCreateDrawerRequestFieldValue
} from "store/lockers/createDrawerSlice";

export default function CreateDrawerModal() {
  const dispatch = useAppDispatch();

  const createDrawerModalOpen = useAppSelector(selectCreateDrawerModalOpen);
  const createDrawerRequest = useAppSelector(selectCreateDrawerRequest);
  const createDrawerFormValidationError = useAppSelector(selectCreateDrawerFormValidationError);
  const creatingDrawer = useAppSelector(selectCreatingDrawer);
  // const fetchDrawers = useFetchDrawers()

  const initialFocusRef = useRef(null);

  const [selectedLocker, setSelectedLocker] = useState<null | { id: number, locker_code: number /*, email: string, firstname: string, lastname: string */ }>(null)

  useEffect(() => {
    dispatch(setCreateDrawerRequestFieldValue({ fieldName: 'locker_id', fieldValue: selectedLocker ? selectedLocker.id : '' }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocker])

  const formSubmitHandler = (e: Event) => {
    e.preventDefault();
    dispatch(createDrawer(createDrawerRequest))
    // .then(() => {
    //   fetchDrawers()
    // })
  };

  function setFieldValue(fieldName: string, fieldValue: string | null | { id: number, locker_code: number }) {
    if (fieldName === 'selectedLocker') {
      setSelectedLocker(fieldValue as null | { id: number, locker_code: number })
    } else {
      dispatch(setCreateDrawerRequestFieldValue({ fieldName, fieldValue }))
    }
  }

  return (
    <Transition.Root show={createDrawerModalOpen} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => creatingDrawer ? null : dispatch(setCreateDrawerModalOpen(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !creatingDrawer
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setCreateDrawerModalOpen(false))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }
                  
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Create Drawer
                    </Dialog.Title>

                    <DrawerForm
                      initialFocusRef={initialFocusRef}
                      formSubmitHandler={formSubmitHandler}
                      cancelHandler={() => dispatch(cancelCreateDrawerModal())}
                      request={{
                        ...createDrawerRequest,
                        selectedLocker,
                      }}
                      setFieldValue={setFieldValue}
                      submittingForm={creatingDrawer}
                      formValidationError={createDrawerFormValidationError}
                      formType={'create'}
                    />

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
