import Loading from "components/Loading";
import AppPagination, { getPageSearch } from "components/partials/AppPagination";
import { Contact } from "models";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import TransactionsFilters from "./TransactionsFilters";
import axios from 'axios';
import GlobalContext from "contexts/GlobalContext";
import { DateTime } from 'luxon';

export default function ContactsTable() {
  const { catchErr } = useContext(GlobalContext)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const startDateFromParams = searchParams.get('start_date')
  const endDateFromParams = searchParams.get('end_date')
  const pageFromParams = searchParams.get('page')
  const currentPage = pageFromParams ? Number(pageFromParams) : 1

  const hasFilter = startDateFromParams || endDateFromParams ? true : false

  const perPage = 8
  const [contactsCount, setContactsCount] = useState(0)
  const totalPages = Math.ceil(contactsCount / perPage)
  const [contacts, setContacts] = useState([])
  const [gettingContacts, setGettingContacts] = useState(false)

  useEffect(() => {
    const accessToken = localStorage.getItem("login")

    if (!accessToken) {
      return
    }
    
    const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';

    setGettingContacts(true)

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

    axios.get(`${API_BASE_URL}/api/contacts`, {
      headers: {
        Authorization: token
      },
      params: {
        per_page: perPage,
        page: currentPage,
      },
    }).then((response) => {
      console.log('get contacts response', response)
      const contacts = response.data.contacts.data
      const contactsCount = response.data.contacts.meta.total
      setContacts(contacts)
      setContactsCount(contactsCount)
    }).catch((err) => {
      console.log('get contacts err', err)
      setContacts([])
      catchErr(err)
    }).finally(() => {
      setGettingContacts(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  // const transactions = useAppSelector(selectTransactions);
  // const loading = useAppSelector(selectGettingTransactionsLoading);
  // const totalTransactions = useAppSelector(selectTransactionsCount);
  // const transactionsPerPage = useAppSelector(selectTransactionsPerPage);
  // const totalPages = useAppSelector(selectTransactionsNumberOfPages);
  // const currentPage = useAppSelector(selectTransactionsCurrentPage);

  function pageHandler (page: number) {
    const search = getPageSearch(page)
    navigate({ search })
  }

  return (
    <div className="p-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Contacts</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        </div>
      </div>

      {/* <TransactionsFilters /> */}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Message
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Submitted At
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                      <span className="sr-only">Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {contacts.map((contact: Contact) => (
                    <tr key={contact.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        {contact.id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {contact.name}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {contact.email}
                      </td>

                      <td
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        style={{ width: '340px', maxWidth: '340px' }}
                      >
                        <p className="truncate">{contact.message}</p>
                      </td>
                      
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {contact.created_at ? DateTime.fromISO(contact.created_at, { zone: 'utc' }).setZone('Asia/Manila').toFormat('LLL dd, yyyy | hh:mm.ss a') : '' }
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {contact.status}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                        N/A
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {
                !gettingContacts && contacts.length === 0 ? <>
                  <div className="flex justify-center p-4">
                    <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                  </div>
                </>
                : <></>
              }

              <AppPagination
                page={currentPage}
                pageHandler={pageHandler}
                loading={gettingContacts}
                resources={contacts}
                totalPages={totalPages}
                totalResources={contactsCount}
                resourcesPerPage={perPage}
              />
              
              {
                gettingContacts
                ?
                  <>
                    <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <Loading />
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
