import AnnoucementTab from "components/AnnoucementTab";
import TransactionsContext from "contexts/TransactionsContent";
import { useFetchTransactions } from "hooks/useFetchTransactions";
import useTransactionsState from "hooks/useTransactionsState";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TransactiomsFiltersModal from "./TransactiomsFiltersModal";
import TransactionsTable from "./TransactionTable";
import ViewTransactionModal from "./ViewTransactionModal";

export default function TransactionsPage() {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') ? searchParams.get('page') : '1'
  const lockerCodeFromParams = searchParams.get('locker_code') ? searchParams.get('locker_code') : ''
  const statusFromParams = searchParams.get('status') ? searchParams.get('status') : ''
  const startDateFromParams = searchParams.get('start_date') ? searchParams.get('start_date') : ''
  const endDateFromParams = searchParams.get('end_date') ? searchParams.get('end_date') : ''
  const startTimeFromParams = searchParams.get('start_time') ? searchParams.get('start_time') : ''
  const endTimeFromParams = searchParams.get('end_time') ? searchParams.get('end_time') : ''

  const fetchTransactions = useFetchTransactions()

  useEffect(() => {
    fetchTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFromParams, lockerCodeFromParams, lockerCodeFromParams, statusFromParams, startDateFromParams, endDateFromParams, startTimeFromParams, endTimeFromParams]);

  const transactionsState = useTransactionsState()

  return (
    <>
      <TransactionsContext.Provider value={transactionsState}>
        <main className="flex-1 overflow-y-auto">
          {/* Primary column */}
          <section
            aria-labelledby="primary-heading"
            className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
          >
            <TransactionsTable/>

            <ViewTransactionModal />

            <TransactiomsFiltersModal />
          </section>
        </main>

        {/* Secondary column (hidden on smaller screens) */}
        {/* <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
          <AnnoucementTab />
        </aside> */}
      </TransactionsContext.Provider>
    </>
  );
}
