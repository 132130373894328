import AnnoucementTab from "components/AnnoucementTab";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import UsersContext from "contexts/UsersContext";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchUsers } from "hooks/useFetchUsers";
import useUsersState from "hooks/useUsersState";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cancelDeleteUserModal, deleteUser, selectDeleteUserFormValidationError, selectDeletingUser, selectUserToDelete, setUserToDelete } from "store/users/deleteUserSlice";
import CreateUserModal from "./CreateUserModal";
import EditUserModal from "./EditUserModal";
import UsersFilterModal from "./UsersFilterModal";
import UsersTable from "./UsersTable";

export default function Users() {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') ? searchParams.get('page') : '1'
  const emailFromParams = searchParams.get('email') || ''
  const firstnameFromParams = searchParams.get('firstname') || ''
  const lastnameFromParams = searchParams.get('lastname') || ''
  const statusFromParams = searchParams.get('status') || ''
  const startDateFromParams = searchParams.get('start_date') || ''
  const endDateFromParams = searchParams.get('end_date') || ''

  const dispatch = useAppDispatch()
  const userToDelete = useAppSelector(selectUserToDelete)
  const deletingUser = useAppSelector(selectDeletingUser)
  const deleteUserFormValidationError = useAppSelector(selectDeleteUserFormValidationError)

  const fetchUsers = useFetchUsers()

  useEffect(() => {
    fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageFromParams,
    startDateFromParams,
    endDateFromParams,
    emailFromParams,
    firstnameFromParams,
    lastnameFromParams,
    statusFromParams,
  ]);

  const confirmDeleteUserHandler = () => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete)).then(() => {
        fetchUsers()
      })
    }
  };

  const usersState = useUsersState()
  
  return (
    <>
      <UsersContext.Provider value={usersState}>
        <main className="flex-1 overflow-y-auto">
          {/* Primary column */}
          <section
            aria-labelledby="primary-heading"
            className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
          >
            <UsersTable/>
          </section>
        </main>

        {/* Secondary column (hidden on smaller screens) */}
        {/* <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
          <AnnoucementTab />
        </aside> */}

        <CreateUserModal />

        <EditUserModal />

        <UsersFilterModal />

        <DeleteConfirmationModal
          title={'Delete user'}
          subtitle={
            <>
              Are you sure you want to delete this user ({userToDelete?.email})?
              All of their data will be permanently removed from our servers forever.
              This action cannot be undone.
            </>
          }
          openModal={userToDelete ? true : false}
          loading={deletingUser}
          closeHandler={() => dispatch(setUserToDelete(null))}
          cancelHandler={() => dispatch(cancelDeleteUserModal())}
          confirmHandler={confirmDeleteUserHandler}
          formValidationError={deleteUserFormValidationError}
        />
      </UsersContext.Provider>
    </>
  );
}
