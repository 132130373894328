import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { Drawer } from 'models';
import { drawerService, UpdateDrawerRequest } from "services/drawerService";
import { RootState } from 'store/store';

interface UpdateDrawerState {
  drawerToView: Drawer | null;
  drawerToUpdate: Drawer | null;
  updateDrawerRequest: UpdateDrawerRequest;
  updatingDrawer: boolean;
  updateDrawerFormValidationError: FormValidationError | null;
}

const updateDrawerRequestIntialValue = {
  id: '',
  locker_id: '',
  cu_number: '',
  position: '',
  size: 0,
  price: '',
  status: 0
}

const updateDrawerInitialState: UpdateDrawerState = {
  drawerToView: null,
  drawerToUpdate: null,
  updateDrawerRequest: updateDrawerRequestIntialValue,
  updatingDrawer: false,
  updateDrawerFormValidationError: null,
}

export const updateDrawer = createAsyncThunk('updateDrawerModal/updateDrawer', async (updateDrawerRequest: UpdateDrawerRequest, thunkAPI) => {
  try {
    const request = {
      ...updateDrawerRequest
    }
    // delete request.attachment
    const updateDrawerResponse = await drawerService.updateDrawer(request);
    return updateDrawerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const updateDrawerSlice = createSlice({
  name: 'updateDrawerSlice',
  initialState: updateDrawerInitialState,
  reducers: {
    setDrawerToView: (state, action: PayloadAction<Drawer | null>) => {
      state.drawerToView = action.payload

      if (state.drawerToView) {
        state.updateDrawerRequest = {
          ...state.drawerToView,
          locker_id: state.drawerToView.locker?.id || '',
        }
      } else {
        state.updateDrawerRequest = updateDrawerRequestIntialValue
      }

      state.updateDrawerFormValidationError = null
    },

    setDrawerToUpdate: (state, action: PayloadAction<Drawer | null>) => {
      state.drawerToUpdate = action.payload

      if (state.drawerToUpdate) {
        state.updateDrawerRequest = {
          ...state.drawerToUpdate,
          locker_id: state.drawerToUpdate.locker?.id || '',
        }
      } else {
        state.updateDrawerRequest = updateDrawerRequestIntialValue
      }

      state.updateDrawerFormValidationError = null
    },

    setUpdateDrawerRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.updateDrawerRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'locker_id') {
        state.updateDrawerRequest.locker_id = action.payload.fieldValue
      } else if (action.payload.fieldName === 'cu_number') {
        state.updateDrawerRequest.cu_number = action.payload.fieldValue
      } else if (action.payload.fieldName === 'position') {
        state.updateDrawerRequest.position = action.payload.fieldValue
      } else if (action.payload.fieldName === 'size') {
        state.updateDrawerRequest.size = action.payload.fieldValue
      } else if (action.payload.fieldName === 'price') {
        state.updateDrawerRequest.price = action.payload.fieldValue
      } else if (action.payload.fieldName === 'status') {
        state.updateDrawerRequest.status = action.payload.fieldValue
      }
      
      if (state.updateDrawerFormValidationError && state.updateDrawerFormValidationError.validationErrors) {
        state.updateDrawerFormValidationError.validationErrors = state.updateDrawerFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.updateDrawerFormValidationError.validationErrors.length === 0) {
          state.updateDrawerFormValidationError = null
        }
      }
    },

    cancelUpdateDrawerModal: (state) => {
      state.drawerToUpdate = null
      state.updateDrawerRequest = updateDrawerRequestIntialValue
      state.updatingDrawer = false
      state.updateDrawerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDrawer.pending, (state) => {
        state.updatingDrawer = true
        state.updateDrawerFormValidationError = null
      })
      .addCase(updateDrawer.fulfilled, (state) => {
        state.drawerToUpdate = null
        state.updatingDrawer = false
        state.updateDrawerRequest = updateDrawerRequestIntialValue
      })
      .addCase(updateDrawer.rejected, (state, action) => {
        state.updateDrawerFormValidationError = action.payload as FormValidationError
        state.updatingDrawer = false
      });
  },
})

export const { setDrawerToView, setDrawerToUpdate, setUpdateDrawerRequestFieldValue, cancelUpdateDrawerModal } = updateDrawerSlice.actions

export const selectDrawerToView = (state: RootState) => state.updateDrawerSlice.drawerToView;
export const selectDrawerToUpdate = (state: RootState) => state.updateDrawerSlice.drawerToUpdate;
export const selectUpdateDrawerRequest = (state: RootState) => state.updateDrawerSlice.updateDrawerRequest;
export const selectUpdatingDrawer = (state: RootState) => state.updateDrawerSlice.updatingDrawer;
export const selectUpdateDrawerFormValidationError = (state: RootState) => state.updateDrawerSlice.updateDrawerFormValidationError;

export default updateDrawerSlice.reducer
