import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { logout, selectLoggedInUserProfile } from "store/auth/authSlice";
import { getCompleteNameFromUserProfile } from 'models'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const userNavigation = [{ name: "Your Profile", href: "/settings/account" }];

export interface AppHeaderProps {
  setMobileMenuOpenHandler: () => void;
}

export default function AppHeader({ setMobileMenuOpenHandler }: AppHeaderProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggedInUserProfile = useAppSelector(selectLoggedInUserProfile)

  const signoutHandler = () => {
    localStorage.removeItem("login");
    dispatch(logout())
    navigate("/");
  };

  return (
    <>
      <header className="w-full">
        <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 md:hidden"
            onClick={setMobileMenuOpenHandler}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4 sm:px-6">
            <div className="flex flex-1">
              {/* <form className="flex w-full md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search all files
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    name="search-field"
                    id="search-field"
                    className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                    placeholder="Search"
                    type="search"
                  />
                </div>
              </form> */}
            </div>

            <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
              {/* Profile dropdown */}
              <Menu>
                <div className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      {loggedInUserProfile && loggedInUserProfile.image ? <img
                        className="h-8 w-8 rounded-full"
                        src={loggedInUserProfile.image}
                        alt="profile_image"
                      /> : <></>}
                      <span className="px-2 font-bold">{ loggedInUserProfile ? getCompleteNameFromUserProfile(loggedInUserProfile) : '' }</span>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <span
                          className="block px-4 py-2 text-sm text-gray-700"
                          style={{ cursor: "pointer" }}
                          onClick={signoutHandler}
                        >
                          Log out
                        </span>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </div>
              </Menu>

              {/* <button
                type="button"
                className="flex items-center justify-center rounded-full bg-cyan-600 p-1 text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              >
                <PlusIcon className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">Add file</span>
              </button> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
