import AnnoucementTab from "components/AnnoucementTab";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { LineChart } from "./LineChart";
import { PieChart } from "./PieChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Transactions',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  maintainAspectRatio: false,
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novermber', 'December'];

function shuffle(array: unknown[]) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const data = {
  labels,
  datasets: [
    {
      label: 'Deposited',
      data: shuffle([1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1, 7]),
      backgroundColor: '#facc15' || 'yellow',
    },
    {
      label: 'Paid',
      data: shuffle([1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1, 7]),
      backgroundColor: '#4ade80' || 'green',
    },
    {
      label: 'Claimed',
      data: shuffle([1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1, 7]),
      backgroundColor: '#9ca3af' || 'gray',
    },
    {
      label: 'Returned',
      data: shuffle([1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1, 7]),
      backgroundColor: '#f97316' || 'orange',
    },
    {
      label: 'Cancelled',
      data: shuffle([1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1, 7]),
      backgroundColor: '#f87171' || 'red',
    },
  ],
};

export default function Overview() {
  
  return (
    <>
      <main className="flex-1 overflow-y-auto">
        {/* Primary column */}
        <section
          aria-labelledby="primary-heading"
          className="flex h-full min-w-0 flex-1 flex-col lg:order-last px-6 py-4"
        >
          {/* Your content */}
          <h1>Overview</h1>

          <div>
            <div
              style={{
                height: '400px',
                width: 'calc(50% - 16px)',
                border: '1px solid black'
              }}
              className="rounded-lg inline-flex justify-center p-2 m-1"
            >
              <Bar options={options} data={data} />;
            </div>

            <div
              style={{
                height: '400px',
                width: 'calc(50% - 16px)',
                border: '1px solid black'
              }}
              className="rounded-lg inline-flex justify-center p-2 m-1"
            >
              <LineChart />
            </div>

            <div
              style={{
                height: '400px',
                width: 'calc(50% - 16px)',
                border: '1px solid black'
              }}
              className="rounded-lg inline-flex justify-center p-2 m-1"
            >
              <PieChart />
            </div>
          </div>


        </section>
      </main>

      {/* Secondary column (hidden on smaller screens) */}
      {/* <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
        <AnnoucementTab />
      </aside> */}
    </>
  );
}
