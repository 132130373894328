import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ContactsTable from "./ContactsTable";

export default function ContactsPage() {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') ? searchParams.get('page') : '1'
  const startDateFromParams = searchParams.get('start_date') ? searchParams.get('start_date') : ''
  const endDateFromParams = searchParams.get('end_date') ? searchParams.get('end_date') : ''

  // const fetchTransactions = useFetchTransactions()

  useEffect(() => {
    // fetchTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFromParams, startDateFromParams, endDateFromParams]);

  return (
    <>
      <main className="flex-1 overflow-y-auto">
        {/* Primary column */}
        <section
          aria-labelledby="primary-heading"
          className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
        >
          <ContactsTable/>
        </section>
      </main>

      {/* Secondary column (hidden on smaller screens) */}
      <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
        {/* Your content */}
      </aside>
    </>
  );
}
