import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError } from 'errors/FormValidationError';
import { Partner } from 'models';
import { GetPartnersRequest, partnerService } from "services/partnerService";
import { asyncThunkErrorHandler } from 'store/asyncThunkErrorHandler';
import { RootState } from 'store/store';
import { createPartner } from './createPartnerSlice';
import { deletePartner } from './deletePartnerSlice';
import { updatePartner } from './updatePartnerSlice';

interface PartnerListState {
  gettingPartnersLoading: boolean;
  partnersCount: number;
  partners: Partner[];
  partnersPerPage: number;
  partnersCurrentPage: number;
  getPartnersRequest: GetPartnersRequest;
  getPartnersFormValidationError: FormValidationError | null;
}

const partnerListInitialState: PartnerListState = {
  gettingPartnersLoading: false,
  partnersCount: 0,
  partners: [],
  partnersPerPage: 8,
  partnersCurrentPage: 1,
  getPartnersRequest: {},
  getPartnersFormValidationError: null
}

export const fetchPartners = createAsyncThunk('partnerList/fetchPartners', async (getPartnersRequest: GetPartnersRequest, thunkAPI) => {
  try {
    const getPartnersResponse = await partnerService.getPartners(getPartnersRequest);
    return getPartnersResponse;
  } catch (err: any) {
    return asyncThunkErrorHandler(err, thunkAPI)
  }
});

export const partnerListSlice = createSlice({
  name: 'partnerListSlice',
  initialState: partnerListInitialState,
  reducers: {
    setPartnersCurrentPage: (state, action: PayloadAction<number>) => {
      state.partnersCurrentPage = action.payload
    },

    removePartnerFromList: (state, action: PayloadAction<number>) => {
      const partnerId = action.payload
      
      const index = state.partners.findIndex((partner) => partner.id === partnerId)

      if (index) {
        state.partners.splice(index, 1)
      }

      state.partnersCount--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPartner.fulfilled, (state) => {
        state.partnersCount++
      })

      .addCase(updatePartner.fulfilled, (state, action) => {
        const index = state.partners.findIndex((partner) => partner.id === action.meta.arg.id)

        if (index > -1) {
          state.partners.splice(index, 1, action.payload.data)
        }
      })

      .addCase(deletePartner.fulfilled, (state, action) => {
        const index = state.partners.findIndex((partner) => partner.id === action.meta.arg.id)

        if (index > -1) {
          state.partners.splice(index, 1)
        }

        state.partnersCount--
      })

      .addCase(fetchPartners.pending, (state, action) => {
        state.getPartnersRequest = action.meta.arg
        state.gettingPartnersLoading = true
        state.getPartnersFormValidationError = null
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.gettingPartnersLoading = false

        const meta = action.payload.partners.meta

        if (meta) {
          state.partnersCount = meta.total
          state.partners = action.payload.partners.data;
          state.partnersPerPage = meta.per_page
          state.partnersCurrentPage = meta.current_page
        } else {
          state.partnersCount = action.payload.partners.data.length
          state.partners = action.payload.partners.data;
          state.partnersPerPage = action.payload.partners.data.length
          state.partnersCurrentPage = 1
        }
      })
      .addCase(fetchPartners.rejected, (state, action) => {
        state.gettingPartnersLoading = false
        state.partnersCount = 0
        state.partners = []
        state.getPartnersFormValidationError = (action.meta.rejectedWithValue ? action.payload : action.error) as FormValidationError
      });
  },
})

export const { setPartnersCurrentPage, removePartnerFromList } = partnerListSlice.actions

export const selectPartnersCount = (state: RootState) => state.partnerListSlice.partnersCount;
export const selectPartners = (state: RootState) => state.partnerListSlice.partners;
export const selectGettingPartnersLoading = (state: RootState) => state.partnerListSlice.gettingPartnersLoading;
export const selectPartnersPerPage = (state: RootState) => state.partnerListSlice.partnersPerPage;
export const selectPartnersNumberOfPages = (state: RootState) => Math.ceil(state.partnerListSlice.partnersCount / state.partnerListSlice.partnersPerPage);
export const selectPartnersCurrentPage = (state: RootState) => state.partnerListSlice.partnersCurrentPage;
export const selectGetPartnersFormValidationError = (state: RootState) => state.partnerListSlice.getPartnersFormValidationError;

export default partnerListSlice.reducer
