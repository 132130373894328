import { useSearchParams } from "react-router-dom";
import { GetUsersRequest } from "services/userService";
import { fetchUsers } from "store/users/userListSlice";
import { useAppDispatch } from "./store-hooks";

export function useFetchUsers () {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') || '1'
  const emailFromParams = searchParams.get('email') || ''
  const firstnameFromParams = searchParams.get('firstname') || ''
  const lastnameFromParams = searchParams.get('lastname') || ''
  const statusFromParams = searchParams.get('status') || ''
  const startDateFromParams = searchParams.get('start_date') || ''
  const endDateFromParams = searchParams.get('end_date') || ''

  const dispatch = useAppDispatch();

  function _fetchUsers() {
    const getRequest: GetUsersRequest = {}

    if (pageFromParams) {
      getRequest.page = pageFromParams
    }

    if (emailFromParams) {
      getRequest.email = emailFromParams
    }

    if (firstnameFromParams) {
      getRequest.firstname = firstnameFromParams
    }

    if (lastnameFromParams) {
      getRequest.lastname = lastnameFromParams
    }

    if (statusFromParams) {
      getRequest.status = statusFromParams
    }

    if (startDateFromParams) {
      getRequest.start_date = startDateFromParams
    }

    if (endDateFromParams) {
      getRequest.end_date = endDateFromParams
    }

    dispatch(fetchUsers(getRequest))
  }

  return _fetchUsers
}
