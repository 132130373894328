import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import AppFieldError from "components/AppFieldError";
import LoadingIcon from "components/LoadingIcon";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { Fragment, useRef, useState } from "react";
import { cancelUpdateUserModal, selectUpdateUserFormValidationError, selectUpdateUserRequest, selectUpdatingUser, selectUserToUpdate, setUpdateUserRequestFieldValue, setUserToUpdate, updateUser } from "store/users/updateUserSlice";
import { useFetchUsers } from "hooks/useFetchUsers";

export default function EditUserModal() {
  const dispatch = useAppDispatch();

  const userToUpdate = useAppSelector(selectUserToUpdate);
  const updateUserRequest = useAppSelector(selectUpdateUserRequest);
  const updateUserFormValidationError = useAppSelector(selectUpdateUserFormValidationError);
  const updatingUser = useAppSelector(selectUpdatingUser);

  const initialFocusRef = useRef(null);

  const fetchUsers = useFetchUsers()

  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    console.log("in formSubmitHandler")
    dispatch(updateUser(updateUserRequest)).then(() => {
      fetchUsers()
    })
    console.log("done")
  };

  function setFieldValue(fieldName: string, fieldValue: any) {
    dispatch(setUpdateUserRequestFieldValue({ fieldName, fieldValue }))
  }

  const handleCheckboxChange = (e: any) => {
    setFieldValue('is_enabled', e.target.checked)
  };
  
  return (
    <Transition.Root show={userToUpdate ? true : false} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => updatingUser ? null : dispatch(setUserToUpdate(null))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !updatingUser
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setUserToUpdate(null))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }
                  
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Edit User { userToUpdate?.id }
                    </Dialog.Title>

                    <div className="mt-2">
                      <form
                        className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y"
                        onSubmit={formSubmitHandler}
                      >
                        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                          <div className="sm:col-span-6">
                            <h2 className="text-xl font-medium text-cyan-gray-900">
                              User Information
                            </h2>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="firstname"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              First Name
                            </label>

                            <input
                              type="text"
                              name="firstname"
                              id="firstname"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.firstname}
                              onChange={(e) => setFieldValue('firstname', e.target.value)}
                            />

                            <AppFieldError fieldName={'firstname'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="mi"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Middle Name
                            </label>

                            <input
                              type="text"
                              name="mi"
                              id="mi"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.mi !== null ? updateUserRequest.mi : ""}
                              onChange={(e) => setFieldValue('mi', e.target.value)}
                            />

                            <AppFieldError fieldName={'mi'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="lastname"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Last Name
                            </label>

                            <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.lastname}
                              onChange={(e) => setFieldValue('lastname', e.target.value)}
                            />

                            <AppFieldError fieldName={'lastname'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Phone Number
                            </label>

                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.phone}
                              onChange={(e) => setFieldValue('phone', e.target.value)}
                            />

                            <AppFieldError fieldName={'phone'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="e_wallet_number"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              E-wallet Number
                            </label>

                            <input
                              type="text"
                              name="e_wallet_number"
                              id="e_wallet_number"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.e_wallet_number}
                              onChange={(e) => setFieldValue('e_wallet_number', e.target.value)}
                            />

                            <AppFieldError fieldName={'e_wallet_number'} formValidationError={updateUserFormValidationError} />
                          </div>
                          
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="address"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Address
                            </label>

                            <input
                              type="text"
                              name="address"
                              id="address"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.address}
                              onChange={(e) => setFieldValue('address', e.target.value)}
                            />

                            <AppFieldError fieldName={'address'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="points"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Points
                            </label>

                            <input
                              type="number"
                              name="points"
                              id="points"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.points}
                              onChange={(e) => setFieldValue('points', e.target.value)}
                            />

                            <AppFieldError fieldName={'points'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="is_enabled"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Enabled
                            </label>

                            <input
                              type="checkbox"
                              name="is_enabled"
                              id="is_enabled"
                              className="mt-1 block rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              checked={updateUserRequest.is_enabled}
                              onChange={handleCheckboxChange}
                            />

                            <AppFieldError fieldName={'points'} formValidationError={updateUserFormValidationError} />
                          </div>
                          {/* <div className="sm:col-span-3">
                            <label
                              htmlFor="city"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              City
                            </label>

                            <input
                              type="text"
                              name="city"
                              id="city"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.city !== null ? updateUserRequest.city : ""}
                              onChange={(e) => setFieldValue('city', e.target.value)}
                            />

                            <AppFieldError fieldName={'city'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="province"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Province
                            </label>

                            <input
                              type="text"
                              name="province"
                              id="province"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.province !== null ? updateUserRequest.province : ""}
                              onChange={(e) => setFieldValue('province', e.target.value)}
                            />

                            <AppFieldError fieldName={'province'} formValidationError={updateUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="zip_code"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Zip Code
                            </label>

                            <input
                              type="text"
                              name="zip_code"
                              id="zip_code"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={updateUserRequest.zip_code !== null ? updateUserRequest.zip_code : ""}
                              onChange={(e) => setFieldValue('zip_code', e.target.value)}
                            />

                            <AppFieldError fieldName={'zip_code'} formValidationError={updateUserFormValidationError} />
                          </div> */}
                        </div>

                        <div>
                          <div className="flex justify-end pt-8">
                            <button
                              type="button"
                              disabled={updatingUser}
                              className={`${updatingUser ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                              onClick={() => dispatch(cancelUpdateUserModal())}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              disabled={updatingUser}
                              className={`${updatingUser ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                            >
                              { updatingUser ? <><LoadingIcon /><span>Loading...</span></> : 'Submit' }
                            </button>
                          </div>

                          {
                            updateUserFormValidationError
                              ?
                                <div className="flex justify-end">
                                  <small className="text-red-500">{ updateUserFormValidationError.message }</small>
                                </div>
                              :
                                <></>
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
