import { useState, useEffect } from "react";
import { psgcService } from "services/psgcService";

export default function usePsgc() {
  const [regions, setRegions] = useState<string[][]>([])
  const [provinces, setProvinces] = useState<string[][]>([])
  const [cityOrMunicipalities, setCityOrMunicipalities] = useState<string[][]>([])
  const [barangays, setBarangays] = useState<string[][]>([])
  const [psgcLoading, setPsgcLoading] = useState(false)

  useEffect(() => {
    setPsgcLoading(true)
    psgcService.getPsgcData().then((psgcArrayOfArray) => {
        const {
          regions,
          provinces,
          cityOrMunicipalities,
          barangays,
          // qwe,
        } = psgcArrayOfArray.reduce((result, array) => {
          if (array[2] === 'Reg') {
            result.regions.push([array[0], array[1]])
          } else if (
            array[2] === 'Prov'
            || array[2] === 'Dist'
            || (array[0] === '0931700000' && array[1] === 'City of Zamboanga')
            || (array[0] === '0990100000' && array[1] === 'City of Isabela (Not a Province)')
          ) {
            result.provinces.push([array[0], array[1], array[2]])
          } else if (array[2] === 'City' || array[2] === 'Mun' || array[2] === 'SubMun' || array[2] === 'SGU') {
            result.cityOrMunicipalities.push([array[0], array[1]])
          } else if (array[2] === 'Bgy') {
            result.barangays.push([array[0], array[1]])
          } else {
            result.qwe.push([array[0], array[1], array[2]])
          }
          return result
        }, {
          regions: [],
          provinces: [],
          cityOrMunicipalities: [],
          barangays: [],
          qwe: [],
        } as {
          regions: string[][];
          provinces: string[][];
          cityOrMunicipalities: string[][];
          barangays: string[][];
          qwe: string[][];
        })

        // console.log('qwe', qwe)

        // const asd = psgcArrayOfArray.filter((array) => {
        //   return array[0].slice(0, 5) === '1999900000'.slice(0, 5)
        // })

        // console.log('asd', asd)
        
        setRegions(regions)
        setProvinces(provinces)
        setCityOrMunicipalities(cityOrMunicipalities)
        setBarangays(barangays)
    }).finally(() => {
      setPsgcLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    regions,
    provinces,
    cityOrMunicipalities,
    barangays,
    psgcLoading,
  }
}
