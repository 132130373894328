import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError } from 'errors/FormValidationError';
import { Transaction } from 'models';
import { GetTransactionsRequest, transactionService } from "services/transactionService";
import { asyncThunkErrorHandler } from 'store/asyncThunkErrorHandler';
import { RootState } from 'store/store';

interface TransactionListState {
  gettingTransactionsLoading: boolean;
  transactionsCount: number;
  transactions: Transaction[];
  transactionsPerPage: number;
  transactionsCurrentPage: number;
  getTransactionsRequest: GetTransactionsRequest;
  getTransactionsFormValidationError: FormValidationError | null;
}

const transactionListInitialState: TransactionListState = {
  gettingTransactionsLoading: false,
  transactionsCount: 0,
  transactions: [],
  transactionsPerPage: 8,
  transactionsCurrentPage: 1,
  getTransactionsRequest: {},
  getTransactionsFormValidationError: null,
}

export const fetchTransactions = createAsyncThunk('transactionList/fetchTransactions', async (getTransactionsRequest: GetTransactionsRequest, thunkAPI) => {
  try {
    const getTransactionsResponse = await transactionService.getTransactions(getTransactionsRequest);
    return getTransactionsResponse;
  } catch (err: any) {
    return asyncThunkErrorHandler(err, thunkAPI)
  }
});

export const exportTransactions = createAsyncThunk('transactionList/exportTransactions', async (getTransactionsRequest: GetTransactionsRequest, thunkAPI) => {
  try {
    const getTransactionsResponse = await transactionService.exportTransactions(getTransactionsRequest);
    return getTransactionsResponse;
  } catch (err: any) {
    return asyncThunkErrorHandler(err, thunkAPI)
  }
});

export const transactionListSlice = createSlice({
  name: 'transactionListSlice',
  initialState: transactionListInitialState,
  reducers: {
    setTransactionsCurrentPage: (state, action: PayloadAction<number>) => {
      state.transactionsCurrentPage = action.payload
    },

    removeTransactionFromList: (state, action: PayloadAction<number>) => {
      const transactionId = action.payload
      
      const index = state.transactions.findIndex((transaction) => transaction.payment_id === transactionId)

      if (index) {
        state.transactions.splice(index, 1)
      }

      state.transactionsCount--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state, action) => {
        state.getTransactionsRequest = action.meta.arg
        state.gettingTransactionsLoading = true
        state.getTransactionsFormValidationError = null
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.gettingTransactionsLoading = false

        const meta = action.payload.transactions.meta
        
        if (meta) {
          state.transactionsCount = meta.total
          state.transactions = action.payload.transactions.data;
          state.transactionsPerPage = meta.per_page
          state.transactionsCurrentPage = meta.current_page
        } else {
          state.transactionsCount = action.payload.transactions.data.length
          state.transactions = action.payload.transactions.data;
          state.transactionsPerPage = action.payload.transactions.data.length
          state.transactionsCurrentPage = 1
        }
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.gettingTransactionsLoading = false
        state.transactionsCount = 0
        state.transactions = []
        state.getTransactionsFormValidationError = (action.meta.rejectedWithValue ? action.payload : action.error) as FormValidationError
      });
  },
})

export const { setTransactionsCurrentPage, removeTransactionFromList } = transactionListSlice.actions

export const selectTransactionsCount = (state: RootState) => state.transactionListSlice.transactionsCount;
export const selectTransactions = (state: RootState) => state.transactionListSlice.transactions;
export const selectGettingTransactionsLoading = (state: RootState) => state.transactionListSlice.gettingTransactionsLoading;
export const selectTransactionsPerPage = (state: RootState) => state.transactionListSlice.transactionsPerPage;
export const selectTransactionsNumberOfPages = (state: RootState) => Math.ceil(state.transactionListSlice.transactionsCount / state.transactionListSlice.transactionsPerPage);
export const selectTransactionsCurrentPage = (state: RootState) => state.transactionListSlice.transactionsCurrentPage;
export const seletGetTransactionsFormValidationError = (state: RootState) => state.transactionListSlice.getTransactionsFormValidationError;

export default transactionListSlice.reducer
