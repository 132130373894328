import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError } from 'errors/FormValidationError';
import { User } from 'models';
import * as qs from 'qs';
import { GetUsersRequest, userService } from "services/userService";
import { asyncThunkErrorHandler } from 'store/asyncThunkErrorHandler';
import { RootState } from 'store/store';
import { createUser } from './createUserSlice';
import { deleteUser } from './deleteUserSlice';
import { updateUser } from './updateUserSlice';
const qsParsed = qs.parse(window.location.search.slice(1))

interface UserListState {
  gettingUsersLoading: boolean;
  usersCount: number;
  users: User[];
  usersPerPage: number;
  usersCurrentPage: number;
  getUsersFormValidationError: FormValidationError | null;
}

const userListInitialState: UserListState = {
  gettingUsersLoading: false,
  usersCount: 0,
  users: [],
  usersPerPage: 8,
  usersCurrentPage: qsParsed.page ? Number(qsParsed.page) : 1,
  getUsersFormValidationError: null
}

export const fetchUsers = createAsyncThunk('userList/fetchUsers', async (getUsersRequest: GetUsersRequest, thunkAPI) => {
  try {
    const getUsersResponse = await userService.getUsers(getUsersRequest);
    return getUsersResponse;
  } catch (err: any) {
    return asyncThunkErrorHandler(err, thunkAPI)
  }
});

export const userListSlice = createSlice({
  name: 'userListSlice',
  initialState: userListInitialState,
  reducers: {
    setUsersCurrentPage: (state, action: PayloadAction<number>) => {
      state.usersCurrentPage = action.payload
    },

    removeUserFromList: (state, action: PayloadAction<number>) => {
      const userId = action.payload
      
      const index = state.users.findIndex((user) => user.id === userId)

      if (index) {
        state.users.splice(index, 1)
      }

      state.usersCount--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state) => {
        state.usersCount++
      })

      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex((user) => user.id === action.meta.arg.id)

        if (index > -1) {
          state.users.splice(index, 1, action.payload.data)
        }
      })

      .addCase(deleteUser.fulfilled, (state, action) => {
        const index = state.users.findIndex((user) => user.id === action.meta.arg.id)

        if (index > -1) {
          state.users.splice(index, 1)
        }

        state.usersCount--
      })

      .addCase(fetchUsers.pending, (state) => {
        state.gettingUsersLoading = true
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.gettingUsersLoading = false

        const meta = action.payload.users.meta

        console.log('meta', meta)

        if (meta) {
          state.usersCount = meta.total
          state.users = action.payload.users.data;
          state.usersPerPage = meta.per_page
          state.usersCurrentPage = meta.current_page
        } else {
          state.usersCount = action.payload.users.data.length
          state.users = action.payload.users.data;
          state.usersPerPage = action.payload.users.data.length
          state.usersCurrentPage = 1
        }
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.gettingUsersLoading = false
        state.usersCount = 0
        state.users = []
        state.getUsersFormValidationError = (action.meta.rejectedWithValue ? action.payload : action.error) as FormValidationError
      });
  },
})

export const { setUsersCurrentPage, removeUserFromList } = userListSlice.actions

export const selectUsersCount = (state: RootState) => state.userListSlice.usersCount;
export const selectUsers = (state: RootState) => state.userListSlice.users;
export const selectGettingUsersLoading = (state: RootState) => state.userListSlice.gettingUsersLoading;
export const selectUsersPerPage = (state: RootState) => state.userListSlice.usersPerPage;
export const selectUsersNumberOfPages = (state: RootState) => Math.ceil(state.userListSlice.usersCount / state.userListSlice.usersPerPage);
export const selectUsersCurrentPage = (state: RootState) => state.userListSlice.usersCurrentPage;
export const seletGetUsersFormValidationError = (state: RootState) => state.userListSlice.getUsersFormValidationError;

export default userListSlice.reducer
