import "./App.css";

import TransactionsPage from "pages/transactions/TransactionPage";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import LockersPage from "./pages/lockers/LockersPage";
import Overview from "./pages/overview/Index";
import PartnersPage from "./pages/partners/PartnersPage";
import AccountSettingsPage from "./pages/settings/account/AccountSettingsPage";
import FreePointsSettingsPage from "./pages/settings/freepoints/FreePointsSettingsPage";
import Settings from "./pages/settings/Index";
import SecuritySettings from "./pages/settings/security/Form";
import UsersPage from "./pages/users/UsersPage";

import MainLayout from "components/includes/MainLayout";

import GlobalContext from "contexts/GlobalContext";
import PsgcContext from "contexts/PsgcContext";
import { ValidationError } from 'errors/FormValidationError';
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import usePsgc from "hooks/usePsgc";
import ContactsPage from "pages/contacts/ContactsPage";
import LandingPage from "pages/landing/LandingPage";
import { useEffect } from "react";
import { getAuthProfile, selectGettingLoggedInUser, selectLoggedInUserProfile } from "store/auth/authSlice";
import Swal from 'sweetalert2';
import Pagenotfound from "./pages/pagenotfound/Pagenotfound";
// declare global {
//   interface Window {
//     env: {
//       API_BASE_URL?: string;
//       GOOGLE_MAP_API_KEY?: string;
//     };
//   }
// }

function App() {
  const dispatch = useAppDispatch()

  const loggedInUserProfile = useAppSelector(selectLoggedInUserProfile)
  const gettingLoggedInUser = useAppSelector(selectGettingLoggedInUser)

  const {
    regions,
    provinces,
    cityOrMunicipalities,
    barangays,
    psgcLoading,
  } = usePsgc()

  useEffect(() => {
    const login = localStorage.getItem("login")
    if (login) {
      dispatch(getAuthProfile(JSON.parse(login)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function catchErr(err: Error | any) {
    let message = 'Something went wrong!'
    let text = ''

    if (err.response) {
        if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
            message = err.response.data.message

            const validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
                if (err.response.data.errors[fieldName].length > 0) {
                    err.response.data.errors[fieldName].forEach((errorMessage: string) => {
                        validationErrors.push({
                        field: fieldName,
                        message: errorMessage
                        })
                    })
                }

                return validationErrors
            }, [] as ValidationError[])

            if (validationErrors.length > 0) {
                message = 'Validation Failed!'
                text = validationErrors.map((validationError) => validationError.message).join('<br>')
            }
        } else {
            message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
        }
    }
    
    Swal.fire({
        icon: "error",
        title: message,
        html: text || message,
        confirmButtonText: 'Okay',
        reverseButtons: true,
        confirmButtonColor: "#637D96",
        cancelButtonColor: "#C5C7C4",
    })
  }

  return (
    <GlobalContext.Provider value={{
      catchErr
    }}>
      <PsgcContext.Provider value={{
        regions,
        provinces,
        cityOrMunicipalities,
        barangays,
        psgcLoading,
      }}>
        <Routes>
          <Route path="/" element={loggedInUserProfile ? <MainLayout /> : <LandingPage />}>
            <Route path="overview" element={<Overview />} />
            <Route path="users" element={<UsersPage />} />

            <Route path="partners" element={<PartnersPage />} />

            <Route path="lockers" element={<LockersPage />} />
            <Route path="transactions" element={<TransactionsPage />} />

            <Route path="contacts" element={<ContactsPage />} />

            <Route path="settings/" element={<Settings />}>
              <Route path="account" element={<AccountSettingsPage />} />
              <Route path="freepoints" element={<FreePointsSettingsPage />} />
              {/* <Route path="security" element={<SecuritySettings />} /> */}
            </Route>
          </Route>

          <Route path="/login" element={gettingLoggedInUser ? <LandingPage /> :<LoginPage />} />
          
          {/* MAKE SURE THE CODE BELOW IS THE LAST ROUTE */}
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </PsgcContext.Provider>
    </GlobalContext.Provider>
  );
}

export default App;
