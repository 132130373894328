import AnnoucementTab from "components/AnnoucementTab";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import PartnersContext from "contexts/PartnersContext";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchPartners } from "hooks/useFetchPartners";
import usePartnersState from "hooks/usePartnersState";
import ViewLockerModal from "pages/lockers/ViewLockerModal";
import CreatePartnerModal from "pages/partners/CreatePartnerModal";
import EditPartnerModal from "pages/partners/EditPartnerModal";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cancelDeletePartnerModal, deletePartner, selectDeletePartnerFormValidationError, selectDeletingPartner, selectPartnerToDelete, setPartnerToDelete } from "store/partners/deletePartnerSlice";
import PartnersFiltersModal from "./PartnersFiltersModal";
import PartnersTable from "./PartnersTable";
import ViewPartnerModal from "./ViewPartnerModal";

export default function PartnersPage() {
  const [searchParams] = useSearchParams()
  const pageFromParams = searchParams.get('page') || '1'
  const emailFromParams = searchParams.get('email') || ''
  const firstnameFromParams = searchParams.get('firstname') || ''
  const lastnameFromParams = searchParams.get('lastname') || ''
  const statusFromParams = searchParams.get('status') || ''
  const startDateFromParams = searchParams.get('start_date') || ''
  const endDateFromParams = searchParams.get('end_date') || ''

  const dispatch = useAppDispatch()
  const partnerToDelete = useAppSelector(selectPartnerToDelete)
  const deletingPartner = useAppSelector(selectDeletingPartner)
  const deletePartnerFormValidationError = useAppSelector(selectDeletePartnerFormValidationError)
  const fetchPartners = useFetchPartners()

  const confirmDeletePartnerHandler = () => {
    if (partnerToDelete) {
      dispatch(deletePartner(partnerToDelete)).then(() => {
        fetchPartners()
      })
    }
  };

  useEffect(() => {
    fetchPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageFromParams,
    startDateFromParams,
    endDateFromParams,
    emailFromParams,
    firstnameFromParams,
    lastnameFromParams,
    statusFromParams,
  ]);

  const partnersState = usePartnersState()

  return (
    <>
      <PartnersContext.Provider value={partnersState}>
        <main className="flex-1 overflow-y-auto">
          {/* Primary column */}
          <section
            aria-labelledby="primary-heading"
            className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
          >
            <PartnersTable/>
          </section>
        </main>

        {/* Secondary column (hidden on smaller screens) */}
        {/* <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
          <AnnoucementTab />
        </aside> */}

        <CreatePartnerModal />

        <EditPartnerModal />

        <ViewPartnerModal />
                          
        <ViewLockerModal />

        <PartnersFiltersModal />

        <DeleteConfirmationModal
          title={'Delete partner'}
          subtitle={
            <>
              Are you sure you want to delete this partner ({partnerToDelete?.email})?
              All of their data will be permanently removed from our servers forever.
              This action cannot be undone.
            </>
          }
          openModal={partnerToDelete ? true : false}
          loading={deletingPartner}
          closeHandler={() => dispatch(setPartnerToDelete(null))}
          cancelHandler={() => dispatch(cancelDeletePartnerModal())}
          confirmHandler={confirmDeletePartnerHandler}
          formValidationError={deletePartnerFormValidationError}
        />
      </PartnersContext.Provider>
    </>
  );
}
