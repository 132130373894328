import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { CreateLockerRequest, lockerService } from "services/lockerService";
import { RootState } from 'store/store';

interface CreateLockerState {
  createLockerModalOpen: boolean;
  createLockerRequest: CreateLockerRequest;
  creatingLocker: boolean;
  createLockerFormValidationError: FormValidationError | null;
}

const createLockerRequestIntialValue = {
  partner_id: '',
  address: '',
  city: '',
  region: '',
  barangay: '',
  province: '',
  zip_code: '',
  status: 0,
}

const createLockerInitialState: CreateLockerState = {
  createLockerModalOpen: false,
  createLockerRequest: createLockerRequestIntialValue,
  creatingLocker: false,
  createLockerFormValidationError: null,
}

export const createLocker = createAsyncThunk('createLockerModal/createLocker', async (createLockerRequest: CreateLockerRequest, thunkAPI) => {
  try {
    const request = {
      ...createLockerRequest
    }
    // delete request.attachment
    const createLockerResponse = await lockerService.createLocker(request);
    return createLockerResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const createLockerSlice = createSlice({
  name: 'createLockerSlice',
  initialState: createLockerInitialState,
  reducers: {
    setCreateLockerModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createLockerModalOpen = action.payload
    },

    setCreateLockerRequestFieldValue: (state, action: PayloadAction<{ fieldName: string; fieldValue: any}>) => {
      // state.createLockerRequest[action.payload.fieldName] = action.payload.fieldValue
      if (action.payload.fieldName === 'partner_id') {
        state.createLockerRequest.partner_id = action.payload.fieldValue
      } else if (action.payload.fieldName === 'address') {
        state.createLockerRequest.address = action.payload.fieldValue
      } else if (action.payload.fieldName === 'city') {
        state.createLockerRequest.city = action.payload.fieldValue
      } else if (action.payload.fieldName === 'region') {
        state.createLockerRequest.region = action.payload.fieldValue
      } else if (action.payload.fieldName === 'barangay') {
        state.createLockerRequest.barangay = action.payload.fieldValue
      } else if (action.payload.fieldName === 'province') {
        state.createLockerRequest.province = action.payload.fieldValue
      } else if (action.payload.fieldName === 'zip_code') {
        state.createLockerRequest.zip_code = action.payload.fieldValue
      } else if (action.payload.fieldName === 'status') {
        state.createLockerRequest.status = action.payload.fieldValue
      }

      if (action.payload.fieldName === 'region') {
        state.createLockerRequest['province'] = ''
        state.createLockerRequest['city'] = ''
        state.createLockerRequest['barangay'] = ''
      }

      if (action.payload.fieldName === 'province') {
        state.createLockerRequest['city'] = ''
        state.createLockerRequest['barangay'] = ''
      }

      if (action.payload.fieldName === 'city') {
        state.createLockerRequest['barangay'] = ''
      }
      
      if (state.createLockerFormValidationError && state.createLockerFormValidationError.validationErrors) {
        state.createLockerFormValidationError.validationErrors = state.createLockerFormValidationError.validationErrors.filter((validationError) => {
          return validationError.field !== action.payload.fieldName
        })

        if (state.createLockerFormValidationError.validationErrors.length === 0) {
          state.createLockerFormValidationError = null
        }
      }
    },

    cancelCreateLockerModal: (state) => {
      state.createLockerModalOpen = false
      state.createLockerRequest = createLockerRequestIntialValue
      state.creatingLocker = false
      state.createLockerFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLocker.pending, (state) => {
        state.creatingLocker = true
        state.createLockerFormValidationError = null
      })
      .addCase(createLocker.fulfilled, (state) => {
        state.createLockerModalOpen = false
        state.creatingLocker = false
        state.createLockerRequest = createLockerRequestIntialValue
      })
      .addCase(createLocker.rejected, (state, action) => {
        state.createLockerFormValidationError = action.payload as FormValidationError
        state.creatingLocker = false
      });
  },
})

export const { setCreateLockerModalOpen, setCreateLockerRequestFieldValue, cancelCreateLockerModal } = createLockerSlice.actions

export const selectCreateLockerModalOpen = (state: RootState) => state.createLockerSlice.createLockerModalOpen;
export const selectCreateLockerRequest = (state: RootState) => state.createLockerSlice.createLockerRequest;
export const selectCreatingLocker = (state: RootState) => state.createLockerSlice.creatingLocker;
export const selectCreateLockerFormValidationError = (state: RootState) => state.createLockerSlice.createLockerFormValidationError;

export default createLockerSlice.reducer
