import axios from 'axios';
import AppFieldError from "components/AppFieldError";
import AppInputSelect from "components/AppInputSelect";
import AppInputSelectSearch from "components/AppInputSelectSearch";
import LoadingIcon from "components/LoadingIcon";
import GlobalContext from 'contexts/GlobalContext';
import { FormValidationError } from "errors/FormValidationError";
import { usePsgcOptions } from "hooks/usePsgcOptions";
import { DrawerSize, DrawerStatus, Locker, LockerStatus, Partner } from "models";
import { useContext, useEffect, useState } from 'react';

export interface DrawerFormProps {
  initialFocusRef: any;
  formSubmitHandler: (e: any) => void;
  cancelHandler: () => void;
  request: Record<string, any>;
  setFieldValue: (fieldName: string, fieldValue: any) => void;
  submittingForm: boolean
  formValidationError: FormValidationError | null;
  formType: 'create' | 'update' | 'view';
}

export default function DrawerForm({
  initialFocusRef,
  formSubmitHandler,
  cancelHandler,
  request,
  setFieldValue,
  submittingForm,
  formValidationError,
  formType,
}: DrawerFormProps) {
  const { catchErr } = useContext(GlobalContext)

  const [lockers, setLockers] = useState([])
  const [gettingLockers, setGettingLockers] = useState(false)

  async function getLockers () {
    const accessToken = localStorage.getItem("login")

    if (!accessToken) {
      return
    }
    
    const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

    setGettingLockers(true)

    axios.get(`${API_BASE_URL}/api/lockers`, {
      headers: {
        Authorization: token
      },
      params: {
        per_page: 1000,
        page: 1,
      },
    }).then((response) => {
      const lockers = response.data.lockers.data
      setLockers(lockers)
    }).catch((err) => {
      console.log('get contacts err', err)
      setLockers([])
      catchErr(err)
    }).finally(() => {
      setGettingLockers(false)
    })
  }

  useEffect(() => {
    getLockers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mt-2">
      <form
        className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y"
        onSubmit={formSubmitHandler}
      >
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          {/* <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-cyan-gray-900">
              Drawer Information
            </h2>
          </div> */}

          <div className="sm:col-span-3">
            <label
              htmlFor="locker_id"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Locker Code
            </label>

            {/* <AppInput
              ref={initialFocusRef}
              name="partner_id"
              id="partner_id"
              autoComplete="partner_id"
              value={request.partner_id}
              onChange={(value) => setFieldValue('partner_id', value)}
            /> */}

            <AppInputSelectSearch
              options={lockers.map((locker: Locker) => ({
                label: `${locker.code} - ${locker.partner.firstname} ${locker.partner.lastname}`,
                value: {
                  id: locker.id,
                  locker_code: locker.code,
                  firstname: locker.partner.firstname,
                  lastname: locker.partner.lastname,
                },
              }))}
              value={request.selectedLocker}
              onChange={(e) => setFieldValue('selectedLocker', e)}
              // search={searchPartners}
              // onSearch={(e) => setSearchPartners(e)}
              // loading={gettingPartners}
              getSelectedOption={() => {
                return request.selectedLocker ? {
                  label: `${request.selectedLocker.locker_code} - ${request.selectedLocker.firstname} ${request.selectedLocker.lastname}`,
                  value: {
                    id: request.selectedLocker.id,
                    locker_code: request.selectedLocker.locker_code,
                    firstname: request.selectedLocker.firstname,
                    lastname: request.selectedLocker.lastname,
                  },
                } : null
              }}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'locker_id'} formValidationError={formValidationError} />
          </div>

          {/* {
            formType === 'view' ? <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Code
                </label>

                <input
                  type="text"
                  name="code"
                  id="code"
                  className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  value={request.code}
                  onChange={(e) => setFieldValue('code', e.target.value)}
                  disabled={formType === 'view'}
                />

                <AppFieldError fieldName={'code'} formValidationError={formValidationError} />
              </div>
            </> : <></>
          } */}

          {
            formType === 'update' || formType === 'view' ? <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-cyan-gray-900"
                >
                  Status
                </label>

                <AppInputSelect
                  options={[
                    {
                      label: 'EMPTY',
                      value: DrawerStatus.EMPTY
                    },
                    {
                      label: 'OCCUPIED',
                      value: DrawerStatus.OCCUPIED,
                    }
                  ]}
                  value={request.status}
                  onChange={(e) => setFieldValue('status', e)}
                  disabled={formType === 'view'}
                />

                <AppFieldError fieldName={'status'} formValidationError={formValidationError} />
              </div>
            </> : <></>
          }

          <div className="sm:col-span-3">
            <label
              htmlFor="cu_number"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Control Unit #
            </label>

            <input
              type="text"
              name="cu_number"
              id="cu_number"
              autoComplete="cu_number"
              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              value={request.cu_number}
              onChange={(e) => setFieldValue('cu_number', e.target.value)}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'cu_number'} formValidationError={formValidationError} />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="position"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Position
            </label>

            <input
              type="text"
              name="position"
              id="position"
              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              value={request.position}
              onChange={(e) => setFieldValue('position', e.target.value)}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'position'} formValidationError={formValidationError} />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="size"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Size
            </label>

            <AppInputSelect
              options={[
                {
                  label: 'XS',
                  value: DrawerSize.XS
                },
                {
                  label: 'Small',
                  value: DrawerSize.SMALL,
                },
                {
                  label: 'Medium',
                  value: DrawerSize.MEDIUM,
                },
                {
                  label: 'Large',
                  value: DrawerSize.LARGE,
                },
                {
                  label: 'XL',
                  value: DrawerSize.XL,
                },
                {
                  label: 'XXL',
                  value: DrawerSize.XXL,
                }
              ]}
              value={request.size}
              onChange={(e) => setFieldValue('size', e)}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'size'} formValidationError={formValidationError} />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="price"
              className="block text-sm font-medium text-cyan-gray-900"
            >
              Price
            </label>

            <input
              type="text"
              name="price"
              id="price"
              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              value={request.price}
              onChange={(e) => setFieldValue('price', e.target.value)}
              disabled={formType === 'view'}
            />

            <AppFieldError fieldName={'price'} formValidationError={formValidationError} />
          </div>


        </div>

        <div>
          <div className="flex justify-end pt-8">
            <button
              type="button"
              disabled={submittingForm}
              className={`${submittingForm ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
              onClick={() => cancelHandler()}
            >
              Cancel
            </button>

            {
              formType !== 'view' ? <>
                <button
                  type="submit"
                  disabled={submittingForm}
                  className={`${submittingForm ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                >
                  { submittingForm ? <><LoadingIcon /><span>Loading...</span></> : 'Submit' }
                </button>
              </> : <></>
            }
          </div>

          {
            formValidationError
              ?
                <div className="flex justify-end">
                  <small className="text-red-500">{ formValidationError.message }</small>
                </div>
              :
                <></>
          }
        </div>
      </form>
    </div>
  )
}
