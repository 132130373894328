import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
export interface AppInputSelectSearchProps {
  disabled?: boolean;
  value: any;
  options: {
    label: string;
    value: any;
  }[];
  onChange: (value: any) => void;
  search?: string;
  onSearch?: (value: string) => void;
  loading?: boolean;
  getSelectedOption?: () => {
    label: string;
    value: any;
  } | null;
}

export default function AppInputSelectSearch({
  disabled,
  value,
  options,
  onChange,
  search: _search = '',
  onSearch,
  loading = false,
  getSelectedOption,
}: AppInputSelectSearchProps) {
  const selectedOption = getSelectedOption ? getSelectedOption() : options.find((option) => option.value === value) || null

  const [search, _setSearch] = useState(_search ? _search.toString() : '')

  function setSearch (search: string) {
    _setSearch(search)
    if (onSearch) {
      onSearch(search)
    }
  }

  const filteredOptions = search && !onSearch ? options.filter(option => {
    return option.label.toLowerCase().includes(search.toLowerCase())
  }) : options

  const props = { tabIndex: 0 }
  const icon = value && !disabled ? <div
    { ...props }
    className="absolute inset-y-0 right-0 flex items-center p-2 focus:outline-none focus:border-cyan-500 focus:ring-cyan-500 focus:ring-1 m-1 rounded-lg cursor-pointer block"
    onClick={(e) => {
      e.stopPropagation()
      onChange('')
      setSearch('')
    }}
    onKeyDown={(e) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        onChange('')
        setSearch('')
      }
    }}
  >
    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
  </div> : <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2">
    <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
  </span>

  if (disabled) {
    return <button
      disabled={disabled}
      className={
        [
          'relative',
          'w-full',
          'cursor-not-allowed',
          'rounded-md',
          'bg-white',
          'py-2',
          'pl-3',
          'pr-8',
          'text-left',
          'shadow-md',
          'focus:outline-none',
          'focus:border-cyan-500',
          'focus:ring-cyan-500',
          'focus:ring-1',
          'sm:text-sm',
          'mt-1',
          'block',
          'border',
          'border-gray-200',
        ].join(' ')
      }
    >
      <span className="block truncate">{selectedOption ? selectedOption.label : value}&nbsp;</span>
      {icon}
    </button>
  }

  function onChangedHandler (selected: any) {
    onChange(selected ? selected.value : null)
    setSearch('')
  }

  return (
    <Combobox
      value={selectedOption}
      onChange={onChangedHandler}
    >
      {
        () => {
          return <>
            <div className="relative mt-1">
              <Combobox.Button as={Fragment}>
                <div className='relative'>
                  <Combobox.Input
                    className="
                      relative
                      w-full
                      cursor-pointer
                      rounded-md
                      bg-white
                      py-2
                      pl-3
                      pr-8
                      text-left
                      shadow-md
                      focus:outline-none
                      focus:border-cyan-500
                      focus:ring-cyan-500
                      focus:ring-1
                      sm:text-sm
                      mt-1
                      block
                      border
                      border-gray-500
                    "
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onBlur={() => setSearch('')}
                    displayValue={() => selectedOption ? selectedOption.label : ''}
                  />
                  {icon}
                </div>
              </Combobox.Button>

              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Combobox.Options static
                  className="
                    absolute
                    mt-1
                    max-h-60
                    w-full
                    overflow-auto
                    rounded-md
                    bg-white
                    py-1
                    text-base
                    shadow-lg
                    ring-1
                    ring-black
                    ring-opacity-5
                    focus:outline-none
                    sm:text-sm
                    border-cyan-500
                    ring-cyan-500
                    z-50
                  "
                >
                  {
                    loading ? <>
                      <Combobox.Option
                        className={() =>
                          `relative select-none py-2 pl-4 pr-4 ${
                            'text-gray-900'
                          }`
                        }
                        value={null}
                        disabled
                      >
                        {() => (
                          <>
                            <span className={`block truncate font-medium`}>
                              Loading...
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    </> : <>
                      {
                        search && filteredOptions.length === 0 ? <>
                          <Combobox.Option
                            className={() =>
                              `relative select-none py-2 pl-4 pr-4 ${
                                'text-gray-900'
                              }`
                            }
                            value={null}
                            disabled
                          >
                            {() => (
                              <>
                                <span className={`block truncate font-medium`}>
                                  No Results
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        </> : <></>
                      }
                      {filteredOptions.map((option, index) => (
                        <Combobox.Option
                          key={index}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-cyan-100 text-cyan-900' : 'text-gray-900'
                            }`
                          }
                          value={option}
                        >
                          {({ selected }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                {option.label}
                              </span>

                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-cyan-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </>
                  }
                </Combobox.Options>
              </Transition>
            </div>
          </>
        }
      }

    </Combobox>
  )
}
