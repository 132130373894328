import axios from "axios";
import { Drawer, DrawerSize, DrawerStatus, PaginationResult } from "models";

export interface GetDrawersRequest {
  locker_id?: string;
  cu_number?: string;
  position?: string;
  size?: string;
  price?: string;
  status?: string;
  page?: string;
}

export interface GetDrawersResponse {
  status: number;
  drawers: PaginationResult<Drawer>;
}

export interface CreateDrawerRequest {
  locker_id: string | number; //locker code
  cu_number: string | number;
  position: string | number;
  size: number | DrawerSize;
  price: string | number;
  status: number | DrawerStatus;
}

export interface CreateDrawerResponse {
  status: number;
  data: Drawer;
}

export interface UpdateDrawerRequest {
  id: string | number;
  locker_id: string | number;
  cu_number: string | number;
  position: string | number;
  size: number | DrawerSize;
  price: string | number;
  status: number | DrawerStatus;
}

export interface UpdateDrawerResponse {
  status: number;
  data: Drawer;
}

export interface DeleteDrawerResponse {
  status: number;
}

function getConfig () {
  const accessToken = localStorage.getItem("login")
  const token = accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return config
}

async function getDrawers(getDrawersRequest: GetDrawersRequest): Promise<GetDrawersResponse> {
  const config = {
    ...getConfig(),
    params: {
      ...getDrawersRequest,
      locker_id: getDrawersRequest.locker_id,
    },
  };

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.get(`/api/drawers/${getDrawersRequest.locker_id}`, config);
  const getDrawersResponse = response.data
  return getDrawersResponse
}

async function createDrawer(createDrawerRequest: CreateDrawerRequest): Promise<CreateDrawerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.post('/api/drawers', createDrawerRequest, config);
  const createDrawerResponse = response.data
  return createDrawerResponse
}

async function updateDrawer(updateDrawerRequest: UpdateDrawerRequest): Promise<UpdateDrawerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.put(`/api/drawers/${updateDrawerRequest.id}`, updateDrawerRequest, config);
  const updateDrawerResponse = response.data
  return updateDrawerResponse
}

async function deleteDrawer(drawerId: string): Promise<DeleteDrawerResponse> {
  const config = getConfig()

  await new Promise((resolve) => setTimeout(resolve, 1000 * .25))

  const response = await axios.delete(`/api/drawers/${drawerId}`, config);
  const deleteDrawerResponse = response.data
  return deleteDrawerResponse
}

export const drawerService = {
  getDrawers,
  createDrawer,
  updateDrawer,
  deleteDrawer
}
