import React from "react";
import { GiftIcon, CogIcon, KeyIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Link, Outlet, useLocation } from "react-router-dom";

const subNavigation = [
  {
    name: "Account",
    description:
      "Change Account Details",
    href: "/settings/account",
    icon: CogIcon,
    current: true,
  },
  {
    name: "Free Points",
    description:
      "Change Free Points Settings",
    href: "/settings/freepoints",
    icon: GiftIcon,
    current: false,
  },
  // {
  //   name: "Security",
  //   description:
  //     "Settings related to Security",
  //   href: "/settings/security",
  //   icon: KeyIcon,
  //   current: false,
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Settings() {
  let location = useLocation()

  subNavigation.forEach((item) => {
    if (item.href === location.pathname) {
      item.current = true;
    } else {
      item.current = false;
    }
  })

  return (
    <>
      <main className="flex flex-1 overflow-hidden">
        <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
          {/* Breadcrumb */}
          <nav
            aria-label="Breadcrumb"
            className="border-b border-cyan-gray-200 bg-white xl:hidden"
          >
            <div className="mx-auto flex max-w-3xl items-start py-3 px-4 sm:px-6 lg:px-8">
              <a
                href="#/"
                className="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-cyan-gray-900"
              >
                <ChevronLeftIcon
                  className="h-5 w-5 text-cyan-gray-400"
                  aria-hidden="true"
                />
                <span>Settings</span>
              </a>
            </div>
          </nav>

          <div className="flex flex-1 xl:overflow-hidden">
            {/* Secondary sidebar */}
            <nav
              aria-label="Sections"
              className="hidden w-96 flex-shrink-0 border-r border-cyan-gray-200 bg-white xl:flex xl:flex-col"
            >
              <div className="flex h-16 flex-shrink-0 items-center border-b border-cyan-gray-200 px-6">
                <p className="text-lg font-medium text-cyan-gray-900">
                  Settings
                </p>
              </div>
              <div className="min-h-0 flex-1 overflow-y-auto">
                {subNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? "bg-cyan-50 bg-opacity-50"
                        : "hover:bg-cyan-50 hover:bg-opacity-50",
                      "flex p-6 border-b border-cyan-gray-200"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className="-mt-0.5 h-6 w-6 flex-shrink-0 text-cyan-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-3 text-sm">
                      <p className="font-medium text-cyan-gray-900">
                        {item.name}
                      </p>
                      <p className="mt-1 text-cyan-gray-500">
                        {item.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </nav>

            {/* FORMS HERE, OUTLET */}
            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
}
