import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FormValidationError, ValidationError } from 'errors/FormValidationError';
import { User } from 'models';
import { userService } from "services/userService";
import { RootState } from 'store/store';

interface DeleteUserState {
  userToDelete: User | null;
  deletingUser: boolean;
  deleteUserFormValidationError: FormValidationError | null;
}

const deleteUserModalInitialState: DeleteUserState = {
  userToDelete: null,
  deletingUser: false,
  deleteUserFormValidationError: null,
}

export const deleteUser = createAsyncThunk('deleteUserModal/deleteUser', async (user: User, thunkAPI) => {
  try {
    const deleteUserResponse = await userService.deleteUser(user.id.toString());
    return deleteUserResponse;
  } catch (err: any) {
		console.log('err', err.response || err)

		let message: string | null = null

    let validationErrors: ValidationError[] = []

		if (err.response) {
      if (err.response.status === 422 && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
        message = err.response.data.message

        validationErrors = Object.keys(err.response.data.errors).reduce((validationErrors, fieldName) => {
          if (err.response.data.errors[fieldName].length > 0) {
            err.response.data.errors[fieldName].forEach((errorMessage: string) => {
              validationErrors.push({
                field: fieldName,
                message: errorMessage
              })
            })
          }

          return validationErrors
        }, [] as ValidationError[])

        if (validationErrors.length > 0) {
          message = 'Validation Error!'
        }
      } else {
        message = err.response.data?.message || err.response.message || err.response.statusText || 'Something went wrong!!'
      }

      return thunkAPI.rejectWithValue({ message, validationErrors })
		} else if (err.request) {
      throw new Error('Something went wrong!')
		}

    throw err
  }
});

export const deleteUserSlice = createSlice({
  name: 'deleteUserSlice',
  initialState: deleteUserModalInitialState,
  reducers: {
    setUserToDelete: (state, action: PayloadAction<User | null>) => {
      state.userToDelete = action.payload
      state.deleteUserFormValidationError = null
    },
    cancelDeleteUserModal: (state) => {
      state.userToDelete = null
      state.deleteUserFormValidationError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUser.pending, (state) => {
        state.deletingUser = true
        state.deleteUserFormValidationError = null
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.userToDelete = null
        state.deletingUser = false
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserFormValidationError = action.payload as FormValidationError
        state.deletingUser = false
      });
  },
})

export const { setUserToDelete, cancelDeleteUserModal } = deleteUserSlice.actions

export const selectUserToDelete = (state: RootState) => state.deleteUserSlice.userToDelete;
export const selectDeletingUser = (state: RootState) => state.deleteUserSlice.deletingUser;
export const selectDeleteUserFormValidationError = (state: RootState) => state.deleteUserSlice.deleteUserFormValidationError;

export default deleteUserSlice.reducer
