import { createContext } from 'react';

interface LockersState {
  showFilterModal: boolean;
  setShowFilterModal: (showFilterModal: boolean) => void;
}

const globalInitialState: LockersState = {
  showFilterModal: false,
  setShowFilterModal: () => {},
}

const LockersContext = createContext(globalInitialState)

export default LockersContext
