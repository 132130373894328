import Loading from "components/Loading";
import AppPagination, { getPageSearch } from "components/partials/AppPagination";
import TransactionsContext from "contexts/TransactionsContent";
import { useAppSelector } from "hooks/store-hooks";
import { Transaction } from "models";
import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectGettingTransactionsLoading, selectTransactions, selectTransactionsCount, selectTransactionsCurrentPage, selectTransactionsNumberOfPages, selectTransactionsPerPage } from "store/transactions/transactionListSlice";
import { useExportTransactions } from "hooks/useExportTransactions";

export default function TransactionsTable() {
  const { setShowFilterModal, setTransactionToView } = useContext(TransactionsContext)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const startDateFromParams = searchParams.get('start_date')
  const endDateFromParams = searchParams.get('end_date')

  const hasFilter = startDateFromParams || endDateFromParams ? true : false

  const transactions = useAppSelector(selectTransactions);
  const loading = useAppSelector(selectGettingTransactionsLoading);
  const totalTransactions = useAppSelector(selectTransactionsCount);
  const transactionsPerPage = useAppSelector(selectTransactionsPerPage);
  const totalPages = useAppSelector(selectTransactionsNumberOfPages);
  const currentPage = useAppSelector(selectTransactionsCurrentPage);

  const exportTransactions = useExportTransactions();

  function pageHandler (page: number) {
    const search = getPageSearch(page)
    navigate({ search })
  }

  function getDrawerSizeText(size: number) {
    switch (size) {
      case 0:
        return "XS";
      case 1:
        return "Small";
      case 2:
        return "Medium";
      case 3:
        return "Large";
      case 4:
        return "XL";
      case 5:
        return "XXL";
      default:
        return "Unknown";
    }
  }

  return (
    <div className="p-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Transactions</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
          
          <button
            type="button"
            className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={exportTransactions}
          >
            Export
          </button>
        </div>
      </div>

      

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Locker Code
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Drawer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Sender
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Recipient
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Deposited At
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Claimed At
                      <span className="sr-only">Action</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                      <span className="sr-only">Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {transactions.map((transaction: Transaction) => (
                    <tr key={transaction.payment_id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        {/* <div className="flex items-center">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              name
                            </div>
                            <div className="text-gray-500">name</div>
                          </div>
                        </div> */}
                        {transaction.code}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {transaction.locker ? transaction.locker.code : 'N/A' }
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {/* <div className="text-gray-900">{}</div>
                        <div className="text-gray-500">{}</div> */}
                        {transaction.amount}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {/* <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Active
                        </span> */}
                        <div className="text-gray-900">
                          Drawer Number: {transaction.drawer_number}
                        </div>
                        <div className="text-gray-600">
                          Drawer size: {getDrawerSizeText(transaction.drawer_size!)}
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {transaction.sender_name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {/* <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Active
                        </span> */}
                        <div className="text-gray-900">
                          {transaction.recipient_name}
                        </div>
                        <div className="text-gray-600">
                          {transaction.recipient_mobile}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {transaction.status}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {/* <div className="text-gray-900">{}</div>
                        <div className="text-gray-500">{}</div> */}
                        {transaction.started_at}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {transaction.status === 'claimed' ? transaction.updated_at : "N/A"}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                        
                        <button className="text-cyan-600 hover:text-cyan-900" onClick={() => setTransactionToView(transaction)}>
                          View
                          <span className="sr-only">transaction Id#: {transaction.id}</span>
                        </button>

                        {/* <a
                          href="#/"
                          className="text-cyan-600 hover:text-cyan-900"
                          onClick={(e) => {
                            e.preventDefault();
                            openEditModalHandler(transaction.payment_id);
                          }}
                        >
                          Edit<span className="sr-only">, {}</span>
                        </a>
                        <a
                          href="#/"
                          className="ml-2 text-red-600 hover:text-red-900"
                          onClick={(e) => {
                            e.preventDefault();
                            openDeleteModalHandler(transaction.payment_id);
                          }}
                        >
                          Delete<span className="sr-only">, {}</span>
                        </a> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {
                !loading && transactions.length === 0 ? <>
                  <div className="flex justify-center p-4">
                    <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                  </div>
                </>
                : <></>
              }

              <AppPagination
                page={currentPage}
                pageHandler={pageHandler}
                loading={loading}
                resources={transactions}
                totalPages={totalPages}
                totalResources={totalTransactions}
                resourcesPerPage={transactionsPerPage}
              />
              
              {
                loading
                ?
                  <>
                    <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <Loading />
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
