import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import AppFieldError from "components/AppFieldError";
import LoadingIcon from "components/LoadingIcon";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { useFetchUsers } from "hooks/useFetchUsers";
import { Fragment, useRef } from "react";
import { cancelCreateUserModal, createUser, selectCreateUserFormValidationError, selectCreateUserModalOpen, selectCreateUserRequest, selectCreatingUser, setCreateUserModalOpen, setCreateUserRequestFieldValue } from "store/users/createUserSlice";

export default function CreateUserModal() {
  const dispatch = useAppDispatch();

  const createUserModalOpen = useAppSelector(selectCreateUserModalOpen);
  const createUserRequest = useAppSelector(selectCreateUserRequest);
  const createUserFormValidationError = useAppSelector(selectCreateUserFormValidationError);
  const creatingUser = useAppSelector(selectCreatingUser);

  const fetchUsers = useFetchUsers()

  const initialFocusRef = useRef(null);

  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    dispatch(createUser(createUserRequest)).then(() => {
      fetchUsers()
    })
  };

  function setFieldValue(fieldName: string, fieldValue: any) {
    dispatch(setCreateUserRequestFieldValue({ fieldName, fieldValue }))
  }
  
  return (
    <Transition.Root show={createUserModalOpen} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={() => creatingUser ? null : dispatch(setCreateUserModalOpen(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  {
                    !creatingUser
                      ?
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => dispatch(setCreateUserModalOpen(false))}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      :
                        <></>
                  }
                  
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Create User
                    </Dialog.Title>

                    <div className="mt-2">
                      <form
                        className="divide-y-cyan-gray-200 mt-6 space-y-8 divide-y"
                        onSubmit={formSubmitHandler}
                      >
                        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                          <div className="sm:col-span-6">
                            <h2 className="text-xl font-medium text-cyan-gray-900">
                              User Information
                            </h2>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                            Email
                            </label>

                            <input
                              ref={initialFocusRef}
                              type="text"
                              name="email"
                              id="email"
                              autoComplete="email"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={createUserRequest.email}
                              onChange={(e) => setFieldValue('email', e.target.value)}
                            />

                            <AppFieldError fieldName={'email'} formValidationError={createUserFormValidationError} />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-cyan-gray-900"
                            >
                              Password
                            </label>

                            <input
                              type="password"
                              name="password"
                              id="password"
                              className="mt-1 block w-full rounded-md border-cyan-gray-300 text-cyan-gray-900 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              value={createUserRequest.password}
                              onChange={(e) => setFieldValue('password', e.target.value)}
                            />

                            <AppFieldError fieldName={'password'} formValidationError={createUserFormValidationError} />
                          </div>
                        </div>

                        <div>
                          <div className="flex justify-end pt-8">
                            <button
                              type="button"
                              disabled={creatingUser}
                              className={`${creatingUser ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-cyan-gray-900 shadow-sm hover:bg-cyan-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                              onClick={() => dispatch(cancelCreateUserModal())}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              disabled={creatingUser}
                              className={`${creatingUser ? 'cursor-not-allowed' : 'cursor-pointer'} ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                            >
                              { creatingUser ? <><LoadingIcon /><span>Loading...</span></> : 'Submit' }
                            </button>
                          </div>

                          {
                            createUserFormValidationError
                              ?
                                <div className="flex justify-end">
                                  <small className="text-red-500">{ createUserFormValidationError.message }</small>
                                </div>
                              :
                                <></>
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
