import Loading from "components/Loading";
import AppPagination, { getPageSearch } from "components/partials/AppPagination";
import LockersContext from "contexts/LockersContext";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { Locker } from "models";
import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setCreateLockerModalOpen } from "store/lockers/createLockerSlice";
import { setLockerToDelete } from "store/lockers/deleteLockerSlice";
import { selectGettingLockersLoading, selectLockers, selectLockersCount, selectLockersCurrentPage, selectLockersNumberOfPages, selectLockersPerPage } from "store/lockers/lockerListSlice";
import { setLockerToUpdate, setLockerToView } from "store/lockers/updateLockerSlice";
import { setCreateDrawerModalOpen } from "store/lockers/createDrawerSlice";

export default function LockersTable() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { setShowFilterModal } = useContext(LockersContext)

  const emailFromParams = searchParams.get('partner_email')
  const statusFromParams = searchParams.get('status')
  const startDateFromParams = searchParams.get('start_date')
  const endDateFromParams = searchParams.get('end_date')

  const hasFilter = emailFromParams || startDateFromParams || endDateFromParams || statusFromParams ? true : false

  const lockers = useAppSelector(selectLockers);
  const loading = useAppSelector(selectGettingLockersLoading);
  const totalLockers = useAppSelector(selectLockersCount);
  const lockersPerPage = useAppSelector(selectLockersPerPage);
  const totalPages = useAppSelector(selectLockersNumberOfPages);
  const currentPage = useAppSelector(selectLockersCurrentPage);

  function pageHandler (page: number) {
    const search = getPageSearch(page)
    navigate({ search })
  }

  return (
    <div className="p-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Lockers</h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p> */}
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>

          <button
            type="button"
            className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => dispatch(setCreateLockerModalOpen(true))}
          >
            Add locker
          </button>
          
          {/* <button
            type="button"
            className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => dispatch(setCreateDrawerModalOpen(true))}
          >
            Add drawer
          </button> */}
        </div>
      </div>

      {/* <LockersFilters /> */}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Partner
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Code
                    </th>

                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Address
                    </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>

                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Id
                    </th> */}

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {lockers.map((locker: Locker, i: number) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        {/* <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={user.avatar}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {user.first_name}
                            </div>
                            <div className="text-gray-500">
                              {user.last_name}
                            </div>
                          </div>
                        </div> */}
                        {locker.partner?.email}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {locker.code}
                      </td>
                      
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {`${locker.address}, ${locker.barangay}, ${locker.city}, ${locker.province}, ${locker.region}, ${locker.zip_code}`}
                      </td> */}

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className={ `inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${locker.status ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}` } >
                          {locker.status ? 'Active' : 'Inactive'}
                        </span>
                      </td>

                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {locker.id}
                      </td> */}

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {locker.created_at}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                        <button className="text-cyan-600 hover:text-cyan-900" onClick={() => dispatch(setLockerToView(locker))}>
                          View
                          <span className="sr-only">locker Id#: {locker.id}</span>
                        </button>

                        <button className="ml-2 text-cyan-600 hover:text-cyan-900" onClick={() => dispatch(setLockerToUpdate(locker))}>
                          Edit
                          <span className="sr-only">locker Id#: {locker.id}</span>
                        </button>

                        <button className="ml-2 text-red-600 hover:text-red-900" onClick={() => dispatch(setLockerToDelete(locker))}>
                          Delete
                          <span className="sr-only">locker Id#: {locker.id}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {
                !loading && lockers.length === 0 ? <>
                  <div className="flex justify-center p-4">
                    <strong>{ hasFilter ? 'No results found' : 'No data'}</strong>
                  </div>
                </>
                : <></>
              }

              <AppPagination
                page={currentPage}
                pageHandler={pageHandler}
                loading={loading}
                resources={lockers}
                totalPages={totalPages}
                totalResources={totalLockers}
                resourcesPerPage={lockersPerPage}
              />
              
              {
                loading
                ?
                  <>
                    <div className="absolute inset-0 flex justify-center items-center h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <Loading />
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
